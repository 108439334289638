//@flow
import memoize from 'memoize-one';

export const moveUpDown = (
  type: string,
  index: number,
  content: Array<mixed>
) => {
  const updatedContent = [...content];
  if (type === 'up') {
    updatedContent[index - 1] = updatedContent.splice(
      index,
      1,
      updatedContent[index - 1]
    )[0];
  } else {
    updatedContent[index + 1] = updatedContent.splice(
      index,
      1,
      updatedContent[index + 1]
    )[0];
  }
  return updatedContent;
};

export const isMexicoSite = memoize((adminUrl: string) => {
  const urlParts = adminUrl.split('.');
  return ['mx', 'lat', 'co', 'ar'].includes(urlParts.pop());
});
