// @flow
import { base } from 'lib/firebase';
import { Image as ImageType } from 'lib/flowTypes';
import { uploadImagesToS3Bucket } from './helpers';

type ReceiveImages = (images: Array<ImageType>) => void;

export const getImages = (
  id: string,
  receiveImages: ReceiveImages,
  type: 'post' | 'product'
): any => {
  let path = `s3images/${id}`;
  if ('product' === type) {
    path = `product_images/${id}`;
  }

  return base.listenTo(path, {
    context: {},
    asArray: true,
    then(data) {
      receiveImages(data);
    },
  });
};

export const postImage = (
  id: string,
  data: ImageType,
  type: 'post' | 'product'
): any => {
  switch (type) {
    case 'post':
      return base.push('s3images/' + id, { data });
    case 'product':
      return base.push('product_images/' + id, { data });
    default:
      return;
  }
};

export const uploadImageFromNativeFileUploader = (
  siteName: string
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const input: HTMLInputElement = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = false;
    input.style.display = 'none';
    input.addEventListener('change', async (event: any) => {
      try {
        const images = await uploadImagesToS3Bucket(
          siteName,
          Array.from(event.target.files)
        );
        resolve(images);
      } catch (e) {
        reject(e);
      }
    });
    if (document.body) {
      document.body.appendChild(input);
    }
    input.click();
  });
};

export const preloadImage = (
  url: string,
  throwError: boolean = false
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const res = (data) => {
      resolve(data);
    };
    img.onload = res;
    img.onerror = throwError ? (err) => reject(err) : res;
    img.src = url;
  });
};
