import 'isomorphic-fetch';

import { mapPostType } from './helpers';
import { removeDeletedReviews } from './ProductHelpers';
import { filterEmptyStores } from '../components/Product/Producto/MarketPlace/lib/helpers';

export const getMonthTimeStamp = (date) => {
  const month = parseInt(date.name.substring(0, 2));
  const year = parseInt(date.name.substring(3));
  const startDate = new Date(year, month - 1, 1);
  startDate.setTime(startDate.getTime());
  const endDate = new Date(year, month, 1);
  endDate.setTime(endDate.getTime());
  return [startDate.getTime(), endDate.getTime()];
};

export const loadAllCategories = async (adminUrl, postType, siteName = '') => {
  const apiPostType = mapPostType(postType);
  let response = await fetch(
    `${adminUrl}/api/${siteName}/categories/${apiPostType}/0`,
    {
      credentials: 'include',
    }
  );
  let categories = response.json();
  return categories;
};

export const getCategoriesByType = async (
  adminUrl,
  categoryType,
  siteName,
  query = '',
  limit = 20
) => {
  let url = `${adminUrl}/api/${siteName}/categories?type=${categoryType}&limit=${limit}`;
  if ('' !== query.trim()) {
    url += `&query=${query}`;
  }

  const response = await fetch(url, {
    credentials: 'include',
  });
  return response.json();
};

export const getCategoriesBySlug = async (adminUrl, siteName, slugs = []) => {
  const promises = slugs.map((slug) =>
    getCategoryBySlug(adminUrl, siteName, slug)
  );
  const categories = await Promise.all(promises);

  return categories;
};

export const getCategoryBySlug = async (adminUrl, siteName, slug) => {
  const response = await fetch(
    `${adminUrl}/api/${siteName}/categories/${slug}`,
    {
      credentials: 'include',
    }
  );

  return response.json();
};

export const getTagBySlug = async (adminUrl, siteName, slug) => {
  const response = await fetch(`${adminUrl}/api/${siteName}/tags/${slug}`, {
    credentials: 'include',
  });

  return response.json();
};

export const getPostByName = async (adminUrl, postName) => {
  const response = await fetch(`${adminUrl}/api/post/${postName}`, {
    credentials: 'include',
  });

  return response.json();
};

export const getPostByID = async (adminUrl, postID, site) => {
  const response = await fetch(`${adminUrl}/api/post/${postID}?site=${site}`, {
    credentials: 'include',
  });

  const jsonResponse = await response.json();

  if (response.status >= 400) {
    throw jsonResponse;
  }

  return jsonResponse;
};

export const getEvent = async (adminUrl, name, site) => {
  const response = await fetch(`${adminUrl}/api/events/${name}?site=${site}`, {
    credentials: 'include',
  });

  const jsonResponse = await response.json();

  if (response.status >= 400) {
    throw jsonResponse;
  }

  return jsonResponse;
};

export const submitRequest = async (
  url,
  backendData = {},
  method = 'POST',
  authToken = ''
) => {
  const params = {
    credentials: 'include',
    method: method,
    headers: {
      Accept: 'application/json, text/javascript, */*',
      'Content-Type': 'application/json; charset=utf-8',
    },
  };

  if (authToken) {
    params.headers.Authorization = 'Bearer ' + authToken;
  }
  if ('GET' !== method) {
    params.body = JSON.stringify(backendData);
  }

  const res = await fetch(url, params);
  const jsonResponse = await res.json();

  if (res.status >= 400) {
    throw jsonResponse;
  }

  return jsonResponse;
};

export const getBrands = async (adminUrl: string) => {
  if (!adminUrl) {
    return [];
  }

  const brands = await submitRequest(`${adminUrl}/api/brands`, {}, 'GET');

  return brands;
};

export const createBrand = async (adminUrl: string, name: string) => {
  const brandId = await submitRequest(
    `${adminUrl}/api/brands`,
    { name },
    'post'
  );

  return brandId;
};

export const getModels = async (adminUrl: string, brandId: number) => {
  if (!adminUrl || !brandId) {
    return [];
  }

  const models = await submitRequest(
    `${adminUrl}/api/fichaproducts?brandId=${brandId}`,
    {},
    'GET'
  );

  return models;
};

export const createModel = async (
  adminUrl: string,
  brandId: number,
  model: string
) => {
  const modelId = await submitRequest(
    `${adminUrl}/api/fichaproducts`,
    { model, brandId },
    'post'
  );

  return modelId;
};

export const updateProduct = async (adminUrl, product) => {
  const productApiParamters = getProductApiParameters(product);
  const response = await submitRequest(
    `${adminUrl}/api/fichaproducts/${product.modelId}`,
    { ...productApiParamters },
    'PUT'
  );

  return response;
};

const getRelatedPosts = (relatedPosts, fixedPosts) => {
  const relatedSortedPosts = [...relatedPosts, ...fixedPosts].sort(
    (x, y) => y.timestamp - x.timestamp
  );

  const posts = relatedSortedPosts.map(({ postId, siteName }) => {
    return { postId, site: siteName };
  });

  return posts;
};

const getProductApiParameters = (product) => {
  const {
    isBrandModelLocked,
    familyId,
    crop,
    nicename,
    opinionMainAsset,
    brand,
    id,
    userId,
    userName,
    modelId,
    isProductUpdated,
    calculatePartial,
    specifications = {},
    highlights = [],
    rating,
    schemas,
    screenRating,
    performanceRating,
    cameraRating,
    softwareRating,
    autonomyRating,
    designRating,
    primaryImage,
    productImage,
    dimensionsImage,
    reviews = [],
    versions = [],
    relatedPosts = [],
    fixedPosts = [],
    seoSchemas = [],
    suggestions,
    isEverPublished,
    updatedVersionIndex,
    ...restProductAttributes
  } = product;

  if (updatedVersionIndex >= 0) {
    restProductAttributes['versions'] = filterEmptyStores(versions);
  }

  const {
    opinionImages = [],
    opinionVideos = [],
    frontCameraLensDetails = [],
    rearCameraLensDetails = [],
    softwareImages = [],
    rearCameraImages = [],
    performanceImages = [],
    frontCameraImages = [],
    RAM = [],
    ROM = [],
    dualSIM = [],
    refreshRates = [],
    realColors = [],
    colors = [],
    colorsImage = {},
    ...spec
  } = specifications;

  return {
    ...restProductAttributes,
    reviews: removeDeletedReviews(reviews),
    highlights,
    opinionAsset: JSON.stringify(opinionMainAsset),
    brandId: brand.id,
    rating: parseFloat(rating),
    screenRating: parseFloat(screenRating),
    performanceRating: parseFloat(performanceRating),
    cameraRating: parseFloat(cameraRating),
    softwareRating: parseFloat(softwareRating),
    autonomyRating: parseFloat(autonomyRating),
    designRating: parseFloat(designRating),
    primaryImage: JSON.stringify(primaryImage),
    productImage: JSON.stringify(productImage),
    dimensionsImage: JSON.stringify(dimensionsImage),
    relatedPosts: getRelatedPosts(relatedPosts, fixedPosts),
    seoSchemas: JSON.stringify(seoSchemas),
    specifications: {
      ...spec,
      RAM: JSON.stringify(RAM),
      ROM: JSON.stringify(ROM),
      dualSIM: JSON.stringify(dualSIM),
      refreshRates: JSON.stringify(refreshRates),
      realColors: JSON.stringify(realColors),
      colors: JSON.stringify(colors),
      frontCameraImages: JSON.stringify(frontCameraImages),
      performanceImages: JSON.stringify(performanceImages),
      rearCameraImages: JSON.stringify(rearCameraImages),
      softwareImages: JSON.stringify(softwareImages),
      opinionImages: JSON.stringify(opinionImages),
      opinionVideos: JSON.stringify(opinionVideos),
      frontCameraLensDetails: JSON.stringify(frontCameraLensDetails),
      rearCameraLensDetails: JSON.stringify(rearCameraLensDetails),
      colorsImage: JSON.stringify(colorsImage),
    },
  };
};

export const getPostFromUrl = (adminUrl: string, postUrl: string) => {
  const desvioUrl = `${adminUrl}/desvio_recommendation?posturl=${encodeURIComponent(
    postUrl
  )}`;

  return submitRequest(desvioUrl, {}, 'GET');
};

export const isOnline = async () => {
  const response = await fetch('https://i.blogs.es/ping/ping.png');

  return response.status === 200;
};

export const isImageExists = async (url) => {
  const response = await fetch(`${url}?date=${Date.now()}`, { method: 'HEAD' });

  return response.status === 200;
};
