import React from 'react';
import { Main } from 'containers/Main';
import { Provider } from 'react-redux';
import configureStore from './stores/ConfigureStore';
import { loadCss } from './utils/css';

if (process.env.NODE_ENV === 'development') {
  require('@welldone-software/why-did-you-render')(React, {
    trackAllPureComponents: true,
    // Uncomment below line to view the log for every render
    // logOnDifferentValues: true,
  });
}

loadCss();
const store = configureStore();
const App = () => (
  <Provider store={store}>
    <Main visitorId={Date.now()} />
  </Provider>
);

export { App as default };
