import moment from 'moment-timezone';
import { isMexicoSite } from './helpers';

moment.locale('es');

const EUROPE_TIMEZONE = 'Europe/Madrid';
const MEXICO_TIMEZONE = 'America/Mexico_City';

export const getTimeZone = (adminUrl: string) => {
  return isMexicoSite(adminUrl) ? MEXICO_TIMEZONE : EUROPE_TIMEZONE;
};
