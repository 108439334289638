// @flow
import * as React from 'react';
import styles from './NodeWrapper.module.css';

type Props = {
  layout?: string,
  align?: string,
  children: React.Node,
};

export const NodeWrapper = ({
  children,
  layout = 'small',
  align = 'center',
}: Props): React.Element<'div'> => {
  let className = '';

  if ('small' === layout && 'left' === align) {
    className = styles.smallLeft;
  } else if ('small' === layout && 'right' === align) {
    className = styles.smallRight;
  } else if ('small' === layout && 'center' === align) {
    className = styles.smallCenter;
  } else if ('large' === layout) {
    className = styles.largeCenter;
  } else if ('normal' === layout) {
    className = styles.normalCenter;
  } else if ('edge' === layout) {
    className = styles.edgeCenter;
  }

  return <div className={className}>{children}</div>;
};
export const LayoutWrapper = ({ children }: Props): React.Element<'div'> => {
  return <div className={`end-sm ${styles.modalMediaLayout}`}>{children}</div>;
};
