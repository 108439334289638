// @flow
import React, { useState } from 'react';
import { TextField } from 'components/Editor/Common';
import { DragAndDropUploader } from 'components/Editor/ImageUploader';
import { InsertPhotoIcon } from 'components/Editor/Common/Icons';
import { Backdrop, CircularLoader, Image } from 'components/Editor/Common';
import { getImageExtension } from 'lib/helpers';
import { postImage as postImageToS3 } from 'lib/s3ImageUploadService';

import style from './UrlImageUploader.module.css';

const INVALID_DIMENSION_TEXT = 'Error: usa una imagen de al menos 150x150 px';
const INVALID_EXTENSION_TEXT =
  'Error: el tipo de imagen no es válida, puedes usar JPG y PNG';
const URL_UPLOAD_ERROR = 'Error: la URL no es una imagen';

type Props = {
  imageSrc: string,
  onURLChange: Function,
  errorText?: string,
  siteName: string,
  setError: Function,
};

const UrlImageUploader = ({
  imageSrc,
  onURLChange,
  siteName,
  errorText = '',
  setError,
}: Props) => {
  const [isLoading, setIsloading] = useState(false);

  const uploadFileToDb = async (file: Object, imageName: string) => {
    try {
      const image = await postImageToS3(siteName, file);
      const { width, height } = { ...image };
      if (width < 150 || height < 150) {
        setError((error) => ({
          ...error,
          image: INVALID_DIMENSION_TEXT,
        }));
        return;
      }
      onURLChange(image);
    } catch (err) {
      setError((error) => ({ ...error, image: URL_UPLOAD_ERROR }));
    }
  };

  const validateExtension = (extension: string): null | RegExp$matchResult =>
    extension.toLowerCase().match(/(jpg|png)$/g);

  const uploadWithUrl = async (url) => {
    setIsloading(true);
    const imageUrl = url.split('?')[0];
    const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    const extension = getImageExtension(imageName);
    if (extension && !validateExtension(extension)) {
      setError((error) => ({ ...error, image: INVALID_EXTENSION_TEXT }));
      setIsloading(false);
      return;
    }
    let data = new FormData();
    data.append('url', imageUrl);
    try {
      await uploadFileToDb(data, imageName);
    } catch (error) {
    } finally {
      setIsloading(false);
    }
  };

  const onChange = (e) => {
    const url = e.target.value;
    if (url) {
      uploadWithUrl(url);
    } else {
      setError((error) => ({ ...error, image: '' }));
    }
  };

  const getImage = () => {
    if (imageSrc) {
      return (
        <>
          <div className={style.dropAreaImage}>
            <Image
              src={imageSrc}
              onLoad={() => setIsloading(false)}
              size="fullWidth"
              alt=""
            />

            {isLoading && (
              <Backdrop>
                <CircularLoader show />
              </Backdrop>
            )}
          </div>
        </>
      );
    }

    const dragAndDropHelperText =
      errorText !== '' ? errorText : 'Mínimo 150x150';
    return (
      <div className={style.dropArea}>
        <DragAndDropUploader
          isUploading={false}
          boxContent={
            <>
              <InsertPhotoIcon /> {dragAndDropHelperText}
            </>
          }
        />
        {isLoading && (
          <Backdrop>
            <CircularLoader show />
          </Backdrop>
        )}
      </div>
    );
  };

  return (
    <div>
      {getImage()}

      <TextField
        floatingLabelText="URL de la imagen"
        onChange={onChange}
        helperText={errorText}
        error={errorText}
      />
    </div>
  );
};

export default UrlImageUploader;
