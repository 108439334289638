import {
  RECEIVE_IMAGES,
  CHANGE_SELECTED_IMAGE,
  CHANGE_UPLOADING_STATUS,
} from 'actions/image';
import { CLOSE_MODAL } from 'actions/modal';
import {
  ADD_SECTION,
  ADD_GRID_SECTION,
  EDIT_SECTION,
  EDIT_GRID_SECTION,
} from 'actions/post';

const initialState = {
  imageUrls: [],
  imageToEmbed: {},
  isImageUploading: false,
};

const imageReducer = function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_IMAGES:
      return {
        ...state,
        imageUrls: action.images,
      };

    case CHANGE_SELECTED_IMAGE:
      return {
        ...state,
        imageToEmbed: action.image,
      };

    case CHANGE_UPLOADING_STATUS:
      return {
        ...state,
        isImageUploading: action.isImageUploading,
      };

    case ADD_SECTION:
    case ADD_GRID_SECTION:
    case EDIT_SECTION:
    case EDIT_GRID_SECTION:
    case CLOSE_MODAL:
      return {
        ...state,
        imageToEmbed: {},
      };

    default:
      return state;
  }
};

export default imageReducer;
