import React from 'react';
import idx from 'idx';
import isEqual from 'lodash.isequal';

import { currentHour, isFuture, getCurrentUtcTime } from './momentHelper';
import { initalLensValue } from 'components/Product/Producto/lib/constants';
import {
  defaultCommentStatus,
  initialMeta,
  POST_TYPE_NORMAL,
  POST_TYPE_BRAND_ADVERTISING,
  POST_TYPE_BRAND_ARTICLE,
  POST_TYPE_BRANDED,
  POST_TYPE_LANDING,
} from 'lib/constants';
import { getInitialPublishRegions, is3dJuegos } from 'lib/helpers';
import { isEmpty } from 'components/Editor/Common/DraftJSToolbar/helpers';
import { Link, RouterLink } from 'components/Editor/Common';

const BRANDED_POST_TYPES = [
  POST_TYPE_BRAND_ADVERTISING,
  POST_TYPE_BRAND_ARTICLE,
  POST_TYPE_BRANDED,
];

const getDefaultAmpVisibility = (postType: string) =>
  POST_TYPE_NORMAL === postType;

export const authorTypes = [
  { label: 'Autor principal', value: 'creator' },
  { label: 'Adaptado por', value: 'adaptor' },
  { label: 'Revisado por', value: 'reviewer' },
];

export const loadStatefromData = (data: {}, userRole: string) => {
  const initialDate =
    data.status === 'draft' ? currentHour() : data.publishData.postDate;

  const postType = data.postType;

  if (!data.meta) {
    data.meta = initialMeta;
  }

  return {
    id: data.id,
    blogName: data.blogName,
    fields: data.sections || [],
    title: data.title,
    subTitle: data.subTitle,
    crop: data.crop,
    postType: postType,
    initialPostType: data.initialPostType || postType,
    commentStatus:
      data.commentStatus ||
      idx(data, (_) => _.meta.comment.status) ||
      defaultCommentStatus[postType],
    meta: data.meta,
    maxId: data.maxId,
    status: data.status || 'draft',
    publishedDate: initialDate,
    postRepostBlogNames:
      idx(data, (_) => _.publishData.postRepostBlogNames) || [],
    publishRegion:
      idx(data, (_) => _.publishData.publishRegion) ||
      getInitialPublishRegions(postType),
    buttonDisabled: false,
    userId: data.user_id,
    userName: data.user_name,
    category: data.category || null,
    categoryNicename: data.categoryNicename || null,
    isSensitive: data.isSensitive || false,
    specialPost: data.specialPost || false,
    tags: data.tags || [],
    postCategories: data.postCategories || [],
    facebook: data.facebook || data.meta.social.facebook || '',
    twitter: data.twitter || data.meta.social.twitter || data.title || '',
    ampVisibility: data.ampVisibility || getDefaultAmpVisibility(postType),
    postVisibility:
      data.postVisibility !== undefined
        ? data.postVisibility
        : postType !== 'brandedLongform',
    currentStatus: currentStatus(data.status, initialDate),
    primaryImage: data.primaryImage || '',
    isNanomedio: data.isNanomedio || false,
    lastUpdatedBy: data.currentUser,
    lastUpdatedAt: data.lastUpdatedAt || getCurrentUtcTime(),
    updateModifiedGmt:
      data.updateModifiedGmt === undefined ? true : data.updateModifiedGmt,
    rating: data.rating || 0,
    republishDate: data.republishDate || '',
    productsCreated: data.productsCreated || 0,
    reindexedNames: data.reindexedNames || [],
    isContentUpdated: data.isContentUpdated || false,
    affiliationId: data.affiliationId || '',
    skinCss: data.skinCss || '',
    isReindexScheduled: data.isReindexScheduled || false,
    isEvergreen: data.isEvergreen || false,
    contentType: data.contentType || { id: -1 },
    juegos: data.juegos || [],
    platforms: data.platforms || [],
    hardware: data.hardware || [],
    others: data.others || [],
    postUrlPrefix: data.postUrlPrefix || null,
    redirectId: data.redirectId || '',
    redirectType: data.redirectType || null,
    prevPublishedRedirectId: data.prevPublishedRedirectId || '',
    bioVisibility: data.bioVisibility || 0,
    redirectTitle: data.redirectTitle || '',
    redirectSite: data.redirectSite || '',
    redirectSiteURL: data.redirectSiteURL || '',
    redirectURL: data.redirectURL || '',
    redirectFirebaseId: data.redirectFirebaseId || '',
    redirectStatus: data.redirectStatus || '',
    redirectFieldValue: data.redirectFieldValue || '',
    hasUnpublishedRedirectChanges: data.hasUnpublishedRedirectChanges || false,
    postAuthors:
      data.postAuthors?.length > 0
        ? data.postAuthors?.map((a) => ({ id: a.id, role: a.role }))
        : [
            {
              id: data.user_id,
              role: authorTypes[0]?.value,
            },
          ],
  };
};

export const loadPublishData = (data: {}) => {
  return {
    postId: idx(data, (_) => _.publishData.postId) || '',
    postHash: idx(data, (_) => _.publishData.postHash) || '',
  };
};
export const currentStatus = (status, publishedDate) => {
  if (status === 'draft') {
    return status;
  } else if (isFuture(publishedDate)) {
    return 'future';
  } else {
    return 'publish';
  }
};

export const initialState = {
  status: 'draft',
  postRepostBlogNames: [],
  publishRegion: [],
  postId: '',
  postType: '',
  commentStatus: 'open',
  postHash: '',
  publishedDate: '',
  meta: initialMeta,
  isSensitive: false,
  specialPost: false,
  buttonDisabled: true,
  isError: false,
  message: '',
  snackbarOpen: false,
  SnackbarMessage: '',
  allCategories: [],
  category: null,
  tags: [],
  postCategories: [],
  errors: {},
  ampVisibility: false,
  postVisibility: true,
  updateModifiedGmt: true,
  productsCreated: 0,
  isContentUpdated: false,
  contentType: { id: -1 },
  juegos: null,
  platforms: null,
  hardware: null,
  others: null,
  internetStatus: '',
};

export const getRamAndRom = (versions = []) => {
  const RAM = new Set();
  const ROM = new Set();
  for (let i = 0; i < versions.length; i++) {
    versions[i].RAM && RAM.add(versions[i].RAM);
    versions[i].ROM && ROM.add(versions[i].ROM);
  }
  return { RAM: [...RAM], ROM: [...ROM] };
};

export const convertTo1DArray = (
  data: Array<{ id: number }>,
  excludeId: number = -1
) => {
  let list = [];
  data.forEach((item) => {
    if (item.id !== excludeId) {
      list.push(item.id);
    }
  });
  return list;
};

export const findByName = (name: string, list: Array<User>) =>
  list.find((item) => item.label === name);

export const getPostType = (
  initialPostType: string,
  isBrandArticle: boolean,
  isBrandAdvertising: boolean
) => {
  if (isBrandArticle) {
    return POST_TYPE_BRAND_ARTICLE;
  }

  if (isBrandAdvertising) {
    return POST_TYPE_BRAND_ADVERTISING;
  }

  return initialPostType;
};

export const getPostVisibility = (
  postStatus: string,
  postType: string,
  isNanomedio: boolean,
  currentPostVisibility: boolean
) => {
  if ('publish' === postStatus) {
    return currentPostVisibility;
  }

  if (isNanomedio) {
    return false;
  }

  if (BRANDED_POST_TYPES.indexOf(postType) > -1) {
    return false;
  }

  return true;
};

export const getPostStatus = (postStatus, postDateGmt) => {
  if ('publish' !== postStatus) {
    return postStatus;
  }

  if (isFuture(postDateGmt)) {
    return 'future';
  }

  return 'publish';
};

export const getSiteDomain = (url: string) =>
  url.replace(/https?:\/\/((www|testing|dev)\.)?/, '');

export const isValidPrice = (versions) => {
  for (const index in versions) {
    if (
      versions[index].hasOwnProperty('price') &&
      versions[index]['price'] !== null &&
      parseFloat(versions[index]['price']) !== 0
    ) {
      return true;
    }

    if (versions[index].hasOwnProperty('stores')) {
      const validVersions = versions[index]['stores'].filter(
        ({ price = null }) => price !== null && parseFloat(price) !== 0
      );
      if (validVersions.length > 0) {
        return true;
      }
    }
  }

  return false;
};

const isRelatedPostExist = (product) => {
  const isRelatedPostExist =
    (product.hasOwnProperty('relatedPosts') &&
      product.relatedPosts.length > 0) ||
    (product.hasOwnProperty('fixedPosts') && product.fixedPosts.length > 0);

  return isRelatedPostExist;
};

export const getProductErrors = (product) => {
  const errors = [];
  if (!product.isBrandModelLocked && !product.isEverPublished) {
    errors.push('Marca y modelo (confirmados)');
  }

  if (!product.hasOwnProperty('name') || '' === product.name.trim()) {
    errors.push('Nombre de producto');
  }

  if (
    !product.hasOwnProperty('dimensionsImage') ||
    product.dimensionsImage === ''
  ) {
    errors.push('Imagen de vista frontal');
  }

  if (
    !product.hasOwnProperty('opinion') ||
    '' === product.opinion.replace(/(<([^>]+)>|&nbsp;|\n)/gim, '')
  ) {
    errors.push('Opinión');
  }

  if (!product.hasOwnProperty('launchDate') || '' === product.launchDate) {
    errors.push('Fecha de lanzamiento');
  }

  if (
    !product.hasOwnProperty('opinionMainAsset') ||
    product['opinionMainAsset'].url === ''
  ) {
    errors.push('Foto o vídeo principal');
  }

  if (!isRelatedPostExist(product)) {
    errors.push('Un post relacionado');
  }

  if (product.hasOwnProperty('versions') && product.versions.length > 0) {
    if (!product['versions'][0]['RAM'] && !product['versions'][0]['ROM']) {
      errors.push('Una versión (RAM/ROM)');
    }
    if (!isValidPrice(product.versions)) {
      errors.push('Precio oficial, o una oferta a la venta');
    }
  } else {
    errors.push('Una versión (RAM/ROM)');
    errors.push('Precio oficial, o una oferta a la venta');
  }

  if (!product.hasOwnProperty('primaryImage') || product.primaryImage === '') {
    errors.push('Imagen para ficha');
  } else if (product.hasOwnProperty('crop')) {
    const { golden, panoramic, square } = product.crop;
    if (!golden.validate || !panoramic.validate || !square.validate) {
      errors.push('Imagen para ficha');
    }
  } else {
    errors.push('Imagen para ficha');
  }

  return errors;
};

export const specDefaultState = {
  weight: '',
  height: '',
  width: '',
  thickness: '',
  waterResistance: 'No',
  fabric: '',
  colorsImage: '',
  realColors: ['Negro', '', ''],
  colors: ['', '', ''],
  designHighlight: '',

  batteryCapacity: '',
  fastCharging: true,
  fastChargingPower: '',
  wirelessCharging: false,
  inverseCharging: false,
  batteryHighlight: '',

  network: '3G',
  dualSIM: [],
  wifi: '',
  bluetooth: '5.0',
  chargingPort: 'microUSB',
  SIM: 'nanoSIM',
  NFC: false,
  navigation: '',
  connectivityHighlight: '',

  frontCameraLensDetails: [initalLensValue],
  videoDetails: '',
  frontCameraImages: [],
  frontCameraHighlight: '',

  fullHD: '',
  HD: '',
  otherVideo: '',
  rearCameraImages: [],
  flash: false,
  eightK: 'No',
  fourK: 'No',
  slowMotion: 'No',
  rearCameraLensDetails: [initalLensValue],
  rearCameraHighlight: '',

  processorMaker: '',
  processorModel: '',
  processorCPU: '',
  processorGPU: '',
  ROMtype: '',
  RAMtype: '',
  performanceImages: [],
  memoryCardCapacity: '',
  memoryCard: false,
  performanceHighlight: '',

  facialRecognition: '2D',
  fingerPrintSensor: 'No',
  securityHighlight: '',

  screenSize: '',
  screenTechnologyDetails: '',
  screenBodyRatio: '',
  screenDensity: '',
  resolution1: '',
  resolution2: '',
  screenFormat: '',
  screenType: 'IPS',
  resolutionType: 'FullHD',
  refreshRates: ['60'],
  screenHighlight: '',

  soundMinijack: false,
  soundDetails: '',
  anotherDetails: '',
  soundHighlight: '',

  softwareVersion: '',
  customizationLayer: '',
  softwareImages: [],
  softwareType: 'Android',
  softwareHighlight: '',
};

const mutlipleValues = [
  'refreshRates',
  'RAM',
  'ROM',
  'rearCameraLensDetails',
  'frontCameraLensDetails',
];

export const formatSpecsValues = (specifications) => {
  const { realColors, colors } = specifications;

  let formattedSpecs = { ...specifications };
  if (realColors && realColors.includes('')) {
    const formattedRealColors = [];
    const formattedColors = [];
    realColors.forEach((realColor, index) => {
      if (realColor !== '') {
        formattedRealColors.push(realColor);
        formattedColors.push(colors && colors[index] ? colors[index] : '');
      }
    });
    if (formattedColors.length === 0) {
      formattedRealColors.push('');
      formattedColors.push('');
    }
    formattedSpecs.realColors = formattedRealColors;
    formattedSpecs.colors = formattedColors;
  }

  mutlipleValues.forEach((value) => {
    const specsValues = specifications[value];

    if (specsValues) {
      let filter;
      if (
        value === 'rearCameraLensDetails' ||
        value === 'frontCameraLensDetails'
      ) {
        filter = (specsValue) => !isEqual(specsValue, initalLensValue);
      } else {
        filter = (specsValue) => specsValue !== '';
      }
      const formattedValues = specsValues.filter(filter);

      if (formattedValues.length === 0) {
        const defaultValue =
          value === 'rearCameraLensDetails' ||
          value === 'frontCameraLensDetails'
            ? initalLensValue
            : '';
        formattedValues.push(defaultValue);
      }
      formattedSpecs[value] = formattedValues;
    }
  });

  return formattedSpecs;
};

const getCompletionScore = (product, filled = 0, total = 0) => {
  let completedFields = filled;
  let totalFields = total;
  for (const key in product) {
    if (typeof product[key] === 'boolean') {
      continue;
    }
    totalFields++;

    if (Array.isArray(product[key])) {
      if (
        product[key].filter((value) => {
          if (typeof value === 'object') {
            return (
              Object.keys(value).filter(
                (objectKey) =>
                  '' !== value[objectKey] &&
                  -1 === ['lensType', 'stabilizer'].indexOf(objectKey)
              ).length > 0
            );
          }

          return '' !== value;
        }).length > 0
      ) {
        completedFields += 1;
      }
      continue;
    }

    if (typeof product[key] === 'object') {
      if (key === 'crop') {
        if (
          product[key].golden.validate &&
          product[key].golden.validate &&
          product[key].panoramic.validate
        ) {
          completedFields++;
        }
        continue;
      }

      if (key === 'opinionMainAsset') {
        if (product[key].url !== '') {
          completedFields++;
        }
        continue;
      }
      const data = getCompletionScore(
        product[key],
        completedFields,
        totalFields
      );
      completedFields = data.completedFields;
      totalFields = data.totalFields - 1; // Subtract parent key count
      continue;
    }

    if (typeof product[key] === 'string') {
      if (!isEmpty(product[key])) {
        completedFields++;
      }
      continue;
    }

    if (typeof product[key] === 'number') {
      if (product[key] > -1) {
        completedFields++;
      }
      continue;
    }
  }

  return { completedFields, totalFields };
};

export const getCompletionPercentage = (product) => {
  const {
    id,
    userId,
    status,
    userName,
    primaryImage,
    presentationDate = '',
    specifications = {},
    suggestions,
    relatedPosts = [],
    fixedPosts = [],
    ...restAttributes
  } = product;

  const {
    colors,
    opinionVideos = [],
    opinionImages = [],
    ...restSpec
  } = specifications;
  const productAttributes = {
    presentationDate,
    relatedPosts: [...relatedPosts, ...fixedPosts],
    ...restAttributes,
    specifications: {
      performanceImages: [],
      softwareImages: [],
      rearCameraImages: [],
      frontCameraImages: [],
      dualSIM: [],
      opinionImages: [...opinionImages, ...opinionVideos],
      ...restSpec,
    },
  };

  const { completedFields, totalFields } =
    getCompletionScore(productAttributes);
  const completionPercentage = (completedFields / totalFields) * 100;

  return Math.round(completionPercentage);
};

export const getFilteredVersions = (versions) => {
  let filteredVersions = [];
  versions.forEach(({ message, ...version }) => {
    if (null != version.RAM || null != version.ROM) {
      filteredVersions.push({ ...version });
    }
  });

  return filteredVersions;
};

export const getUpdatePostMessage = (
  postStatus: string,
  siteUrl: string,
  postId: number,
  difundirUrl: string
) => (
  <span>
    ¡Los cambios se han guardado!{' '}
    {'publish' === postStatus && (
      <>
        <Link color="inherit" href={`${siteUrl}/p/${postId}`}>
          Ver tu artículo publicado.
        </Link>{' '}
      </>
    )}
    Recuerda que lo puedes{' '}
    <RouterLink color="inherit" to={difundirUrl}>
      mandar a portadas en &apos;Difusión&apos;.
    </RouterLink>
  </span>
);

export const getPostUrlPrefix = (siteUrl, siteName, state) => {
  const {
    mainCategory = [],
    juegos = [],
    platforms = [],
    hardware = [],
    others = [],
    contentType,
    categoryNicename,
  } = state;
  if (is3dJuegos(siteName)) {
    if (mainCategory.length > 0) {
      return `${siteUrl}/${categoryNicename}/${contentType.nicename}/`;
    }

    if (juegos.length > 0) {
      return `${siteUrl}/juegos/${categoryNicename}/${contentType.nicename}/`;
    }

    if (platforms.length > 0) {
      return `${siteUrl}/${categoryNicename}/${contentType.nicename}/`;
    }

    if (hardware.length > 0) {
      return `${siteUrl}/hardware/${categoryNicename}/${contentType.nicename}/`;
    }

    if (others.length > 0) {
      return `${siteUrl}/${categoryNicename}/${contentType.nicename}/`;
    }
  }
  return `${siteUrl}/${categoryNicename}/`;
};

export const removeHtmlTags = (text: string) => {
  if (!text) {
    return '';
  }
  return text.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '');
};

export const stripHtml = (text: string, maxLength: number) => {
  text = removeHtmlTags(text);
  text =
    text.length <= maxLength
      ? text
      : text.substring(0, maxLength - 3).concat('...');
  return text;
};

export const isErrorExist = (error) => {
  const errors = Object.values(error);
  const isErrorExist = !!errors.find((value) => !!value);
  return isErrorExist;
};

export const hasCommonKeywords = (haystack: string, needle: string) => {
  // To match incomplete words | string | text
  const haystackString = haystack.toLowerCase();
  const needleKeywords = needle.toLowerCase().split(' ');
  let hasCommonKeywords = false;
  needleKeywords.forEach((keyword) => {
    if (!keyword) {
      return;
    }
    hasCommonKeywords =
      haystackString.indexOf(keyword) >= 0 ? true : hasCommonKeywords;
  });
  return hasCommonKeywords;
};

export const hasCommonFullKeywords = (haystack: string, needle: string) => {
  // To match complete words | string | text
  const haystackString = haystack.toLowerCase();
  const needleKeywords = needle.toLowerCase().split(' ');
  return needleKeywords.some((keyword) => {
    if (!keyword) {
      return false;
    }
    // using a regular expression with word boundaries to match whole word
    const regex = new RegExp(`\\b${keyword}\\b`, 'i');
    return regex.test(haystackString);
  });
};

export const getTocModuleIndex = (sections: Array<Object>) => {
  let index = 0;
  for (const section of sections) {
    if (section.type === 'tableOfContents') {
      return index;
    }
    if (section.type === 'grid') {
      let columnIndex = 0;
      for (const gridColumns of section.columns) {
        let cellIndex = 0;
        for (const gridColumnCell of gridColumns) {
          if (gridColumnCell.type === 'tableOfContents') {
            return `${index}-${columnIndex}-${cellIndex}`;
          }
          cellIndex++;
        }
        columnIndex++;
      }
    }
    index++;
  }
};

export const capitalizeFirstLetter = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const areAllImagesValidated = (crop) => {
  return (
    crop?.golden?.validate &&
    crop?.panoramic?.validate &&
    crop?.square?.validate
  );
};

export const getMappedCategories = (
  data: any,
  postType: string,
  isNanomedio: boolean = false
): any => {
  let categories = [];
  for (let key in data) {
    let categoryGroup = data[key]['children'];
    if (undefined !== categoryGroup) {
      categoryGroup.forEach(function (category) {
        if (!isNanomedio || category['is_nanomedio']) {
          categories.push({
            parent_category: data[key]['cat_name'] || '',
            label: category['cat_name'],
            id: Number(category['id']),
            is_nanomedio: category['is_nanomedio'],
            category_nicename: category['category_nicename'],
          });
        }
      });
    } else if (POST_TYPE_LANDING === postType) {
      let category = data[key];
      categories.push({
        id: Number(category['id']),
        label: category['cat_name'],
        is_nanomedio: false,
        parent_category: '',
      });
    }
  }

  return categories.sort((catA, catB) => {
    if (!catA.value) return 1;
    if (!catB.value) return -1;
    return -catB.parent_category.localeCompare(catA.parent_category);
  });
};

export const filterValidAuthors = (authors) => {
  return authors ? authors.filter((author) => author.id !== null) : [];
};
