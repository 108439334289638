// @flow
import type { Element } from 'React';
import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import { SimpleSelect } from 'components/Editor/Common';
import { getSitesConfig } from 'lib/sites';
import { SelectType } from 'lib/flowTypes';
import Typography from '@material-ui/core/Typography';
import { findItemByValue } from 'lib/helpers';

type Props = {
  site: string,
  handleSiteChange: (site: SelectType) => void,
  criteria?: string,
};

export const SitesDropdown = ({
  site,
  handleSiteChange,
  criteria = '',
}: Props): Element<'div'> => {
  let sitesList = [];
  const sitesConfig = getSitesConfig(site);
  if ('' !== criteria) {
    sitesList = sitesConfig.filter((site) => site[criteria]);
  } else {
    sitesList = sitesConfig;
  }
 
  return (
      <FormControl fullWidth>
        <SimpleSelect
          label={'Medio:'}
          items={sitesList}
          onChange={(e) => {
            const selectedSite = findItemByValue(e.target.value, sitesList);
            handleSiteChange(selectedSite);
          }}
          value={site}
        />
        <Typography
          variant="caption"
          paragraph
          color="textSecondary"
          style={{ marginTop: '8px' }}
        >
          Escribe un poco y se completará automáticamente
        </Typography>
      </FormControl>
  );
};
