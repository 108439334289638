import React from 'react';
import red from '@material-ui/core/colors/red';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

import { Toolbar, SeparatorToolbar } from 'components/Editor/Common/Toolbar';
import { TextField } from 'components/Editor/Common/TextField';

type Props = {
  mode: string,
  placeholder: string,
  value: string,
  isInvalid: boolean,
  onBlur: () => void,
  onClick: () => void,
  onChange: () => void,
  onKeyDown: () => void,
  onDelete: () => void
};

export default class AddLinkComponent extends React.PureComponent<Props> {
  render() {
    const {
      mode,
      onBlur,
      onClick,
      onChange,
      onKeyDown,
      placeholder,
      value,
      onDelete,
      isInvalid = false
    } = this.props;
    return (
      <Toolbar>
        {mode === 'edit' && (
          <>
            <IconButton tooltip="Eliminar" onMouseDown={onDelete}>
              <Delete htmlColor={red[500]} />
            </IconButton>
            <SeparatorToolbar />
          </>
        )}
        <TextField
          hintText={placeholder}
          fullWidth
          autoFocus
          onBlur={onBlur}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={onClick}
          error={isInvalid}
          value={value}
        />
      </Toolbar>
    );
  }
}
