// @flow
import * as React from 'react';
import styles from './DropTargetBox.module.css';

type Props = {
  children: any,
};

export const DropTargetBox = ({
  children,
  ...props
}: Props): React.Element<'div'> => {
  return <div className={styles.container}>{children}</div>;
};
