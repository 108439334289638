// @flow
import * as React from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropTargetBox as DropTargetContainer } from 'components/Editor/Common/Container/DropTargetBox';
import { Caption } from 'components/Editor/Common/Typography/Typo';
import { DropTargetBox } from '.';

type Props = {
  isUploading?: boolean,
  boxContent: React.Node,
  handleFileDrop: (files: Object[]) => void,
};

export class DragAndDropUploader extends React.PureComponent<Props> {
  onDrop: (item: any, monitor: any) => void = (item: any, monitor: any) => {
    if (monitor) {
      const droppedFiles = monitor.getItem().files;
      this.props.handleFileDrop(droppedFiles);
    }
  };

  render(): React.Node | React.Element<'div'> {
    const content = (
      <div>
        <DropTargetContainer>
          <Caption>{this.props.boxContent}</Caption>
        </DropTargetContainer>
      </div>
    );
    const { isUploading = false } = this.props;

    return isUploading ? (
      content
    ) : (
      <DropTargetBox
        accepts={[NativeTypes.FILE]}
        onDrop={this.onDrop}
        content={content}
      />
    );
  }
}

export default DragAndDropUploader;
