// @flow
import * as React from 'react';
import { Grid } from 'components/Editor/Common/Grid/Grid';
import { Checkbox } from '../Common/Checkbox/Checkbox';

type Props = {
  siteName: string,
  repostSites: Array<string>,
  sites: {},
  handleCheck: (e: any, isChecked: boolean) => void,
};

export const RepostSiteListing = ({
  siteName,
  repostSites,
  sites,
  handleCheck,
}: Props): Array<any | React.Node> => {
  const siteKeys = Object.keys(sites).filter((site) => site !== siteName);
  const rows = siteKeys.length;

  const rowsData = [];
  for (let i = 0; i < rows; i++) {
    const site = siteKeys[i];
    rowsData.push(
      <Grid item sm={12} key={`column-${i}`} xs={12}>
        <Checkbox
          checked={repostSites.indexOf(site) !== -1}
          key={site}
          label={sites[site]}
          value={site}
          onChange={handleCheck}
          margin="zero"
        />
      </Grid>
    );
  }

  return rowsData;
};
