// @flow
import * as React from 'react';
import { WarningIcon } from '../CustomSVGIcons';
import styles from './Warning.module.css';

type Props = {
  message: any,
  showIcon?: boolean,
  hint?: string,
  margin?: 'zero' | 'min' | 'normal',
  size?: 'large' | 'small',
  leftSpacing?: boolean,
};

export const Warning = (props: Props): React.Element<'div'> => {
  const {
    message,
    showIcon = true,
    hint = '',
    margin = 'normal',
    leftSpacing = false,
    size = '',
  } = props;

  const classNames = [styles.warning];
  if (margin === 'zero') {
    classNames.push(styles.zero);
  } else if (margin === 'min') {
    classNames.push(styles.min);
  } else {
    classNames.push(styles.normal);
  }

  if (size === 'large') {
    classNames.push(styles.large);
  } else if (size === 'small') {
    classNames.push(styles.small);
  }

  if (leftSpacing) {
    classNames.push(styles.leftMargin);
  }

  return (
    <div className={classNames.join(' ')}>
      <span className={styles.message}>
        {showIcon && <WarningIcon />}
        {message}
      </span>
      {hint && <span className={styles.hint}>{hint}</span>}
    </div>
  );
};
