import React from 'react';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import Error from 'components/Editor/Common/Error';
import { components } from 'react-select';

export const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon style={{ color: 'black' }} fontSize={'large'} />
    </components.DropdownIndicator>
  );
};

type Props = {
  text: string
};

export const ErrorText = ({ text }: Props) => {
  return (
    <div>
      <Error
        fontsize="small"
        margin="min"
        showIcon={false}
        addPrefix={false}
        message={text}
      />
    </div>
  );
};

export const Placeholder = ({ text }: Props) => {
  return (
    <Typography variant="caption" color="inherit" gutterBottom={false}>
      {text}
    </Typography>
  );
};
