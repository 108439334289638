import clonedeep from 'lodash.clonedeep';

import {
  CHANGE_TITLE,
  ADD_SECTION,
  EDIT_SECTION,
  EDIT_TABLE,
  RECEIVE_POST,
  CHANGE_CONTENT,
  DELETE_SECTION,
  DELETE_GRID_SECTION,
  CHANGE_LAYOUT,
  APPEND_SECTION,
  EDIT_DESIGN_FIELD,
  ADD_GRID_SECTION,
  EDIT_GRID_SECTION,
  EDIT_COLUMN_DESIGN_FIELD,
  UNDO_GRID,
  UPDATE_ANCHOR_SOURCE,
  ALTER_TABLE_ROWS,
  ALTER_TABLE_COLUMNS,
  CHANGE_TABLE_ALIGNMENT,
  MODIFY_TABLE_ROW_COLUMN,
} from 'actions/post';
import {
  initialState,
  getSectionsToAdd,
  getGridColumns,
  getInitialState,
  joinTextSections,
  join2SectionArrays,
  joinGridColumns,
  handleTextChange,
  handleTableEdit,
  addNewGridColumns,
  releaseGridColumns,
  findNodeById,
  alterTable,
  changeTableAlignment,
  editTableColumn,
  editTableRow,
  resetTableHeaderFormatting,
} from './lib/sectionHelpers';

const sections = (sections = initialState, action) => {
  switch (action.type) {
    case RECEIVE_POST: {
      const { post } = action;
      if (!post || !post.id) {
        return getInitialState('');
      }

      return post.sections || getInitialState(post.postType);
    }
    case CHANGE_TITLE:
      const sectionAttributes = sections[0];
      return [
        {
          id: 0,
          type: 'title',
          ...sectionAttributes,
          text: action.title,
        },
        ...sections.slice(1),
      ];
    case CHANGE_CONTENT: {
      const { index, section } = handleTextChange(
        action.index,
        sections,
        action.content.text
      );

      return [
        ...sections.slice(0, index),
        {
          ...section,
        },
        ...sections.slice(parseInt(index) + 1),
      ];
    }
    case APPEND_SECTION: {
      let { section } = action;
      const contentSection = {
        id: section.id + 1,
        type: 'content',
        text: '',
      };
      return [...sections, section, contentSection];
    }
    case ADD_SECTION: {
      let { currentIndex, section } = action;

      if ('grid' === section.type) {
        const { columns, id } = getGridColumns(
          section.columnLayout,
          section.id
        );
        section = {
          ...section,
          columns,
          id,
        };
      }

      const sectionsToAdd = getSectionsToAdd(
        sections,
        currentIndex,
        action,
        section
      );

      return [
        ...sections.slice(0, currentIndex),
        ...sectionsToAdd,
        ...sections.slice(parseInt(currentIndex) + 1),
      ];
    }

    case ADD_GRID_SECTION:
      let { currentIndex, section } = action;
      const [gridIndex, colIndex, sectionIndex] = currentIndex.split('-');
      const gridSection = sections[gridIndex];
      if (gridSection.type !== 'grid') {
        console.log('Not a grid');
      }
      const colSections = gridSection['columns'][colIndex];

      const sectionsToAdd = getSectionsToAdd(
        colSections,
        sectionIndex,
        action,
        section
      );
      gridSection['columns'][colIndex] = [
        ...colSections.slice(0, sectionIndex),
        ...sectionsToAdd,
        ...colSections.slice(parseInt(sectionIndex) + 1),
      ];

      return [
        ...sections.slice(0, gridIndex),
        gridSection,
        ...sections.slice(parseInt(gridIndex) + 1),
      ];

    case DELETE_SECTION: {
      const index = parseInt(action.data.index);
      const sectionToAdd = joinTextSections(
        sections[index - 1],
        sections[index + 1],
        action.data.id + 1
      );
      return [
        ...sections.slice(0, index - 1),
        sectionToAdd,
        ...sections.slice(index + 2),
      ];
    }

    case DELETE_GRID_SECTION: {
      const { index, id } = action.data;
      const [gridIndex, colIndex, sectionIndex] = index.split('-').map(Number);
      const section = sections[gridIndex];
      let colSections = section['columns'][colIndex];
      const sectionToAdd = joinTextSections(
        colSections[sectionIndex - 1],
        colSections[sectionIndex + 1],
        id + 1
      );
      section['columns'][colIndex] = [
        ...colSections.slice(0, sectionIndex - 1),
        sectionToAdd,
        ...colSections.slice(sectionIndex + 2),
      ];

      return [
        ...sections.slice(0, gridIndex),
        section,
        ...sections.slice(gridIndex + 1),
      ];
    }

    case EDIT_SECTION: {
      let { index, columnDiff, ...modifiedAttributes } = action.section;
      index = parseInt(index);
      let sectionAttributes = sections[index];

      if (modifiedAttributes.hasRowHeader) {
        sectionAttributes = resetTableHeaderFormatting(
          'row',
          sectionAttributes
        );
      } else if (modifiedAttributes.hasColumnHeader) {
        sectionAttributes = resetTableHeaderFormatting(
          'col',
          sectionAttributes
        );
      }

      if ('grid' === sectionAttributes.type && columnDiff) {
        if (columnDiff < 0) {
          const { section, releasedNodes } =
            releaseGridColumns(modifiedAttributes);
          const joinedNodes = join2SectionArrays(
            releasedNodes,
            sections.slice(index + 1),
            section.id + 1
          );
          return [
            ...sections.slice(0, index),
            {
              ...sectionAttributes,
              ...section,
            },

            ...joinedNodes,
          ];
        }

        const updatedSection = addNewGridColumns(
          sectionAttributes,
          modifiedAttributes
        );
        return [
          ...sections.slice(0, index),
          {
            ...sectionAttributes,
            ...updatedSection,
          },
          ...sections.slice(index + 1),
        ];
      } else {
        return [
          ...sections.slice(0, index),
          {
            ...sectionAttributes,
            ...modifiedAttributes,
          },
          ...sections.slice(index + 1),
        ];
      }
    }

    case EDIT_GRID_SECTION: {
      const { index, ...modifiedAttributes } = action.section;
      const [gridIndex, colIndex, sectionIndex] = index.split('-');
      const gridSection = { ...sections[gridIndex] };
      const colSections = [...gridSection['columns'][colIndex]];
      let sectionAttributes = { ...colSections[sectionIndex] };

      if (modifiedAttributes.hasRowHeader) {
        sectionAttributes = resetTableHeaderFormatting(
          'row',
          sectionAttributes
        );
      } else if (modifiedAttributes.hasColumnHeader) {
        sectionAttributes = resetTableHeaderFormatting(
          'col',
          sectionAttributes
        );
      }

      colSections[sectionIndex] = {
        ...sectionAttributes,
        ...modifiedAttributes,
      };

      gridSection['columns'] = [
        ...gridSection['columns'].slice(0, colIndex),
        colSections,
        ...gridSection['columns'].slice(parseInt(colIndex) + 1),
      ];

      return [
        ...sections.slice(0, gridIndex),
        gridSection,
        ...sections.slice(parseInt(gridIndex) + 1),
      ];
    }

    case EDIT_TABLE: {
      const { index, section } = handleTableEdit(
        action.index,
        sections,
        action.data
      );
      return [
        ...sections.slice(0, index),
        {
          ...section,
        },
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case CHANGE_TABLE_ALIGNMENT: {
      let { index, justify } = action;
      let section = {};

      if (index.indexOf('-') > -1) {
        const [gridIndex, columnIndex, tableIndex] = index.split('-');
        section = { ...sections[gridIndex] };
        const targetColumn = [...section['columns'][columnIndex]];
        const tableAttributes = targetColumn[tableIndex];
        const rows = changeTableAlignment(tableAttributes.rows, justify);
        targetColumn[tableIndex] = { ...tableAttributes, justify, rows };
        section['columns'][columnIndex] = targetColumn;
        index = gridIndex;
      } else {
        const rows = changeTableAlignment(sections[index].rows, justify);
        section = { ...sections[index], justify, rows };
      }

      return [
        ...sections.slice(0, index),
        section,
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case MODIFY_TABLE_ROW_COLUMN: {
      let { index, rowIndex, colIndex, data } = action;
      let section = {};
      let rows = [];

      if (index.indexOf('-') > -1) {
        const [gridIndex, columnIndex, tableIndex] = index.split('-');
        section = { ...sections[gridIndex] };
        const gridColumn = [...section['columns'][columnIndex]];
        const tableAttributes = gridColumn[tableIndex];
        if (rowIndex) {
          rows = editTableRow(tableAttributes.rows, rowIndex, data);
        } else {
          rows = editTableColumn(tableAttributes.rows, colIndex, data);
        }
        gridColumn[tableIndex] = { ...tableAttributes, rows };
        section['columns'][columnIndex] = gridColumn;
        index = gridIndex;
      } else {
        if (rowIndex) {
          rows = editTableRow(sections[index].rows, rowIndex, data);
        } else {
          rows = editTableColumn(sections[index].rows, colIndex, data);
        }
        section = { ...sections[index], rows };
      }

      return [
        ...sections.slice(0, index),
        section,
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case ALTER_TABLE_ROWS: {
      const { index, section } = alterTable({
        subject: 'rows',
        sections,
        ...action,
      });

      return [
        ...sections.slice(0, index),
        section,
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case ALTER_TABLE_COLUMNS: {
      const { index, section } = alterTable({
        subject: 'cols',
        sections,
        ...action,
      });

      return [
        ...sections.slice(0, index),
        section,
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case CHANGE_LAYOUT: {
      const { layout, align = '', index } = action;
      const modifiedNode = {
        ...sections[index],
        layout,
        align,
      };
      if (index && ['left', 'right'].includes(align)) {
        modifiedNode.design = {};
      }

      return [
        ...sections.slice(0, index),
        modifiedNode,
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case EDIT_DESIGN_FIELD: {
      let { index, field, value } = action;
      const sectionAttributes = sections[index];
      const designAttributes = sectionAttributes.design
        ? {
            ...sectionAttributes.design,
          }
        : {};
      if ('' === value || false === value) {
        delete designAttributes[field];
      } else {
        designAttributes[field] = value;
      }
      return [
        ...sections.slice(0, index),
        {
          ...sectionAttributes,
          design: designAttributes,
        },
        ...sections.slice(parseInt(index) + 1),
      ];
    }

    case EDIT_COLUMN_DESIGN_FIELD: {
      let { index, field, value } = action;
      const [gridIndex, colIndex, sectionIndex] = index.split('-');
      const sectionAttributes =
        sections[gridIndex]['columns'][colIndex][sectionIndex];
      const designAttributes = sectionAttributes.design
        ? {
            ...sectionAttributes.design,
          }
        : {};

      if ('' === value || false === value) {
        delete designAttributes[field];
      } else {
        designAttributes[field] = value;
      }

      const section = clonedeep(sections[gridIndex]);
      section['columns'][colIndex][sectionIndex] = {
        ...sectionAttributes,
        design: designAttributes,
      };

      return [
        ...sections.slice(0, gridIndex),
        section,
        ...sections.slice(parseInt(gridIndex) + 1),
      ];
    }

    case UNDO_GRID: {
      let { index, id } = action;
      let gridSection = sections[index];
      const newSections = joinGridColumns(gridSection.columns);
      const joinedSection1 = join2SectionArrays(
        sections.slice(0, index),
        newSections,
        id + 1
      );
      const joinedSection2 = join2SectionArrays(
        joinedSection1,
        sections.slice(parseInt(index) + 1),
        id + 2
      );

      return joinedSection2;
    }

    case UPDATE_ANCHOR_SOURCE: {
      const { targetId, sourceId, sourceText, event } = action;
      const { index, anchorSources } = findNodeById(sections, targetId);

      if (!index) {
        return sections;
      }

      if ('add' === event) {
        anchorSources.push([sourceId, sourceText]);
      } else {
        for (let i = 0; i < anchorSources.length; i++) {
          if (sourceId === anchorSources[i][0]) {
            anchorSources.splice(i, 1);
            break;
          }
        }
      }

      return [
        ...sections.slice(0, index),
        {
          ...sections[index],
          anchorSources,
        },
        ...sections.slice(index + 1),
      ];
    }

    default:
      return sections;
  }
};

export default sections;
