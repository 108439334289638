import decorateComponentWithProps from 'decorate-component-with-props';

import DefaultLink from './components/Link';
import LinkButton from './components/LinkButton';
import AnchorButton from './components/LinkButton/AnchorButton';
import linkStrategy, { matchesEntityType } from './linkStrategy';
import EditorUtils from './utils/EditorUtils';

const anchorPlugin = (
  config = {},
  addLink = () => {},
  updateAnchor = () => {},
  findNodeIndex = () => undefined
) => {
  const { theme = {}, placeholder, Link, linkTarget } = config;

  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
  };

  const getUrl = (editorState) => {
    const linkKey = EditorUtils.getEntityKey(editorState);
    const contentState = editorState.getCurrentContent();
    let url = '',
      isAnchor = false,
      linkId = '';
    if (linkKey) {
      const linkInstance = contentState.getEntity(linkKey);
      url = linkInstance.getData().url;
      linkId = linkInstance.getData().rel;
      const [pageUrl, fragment] = url.split('#');
      isAnchor = fragment && (!pageUrl || pageUrl === window.location.href);
    }
    return { url, linkId, linkKey, isAnchor };
  };

  return {
    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },

    decorators: [
      {
        strategy: linkStrategy,
        matchesEntityType,
        component:
          Link ||
          decorateComponentWithProps(DefaultLink, {
            className: theme.link,
            target: linkTarget,
            findNodeIndex,
          }),
      },
    ],

    LinkButton: decorateComponentWithProps(LinkButton, {
      ownTheme: theme,
      store,
      placeholder,
      addLink,
      getUrl,
    }),

    AnchorButton: decorateComponentWithProps(AnchorButton, {
      store,
      getUrl,
      updateAnchor,
    }),
  };
};

export default anchorPlugin;
