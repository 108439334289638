// @flow
import * as React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MURadio from '@material-ui/core/Radio';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      root: {
        marginLeft: '-9px',
        marginTop: '12px',
        marginRight: '16px',
        alignItems: 'flex-start',

        '@media (min-width:768px)': {
          marginLeft: '-10px',
          marginTop: 0,
        },
      },

      label: {
        color: 'RGBA(0, 0, 0, 0.87)',
        top: '-2px !important',
        position: 'relative',

        '@media (min-width: 768px)': {
          top: '4px !important',
        },
      },
    },

    MuiRadio: {
      root: {
        padding: '2px 8px',
        '@media (min-width:768px)': {
          padding: '4px 8px',
        },
      },
    },

    MuiSvgIcon: {
      root: {
        width: '16px',
        height: '16px',
        '@media (min-width:768px)': {
          width: '1em',
          height: '1em',
        },
      },
    },
  },
});

type Props = {
  label: string,
  value?: string | Object,
  key?: string,
  checked: boolean | string,
  onChange: Function,
};

export const Radio = ({ label = '', ...restProps }: Props): React.Node => (
  <MuiThemeProvider theme={theme}>
    <FormGroup>
      <FormControlLabel
        // $FlowFixMe: suppressing this error until we can refactor
        control={<MURadio color="primary" {...restProps} />}
        label={label}
      />
    </FormGroup>
  </MuiThemeProvider>
);
