// @flow
import * as React from 'react';
import MuiBox from '@mui/material/Box';

type Props = {
  children: any,
};

export const Box = ({ children, ...props }: Props): React.Node => {
  return <MuiBox {...props}>{children}</MuiBox>;
};
