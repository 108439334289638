import React from 'react';
import { createRoot } from 'react-dom/client';

import './i18n';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';

// MS clarity
(function (c, l, a, r, i, t, y) {
  c[a] =
    c[a] ||
    function () {
      (c[a].q = c[a].q || []).push(arguments);
    };
  t = l.createElement(r);
  t.async = 1;
  t.src = 'https://www.clarity.ms/tag/' + i;
  y = l.getElementsByTagName(r)[0];
  y.parentNode.insertBefore(t, y);
})(
  window,
  document,
  'clarity',
  'script',
  process.env.REACT_APP_ms_clarity_tracking_id
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
//registerServiceWorker();
