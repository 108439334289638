// @flow
import * as React from 'react';

import MULink from '@material-ui/core/Link';

type Props = {
  children: React.Node,
};

export const Link = ({ children, ...props }: Props): React.Node => (
  // $FlowFixMe This comment suppresses a Flow error
  <MULink
    underline="always"
    target="_blank"
    rel="noopener noreferrer"
    {...props}
  >
    {children}
  </MULink>
);
