import {
  EDIT_SECTION,
  RECEIVE_POST,
  SHOW_ERRORS,
  CHANGE_TITLE,
  HIDE_ERRORS,
} from 'actions/post';
import { POST_TYPE_LANDING } from 'lib/constants';

const initialState = {
  showErrors: false,
  mainImage: '',
  title: '',
  hasErrors: false,
  emptyPost: false,
  message: '',
  fieldErrors: {},
};

const MAIN_IMAGE =
  'añade una imagen o vídeo a tu artículo para poder publicarlo';
const TITLE = 'Error: el título debe tener al menos 5 caracteres';

const errors = (errors = initialState, action) => {
  switch (action.type) {
    case RECEIVE_POST: {
      let newErrors = {
        ...initialState,
        showErrors: errors.showErrors,
        fieldErrors: errors.fieldErrors,
        message: errors.message,
      };
      const post = action.post;
      if (!post) {
        return {
          ...errors,
        };
      }
      const { sections, title, id, postType } = post;
      if (!id) {
        return {
          ...errors,
          emptyPost: true,
        };
      }
      if (
        POST_TYPE_LANDING !== postType &&
        (!sections || '' === sections[1].type)
      ) {
        newErrors = {
          ...errors,
          hasErrors: true,
          mainImage: MAIN_IMAGE,
        };
      }
      if ('' === title.trim() || 5 > title.trim().length) {
        newErrors = {
          ...newErrors,
          hasErrors: true,
          title: TITLE,
        };
      }
      return newErrors;
    }
    case CHANGE_TITLE: {
      let { title } = action;
      if ('' === title.trim() || 5 > title.trim().length) {
        return {
          ...errors,
          hasErrors: true,
          title: TITLE,
        };
      }

      return {
        ...errors,
        hasErrors: errors.mainImage === '' ? false : true,
        title: '',
      };
    }
    case EDIT_SECTION: {
      let { index, type } = action.section;

      if ('1' === index) {
        if ('' === type) {
          return {
            ...errors,
            mainImage: MAIN_IMAGE,
            hasErrors: true,
          };
        } else {
          return {
            ...errors,
            mainImage: '',
            hasErrors: errors.title !== '',
          };
        }
      }
      return errors;
    }
    case SHOW_ERRORS: {
      return {
        ...errors,
        message: action.message,
        fieldErrors: action.fieldErrors,
        showErrors: true,
      };
    }
    case HIDE_ERRORS: {
      return {
        ...errors,
        message: '',
        fieldErrors: {},
        showErrors: false,
      };
    }
    default:
      return errors;
  }
};

export default errors;
