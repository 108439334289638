import * as React from 'react';
import Box from '@material-ui/core/Box';
import styles from './Container.module.css';

type Props = {
  children: any,
  size?: 'tiny' | 'small' | 'medium' | 'mediumPlus' | 'large' | 'extraLarge',
  bgcolor?:
    | 'primary'
    | 'secondary'
    | 'blueGrey'
    | 'black'
    | 'green'
    | 'transparent',
  padding?: 'light' | 'medium' | 'heavy',
  align?: 'center',
};

const colors = {
  blueGrey: '#E5E9EE',
  black: '#212121',
  grey: '#9B9B9B',
  green: '#4CAF50',
  transparent: 'rgba(0,0,0,0)',
};

export const Container = ({
  size = '',
  bgcolor = '',
  align = '',
  padding = '',
  children,
  ...props
}: Props) => {
  const classNames = [];

  if ('center' === align) {
    classNames.push(styles.alignCenter);
  }

  if ('small' === size) {
    classNames.push(styles.small);
  } else if ('medium' === size) {
    classNames.push(styles.medium);
  } else if ('tiny' === size) {
    classNames.push(styles.tiny);
  } else if ('mediumPlus' === size) {
    classNames.push(styles.mediumPlus);
  } else if ('large' === size) {
    classNames.push(styles.large);
  } else {
    classNames.push(styles.extraLarge);
  }

  if ('blueGrey' === bgcolor) {
    bgcolor = colors.blueGrey;
  } else if ('black' === bgcolor) {
    bgcolor = colors.black;
  } else if ('secondary' === bgcolor) {
    bgcolor = 'secondary.main';
  } else if ('grey' === bgcolor) {
    bgcolor = colors.grey;
  } else if ('green' === bgcolor) {
    bgcolor = colors.green;
  } else if ('transparent' === bgcolor) {
    bgcolor = colors.transparent;
  } else {
    bgcolor = 'primary.main';
  }

  if ('medium' === padding) {
    padding = '24px';
  } else {
    padding = '0';
  }

  return (
    <Box
      component="div"
      className={classNames.join(' ')}
      bgcolor={bgcolor}
      padding={padding}
      {...props}
    >
      {children}
    </Box>
  );
};
