// @flow
import * as React from 'react';

import { CheckCircleIcon } from 'components/Editor/Common/Icons';
import styles from './SuccessMessage.module.css';
import { Link } from '../Link/Link';

type Props = {
  message: string,
  margin?: 'zero' | 'normal' | 'dense' | 'large',
  url?: string,
  urlText?: string,
  showCheckIcon?: boolean,
  leftSpacing?: boolean,
  onLinkClick?: Function,
  size?: 'small',
};

const SuccessMessage = (props: Props): React.Element<'div'> => {
  const {
    message,
    url = '',
    urlText = '',
    margin = 'normal',
    showCheckIcon = true,
    leftSpacing = false,
    onLinkClick = null,
    size = '',
  } = props;

  const classNames = [styles.Success];
  if (margin === 'dense') {
    classNames.push(styles.dense);
  } else if (margin === 'zero') {
    classNames.push(styles.zero);
  } else {
    classNames.push(styles.normal);
  }

  if (leftSpacing) {
    classNames.push(styles.leftMargin);
  }

  if (size === 'small') {
    classNames.push(styles.small);
  }

  return (
    <div className={classNames.join(' ')}>
      {showCheckIcon && <CheckCircleIcon />}
      <span>
        {message}{' '}
        {('' !== url || onLinkClick !== null) && (
          <Link href={url} onClick={onLinkClick}>
            {urlText}
          </Link>
        )}
      </span>
    </div>
  );
};

export default SuccessMessage;
