// @flow
import type { Node } from 'React';
import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import grey from '@material-ui/core/colors/grey';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import styles from './CloseButton.module.css';

const grey700 = grey['700'];

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        padding: '12px',

        '@media(max-width: 768px)': {
          padding: '0',
          verticalAlign: 'text-top',
        },
      },
    },
  },
});

type Props = {
  handleClose: () => void,
};

export function CloseButton(props: Props): Node {
  return (
    <MuiThemeProvider theme={theme}>
      <IconButton onClick={props.handleClose}>
        <Close
          fontSize="small"
          className={styles.btnClose}
          htmlColor={grey700}
        />
      </IconButton>
    </MuiThemeProvider>
  );
}
