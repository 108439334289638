// @flow
import * as React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { TextButton } from 'components/Editor/Common/Buttons/Button';
import { ParagraphSmall } from 'components/Editor/Common/Typography/Typo';
import { Grid } from 'components/Editor/Common';
import { isMobile } from 'lib/deviceDetect';
import styles from './SnackbarConfirmation.module.css';

type Props = {
  message: string,
  handleClose: Function,
  closeButtonLabel: string,
  confirmationButtonLabel: string,
  handleConfirmation: Function,
  horizontal?: 'left' | 'center',
  layout?: 'column',
  size?: 'small' | 'medium',
  position?: 'topEdge',
};

let theme = createTheme({
  overrides: {
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#FFD54F',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '816px',
        color: 'rgba(0,0,0,0.87)',
        padding: '8px 16px',
        boxSizing: 'border-box',

        '@media(max-width: 960px)': {
          width: '700px',
          justifyContent: 'center',
        },
      },

      message: {
        color: 'inherit',

        '@media(min-width: 1280px)': {
          whiteSpace: 'normal',
        },
      },
      action: {
        marginLeft: '0 !important',
        marginRight: '0 !important',
        paddingLeft: '0 !important',
      },
    },
  },
});

theme.overrides.MuiSnackbar = {
  root: {
    zIndex: '1300',
  },

  anchorOriginTopCenter: {
    top: '105px',

    '@media(min-width: 600px)': {
      top: '105px',
    },

    '@media(min-width: 768px)': {
      top: '96px',
    },

    '@media(min-width: 1280px)': {
      top: '72px',
    },
  },
};

export const SnackbarConfirmation = ({
  message,
  closeButtonLabel,
  confirmationButtonLabel,
  horizontal = 'center',
  handleClose,
  handleConfirmation,
  layout = '',
  size = '',
  position = '',
}: Props): React.Node => {
  let layoutClass = [];

  if (layout === 'column') {
    layoutClass.push(styles.column);
  }

  if (position === 'topEdge') {
    layoutClass.push(styles.topEdge);
  }

  if (size === 'small') {
    layoutClass.push(styles.small);
  } else if (size === 'medium') {
    layoutClass.push(styles.medium);
  }

  const action = (
    <Grid
      container
      gutter="custom"
      spacing={isMobile() ? '1' : '0'}
      justify={isMobile() ? 'center' : 'flex-end'}
    >
      <Grid item>
        <div className={styles.buttonWrapper}>
          <TextButton style={{ opacity: '0.7' }} onClick={handleClose}>
            {closeButtonLabel}
          </TextButton>
          <TextButton onClick={handleConfirmation}>
            {confirmationButtonLabel}
          </TextButton>
        </div>
      </Grid>
    </Grid>
  );

  return (
    <div className={styles.backdrop}>
      <MuiThemeProvider theme={theme}>
        <MuiSnackbar
          open
          message={<ParagraphSmall>{message}</ParagraphSmall>}
          action={action}
          anchorOrigin={{ vertical: 'top', horizontal }}
          className={layoutClass}
        />
      </MuiThemeProvider>
    </div>
  );
};
