// @flow
import * as React from 'react';
import { Grid } from 'components/Editor/Common/Grid/Grid';
import { Label, CircularLoader, Box, Error } from '../Common';
import { PrimaryButton } from '../Common/Buttons/Button';
import { InputEvent } from 'lib/flowTypes';
import { getSiteGroups } from './lib/constants';
import { RepostSiteGroupOption } from './RepostSiteGroupOption';
import { RepostSiteListing } from './RepostSiteListing';
import SuccessMessage from '../Common/Success/SuccessMessage';
import styles from './RepostSiteOptions.module.css';
import { Warning } from 'components/Editor/Common/Warning/Warning';

type Props = {
  id: number,
  repostBlogs: Array<string>,
  setRepostBlogs: (key: string, value: boolean) => void,
  blogName: string,
  postStatus: string,
  repostSites: Array<string>,
  submitRepostedBlogs: () => any,
  showRepostError: boolean,
  showSubmitError?: boolean,
  showSuccessMessage?: boolean,
  warning?: string,
  loading?: boolean,
};

type State = {
  isLoading: boolean,
};

export default class RepostSiteOptions extends React.PureComponent<
  Props,
  State
> {
  state: State = {
    isLoading: false,
  };

  componentDidMount() {
    if (this.props.id !== undefined) {
      this.initalRepostedBlogs = this.props.repostBlogs.slice();
      this.forceUpdate();
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { id, repostBlogs } = this.props;
    if (id !== undefined && id !== prevProps.id) {
      this.initalRepostedBlogs = repostBlogs.slice();
      this.forceUpdate();
    }
  }

  initalRepostedBlogs: Array<string> | null = null;

  handleCheck = (e: InputEvent, isChecked: boolean) => {
    this.props.setRepostBlogs(e.currentTarget.value, isChecked);
  };

  submitRepostedBlogs: () => Promise<void> = async () => {
    this.setState({ isLoading: true });
    await this.props.submitRepostedBlogs();
    this.initalRepostedBlogs =
      this.props.postStatus === 'publish' ? [] : [...this.props.repostBlogs];
    this.setState({ isLoading: false });
  };

  render(): React.Element<'div'> {
    const {
      blogName,
      repostBlogs,
      showRepostError,
      showSubmitError,
      showSuccessMessage,
      warning,
    } = this.props;
    const allSiteGroups = getSiteGroups();
    const { latinamerica, ...siteGroups } = allSiteGroups;
    const isDisabled = this.state.isLoading || this.props.loading;

    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Label label="Medios donde enviar crosspost" />
          </Grid>
        </Grid>
        {showRepostError && (
          <Grid container>
            <Grid item xs={12}>
              <Error
                margin="dense"
                message="hay que seleccionar al menos un medio de destino"
              />
            </Grid>
          </Grid>
        )}
        <Grid container gutter="custom" spacing={6}>
          {Object.keys(siteGroups).map((key) => (
            <Grid item md={2} sm={4} key={key} xs={12}>
              <RepostSiteGroupOption
                groupName={key}
                siteName={blogName}
                repostSites={repostBlogs}
                handleCheck={this.handleCheck}
              />
              <Grid container gutter="custom" spacing={2}>
                <RepostSiteListing
                  sites={siteGroups[key].sites}
                  siteName={blogName}
                  repostSites={repostBlogs}
                  initialRepostedSites={this.initalRepostedBlogs}
                  handleCheck={this.handleCheck}
                />
              </Grid>
            </Grid>
          ))}
          <Grid item md={12} sm={12} xs={12}>
            <RepostSiteGroupOption
              groupName={'latinamerica'}
              siteName={blogName}
              repostSites={repostBlogs}
              handleCheck={this.handleCheck}
            />

            <Box
              component={Grid}
              container
              gutter="custom"
              sx={{ m: { xs: -1, sm: -3 }, width: 'auto !important' }}
            >
              {Object.keys(latinamerica).map((key) => (
                <Box
                  component={Grid}
                  key={key}
                  item
                  md={2}
                  sm={4}
                  xs={12}
                  sx={{ p: { xs: 1, sm: 3 } }}
                  display={
                    Object.keys(latinamerica[key].sites).length === 0
                      ? { xs: 'none', lg: 'block' }
                      : 'block'
                  }
                >
                  <Grid container gutter="custom" spacing={2}>
                    <RepostSiteListing
                      key={key}
                      sites={latinamerica[key].sites}
                      siteName={blogName}
                      repostSites={repostBlogs}
                      initialRepostedSites={this.initalRepostedBlogs}
                      handleCheck={this.handleCheck}
                    />
                  </Grid>
                </Box>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container gutter="custom" spacing={0}>
              <Box component={Grid} sx={{ marginRight: '12px' }} item xs="auto">
                <PrimaryButton
                  disabled={isDisabled}
                  onClick={this.submitRepostedBlogs}
                >
                  {this.state.isLoading || this.props.loading ? (
                    <>
                      <CircularLoader
                        show
                        className={styles.buttonLeftIcon}
                        color="inherit"
                      />
                      Enviar Crosspost
                    </>
                  ) : (
                    'Enviar Crosspost'
                  )}
                </PrimaryButton>
              </Box>

              {(showRepostError || showSubmitError) && (
                <Grid item xs="auto">
                  <Error message="hay fallos por revisar" margin="dense" />
                </Grid>
              )}

              {showSuccessMessage && (
                <Grid item xs="auto">
                  <SuccessMessage
                    message="Enviado correctamente. Aparecerá en su destino cuando sea aprobado por el equipo editorial"
                    margin="dense"
                  />
                </Grid>
              )}

              {warning && (
                <Grid item xs="auto">
                  <Warning size="large" margin="normal" message={warning} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
