import React, { Component } from 'react';
import EditorUtils from '../draft-js-anchor-plugin/utils/EditorUtils.js';
type Props = {
  store: Object,
  theme: Object,
  addSummary: Function
};

export default class ExtractSummaryButton extends Component<Props> {
  onMouseDown = event => {
    event.preventDefault();
  };

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const { store, addSummary } = this.props;
    const editorState = store.getEditorState();

    const selectionState = editorState.getSelection();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();
    const currentBlockKey = selectionState.getEndKey();
    const currentContent = editorState.getCurrentContent();
    let splitPosition = currentContent.getBlockMap()._map.get(currentBlockKey);
    // To fix double click issue.
    if (startOffset === endOffset) {
      splitPosition -= 1;
    }
    addSummary(EditorUtils.getSelectionText(editorState), splitPosition);
  };

  render() {
    const { theme } = this.props;
    return (
      <div className={theme.buttonWrapper} onMouseDown={this.onMouseDown}>
        <button
          id="toolbar-link"
          className={theme.button}
          onClick={this.onClick}
          type="button"
        >
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M3 21h18v-2H3v2zM3 8v8l4-4-4-4zm8 9h10v-2H11v2zM3 3v2h18V3H3zm8 6h10V7H11v2zm0 4h10v-2H11v2z" />
          </svg>
          <span className="tooltiptext">Sumario</span>
        </button>
      </div>
    );
  }
}
