import React from 'react';
import { getSiteIcon } from 'lib/helpers';
import { Grid, AppBar } from 'components/Editor/Common';
import { H6 } from 'components/Editor/Common/Typography/Typo';
import styles from './TitleBar.module.css';

type Props = {
  siteName: string,
  adminUrl: string,
  title: string
};

export const TitleBar = ({ siteName, adminUrl, title }: Props) => {
  return (
    <AppBar>
      <Grid
        container
        gutter="custom"
        spacing={0}
        alignItems="flex-end"
        style={{ height: 'inherit' }}
      >
        <Grid
          item
          container
          gutter="custom"
          spacing={0}
          md={4}
          style={{ alignSelf: 'center' }}
        >
          <Grid item>
            <div className={styles.siteLogo}>
              {getSiteIcon(siteName, adminUrl)} <H6>{title}</H6>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default TitleBar;
