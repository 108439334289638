//@flow
import type { Node as $IMPORTED_TYPE$_Node } from 'React';
import React from 'react';
import type { Node } from 'react';
import MuiTabs from '@material-ui/core/Tabs';

type Props = {
  children: Node,
};

export const Tabs = ({ children, ...props }: Props): $IMPORTED_TYPE$_Node => {
  return <MuiTabs {...props}>{children}</MuiTabs>;
};
