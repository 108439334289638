//@flow
import type { Node } from 'React';
import React, { memo } from 'react';
import {
  Grow,
  Paper,
  Popper as MuiPopper,
} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from './Popper.module.css';
import { ClickAwayListener } from 'components/Editor/Common';

const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        minWidth: '194px',
        backgroundColor: '#FAFAFA',

        '@media(max-width: 767px)': {
          position: 'absolute',
          bottom: '47px',
          width: 'max-content',
        },
      },
    },
  },
});

type Props = {
  open: boolean,
  anchorEl: any,
  handleClickAwayListener: () => void,
  placement?: 'top' | 'bottom' | 'right',
  position?: 'bottomRight',
  children: any,
};

export const Popper = ({
  open,
  anchorEl,
  handleClickAwayListener,
  placement = 'right',
  position = '',
  children,
}: Props): Node => {
  let classNames = '';

  if (position === 'bottomRight') {
    classNames = styles.positionBottomRight;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPopper
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement={placement}
        className={classNames}
        style={{ zIndex: 2, display: 'inline' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAwayListener}>
                <span>{children}</span>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </MuiPopper>
    </MuiThemeProvider>
  );
};

export default (memo<Props>(Popper): React$AbstractComponent<Props, mixed>);
