// @flow
import * as React from 'react';
import {
  DailymotionIcon,
  YoutubeIcon,
  VimeoIcon,
  FacebookIcon,
} from 'components/Editor/Common/svgIcons';
import styles from './VideoOptions.module.css';

type props = {
  isPrimaryVideo: boolean,
  showYoutube: boolean,
};

export const VideoUploaderOptions = ({
  isPrimaryVideo = false,
  showYoutube = true,
}: props) => (
  <div className={styles.container}>
    <label>Puedes usar:</label>
    <div className={styles.options}>
      <DailymotionIcon />
      <span className={styles.caption}>Dailymotion</span>
      {showYoutube ? (
        <>
          <YoutubeIcon />
          <span className={styles.caption}>Youtube</span>
        </>
      ) : (
        ''
      )}
      {!isPrimaryVideo && (
        <React.Fragment>
          <VimeoIcon />
          <span className={styles.caption}>Vimeo</span>
          <FacebookIcon />
          <span className={styles.caption}>Facebook</span>
        </React.Fragment>
      )}
    </div>
  </div>
);
