import React from 'react';
import type { Node } from 'react';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './Typo.module.css';

const theme = createTheme({
  typography: {
    h6: {
      lineHeight: '28px',
      height: 'auto !important',
      width: 'auto !important',
      padding: '0 !important',
      borderRadius: '0 !important',

      '@media(minWidth: 768px)': {
        height: 'auto !important',
        width: 'auto !important',
      },
    },
  },
});

type Props = {
  faded?: Boolean,
  children: Node,
  icon?: Object,
};

type SubtitleProps = {
  isBold?: Boolean,
  children: Node,
};

type ListProps = {
  list: Array<string>,
};

const smallCaptionStyles = {
  root: {
    fontSize: '10px !important',
    lineHeight: '16px !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const smallParagraphStyles = {
  root: {
    fontSize: '15px !important',
    lineHeight: '18px !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const smallH4Styles = {
  root: {
    fontSize: '28px !important',
    lineHeight: '1.43 !important',
    fontWeight: 'bold !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const smallH6Styles = {
  root: {
    fontSize: '16px !important',
    lineHeight: '24px !important',
    fontWeight: 'bold !important',
    color: 'RGBA(0, 0, 0, 0.87) !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const smallH5Styles = {
  root: {
    fontSize: '23px !important',
    lineHeight: '1 !important',
    fontWeight: 'bold !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const subHeadingStyles = {
  root: {
    lineHeight: '24px !important',
    fontWeight: 'bold !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const bigParagraphStyles = {
  root: {
    fontSize: '19px !important',
    lineHeight: '28px !important',
    margin: '0 0 24px !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const bigCaptionStyles = {
  root: {
    fontSize: '13px !important',
    lineHeight: '16px !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

const subTitleStyles = {
  root: {
    lineHeight: '24px !important',
    margin: '0 0 16px !important',
    height: 'auto !important',
    width: 'auto !important',
    padding: '0 !important',
    borderRadius: '0 !important',

    '@media(minWidth: 768px)': {
      height: 'auto !important',
      width: 'auto !important',
    },
  },
};

export const H4 = ({ children, ...rest }: Props) => (
  <Typography variant="h4" {...rest}>
    {children}
  </Typography>
);

export const H4Small = withStyles(smallH4Styles)(H4);

export const H6 = ({ icon = '', children, ...rest }: Props) => {
  const Icon = icon;
  return (
    <ThemeProvider theme={theme}>
      <Typography variant="h6" {...rest}>
        {icon && (
          <span className={styles.iconLeftAlign}>
            <Icon fontSize="small" />
          </span>
        )}
        {children}
      </Typography>
    </ThemeProvider>
  );
};

export const H6Small = withStyles(smallH6Styles)(H6);

export const ParagraphBig = withStyles(bigParagraphStyles)(H6);

export const small = ({ children, faded = false, ...rest }: Props) => (
  <Typography
    variant="subtitle1"
    {...rest}
    color={faded ? 'textSecondary' : 'initial'}
  >
    {children}
  </Typography>
);

export const ParagraphSmall = withStyles(smallParagraphStyles)(small);

export const Paragraph = ({ children, faded = false, ...rest }: Props) => (
  <Typography
    variant="subtitle1"
    {...rest}
    color={faded ? 'textSecondary' : 'initial'}
  >
    {children}
  </Typography>
);

export const SubHeading = withStyles(subHeadingStyles)(Paragraph);

export const H5 = ({ children, ...rest }: Props) => (
  <Typography variant="h5" {...rest}>
    {children}
  </Typography>
);

export const H5Small = withStyles(smallH5Styles)(H5);

export const SubTitle = ({ isBold = false, children }: SubtitleProps) => (
  <Typography variant={isBold ? 'subtitle2' : 'body2'}>{children}</Typography>
);

export const SubTitle1 = withStyles(subTitleStyles)(Paragraph);

export const BlockText = ({ children }: Props) => (
  <span className={styles.blockText}>{children}</span>
);

export const Caption = ({ children, faded = false, ...rest }: Props) => (
  <Typography
    variant="caption"
    color={faded ? 'textSecondary' : 'initial'}
    {...rest}
  >
    {children}
  </Typography>
);

export const CaptionBig = withStyles(bigCaptionStyles)(Caption);

export const CaptionSmall = withStyles(smallCaptionStyles)(Caption);

export const ContentText = ({ children }: Props) => (
  <span className={styles.contentText}>{children}</span>
);

export const SimpleList = ({ list }: ListProps) => (
  <ul className={styles.list}>
    {list.map((item) => (
      <li key={item}>{item}</li>
    ))}
  </ul>
);
