import decorateComponentWithProps from 'decorate-component-with-props';
import ExtractSummaryButton from './ExtractSummaryButton';

const summaryPlugin = (addSummary, config = {}) => {
  const { theme = {} } = config;

  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
  };

  return {
    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },
    ExtractSummaryButton: decorateComponentWithProps(ExtractSummaryButton, {
      addSummary,
      ownTheme: theme,
      store,
    }),
  };
};

export default summaryPlugin;
