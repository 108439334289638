import * as React from 'react';
import MuiAutocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { TextField } from '../TextField';
import { findItemByValue, isValidUrl } from 'lib/helpers';
import styles from './Autocomplete.module.css';

type Props = {
  label: string,
  value: { name: string, id: number } | null,
  onChange: Function,
  options: Array<{ name: string, id: number }>,
  placeholder?: string,
  createNew?: Function,
  errorText?: string,
  labelKey?: string,
  freeSolo?: boolean,
  autoSelect?: boolean,
  disabled?: boolean,
  disableClearable?: boolean,
  searchKey?: string,
  helperText?: string,
  showOptionsOnEmptyInput?: boolean,
  searchUrlKey?: string,
};

const theme = createTheme({
  palette: {
    action: {
      selected: '#EEEEEE',
      hover: '#FCE4EC',
    },
  },
  overrides: {
    MuiAutocomplete: {
      inputRoot: {
        padding: '0 !important',
      },
      input: {
        padding: '10px 14px 6px !important',
      },
    },
  },
});

const Autocomplete = ({
  value,
  onChange,
  options,
  label,
  placeholder = '',
  createNew = null,
  errorText = '',
  disableClearable = true,
  labelKey = 'name',
  freeSolo = false,
  autoSelect = false,
  disabled = false,
  searchKey = '',
  searchUrlKey = '',
  helperText = '',
  showOptionsOnEmptyInput = true,
}: Props): React.Node => {
  const [noOptionsText, setNoOptionsText] = React.useState('Buscar');
  const filterOptions = (options, params) => {
    let filter = createFilterOptions();
    if (searchKey) {
      let key = searchKey;
      if ('' !== searchUrlKey && isValidUrl(params.inputValue)) {
        key = searchUrlKey;
      }
      filter = createFilterOptions({
        stringify: (option) => option[key],
      });
    }

    let filtered;
    if (!showOptionsOnEmptyInput && '' === params.inputValue) {
      filtered = filter([], params);
    } else {
      filtered = filter(options, params);
    }

    if (createNew) {
      const searchedItem = findItemByValue(
        params.inputValue.trim(),
        options,
        labelKey
      );
      if (
        'undefined' === typeof searchedItem &&
        createNew &&
        '' !== params.inputValue
      ) {
        filtered.push({
          inputValue: params.inputValue,
          [labelKey]: (
            <span className={styles.highlight}>
              — CREAR {label.toUpperCase()}{' '}
              <i>&quot;{params.inputValue}&quot;</i>
            </span>
          ),
        });
      }
    }
    return filtered;
  };

  const extraParams = {};

  if (!showOptionsOnEmptyInput) {
    extraParams['forcePopupIcon'] = false;
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiAutocomplete
        freeSolo={freeSolo}
        autoSelect={autoSelect}
        value={value}
        onChange={async (event, selectedValue) => {
          if (typeof selectedValue === 'string') {
            onChange({ [labelKey]: selectedValue });
          } else if (selectedValue && selectedValue.inputValue) {
            const response = await createNew(selectedValue.inputValue);
            onChange({ [labelKey]: selectedValue.inputValue, ...response });
          } else {
            onChange(selectedValue);
          }
        }}
        getOptionSelected={(option, value) => {
          return option.id === value.id;
        }}
        filterOptions={filterOptions}
        selectOnFocus
        disableClearable={disableClearable}
        disabled={disabled}
        clearOnBlur
        onInputChange={(e, inputValue) => {
          if (inputValue !== '' && noOptionsText === 'Buscar') {
            setNoOptionsText('Sin opciones');
          } else if (inputValue === '' && noOptionsText !== '') {
            setNoOptionsText('Buscar');
          }
        }}
        handleHomeEndKeys
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }

          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option[labelKey] ? option[labelKey] : '';
        }}
        renderOption={(option) => option[labelKey]}
        renderInput={({ InputLabelProps, ...other }) => (
          <TextField
            {...other}
            label={label}
            placeholder={placeholder}
            variant="outlined"
            error={'' !== errorText}
            errorText={errorText}
            helperText={helperText}
          />
        )}
        noOptionsText={noOptionsText}
        {...extraParams}
      />
    </MuiThemeProvider>
  );
};

export default Autocomplete;
