import * as React from 'react';

import { Info } from 'components/Editor/Common';
import styles from './withLabel.module.css';

type Props = {
  label?: string,
  isFocused: boolean,
  error?: boolean,
  errorText?: string,
  helperText?: string,
  editor: React.Node,
};

export const withLabel = ({
  label = '',
  isFocused,
  error = false,
  errorText = '',
  editor,
  helperText,
}: Props) => {
  const getEditorClassName = () => {
    let className = styles.editor;
    if (error) {
      className += ' ' + styles.editorError;
    } else if (isFocused) {
      className += ' ' + styles.editorFocus;
    }

    return className;
  };

  const getLabelClassName = () => {
    let className = styles.caption;
    if (error) {
      className += ' ' + styles.captionError;
    } else if (isFocused) {
      className += ' ' + styles.captionFocus;
    }

    return className;
  };

  return (
    <>
      <div className={getEditorClassName()}>
        {label && <label className={getLabelClassName()}>{label}</label>}
        <div className={styles.textarea}>{editor}</div>
        {error && (
          <span className={styles.errorAlertSmall}>Error: {errorText}</span>
        )}
      </div>
      {!!helperText && (
        <Info
          size="small"
          margin="dense"
          message={helperText}
          showIcon={false}
        />
      )}
    </>
  );
};
