// @flow
import React, { useState } from 'react';

import { SubTitle } from 'components/Editor/Common/Typography/Typo';
import { DragAndDropUploader } from 'components/Editor/ImageUploader';
import { NormalButton } from 'components/Editor/Common/Buttons/Button';
import { InsertPhotoIcon } from 'components/Editor/Common/Icons';
import { Backdrop, CircularLoader, Image } from 'components/Editor/Common';
import style from './UploadImage.module.css';
import { ImageType } from 'lib/flowTypes';
import { uploadImages } from 'components/Editor/ImageUploader/lib/helpers';

type Props = {
  title: string,
  imageSrc: string,
  onSubmit: Function,
  images?: Array<ImageType>,
  multiple?: boolean,
  firebaseId: string,
  size?: 'square' | 'panoramic' | 'rectangle',
  type?: 'post' | 'product',
  openModal: Function,
  errorText?: string,
  isImageUploading?: boolean,
};

const UploadImage = ({
  title,
  imageSrc,
  onSubmit,
  images = [],
  multiple = false,
  firebaseId,
  size = '',
  type = 'product',
  openModal,
  errorText = '',
  isImageUploading = false,
}: Props) => {
  const classNames = [style.dropArea];
  if (size === 'square') {
    classNames.push(style.square);
  } else if (size === 'panoramic') {
    classNames.push(style.panoramic);
  } else if (size === 'rectangle') {
    classNames.push(style.rectangle);
  }

  if (errorText !== '') {
    classNames.push(style.error);
  }

  const [isLoading, setIsloading] = useState(false);

  const handleSelect = async (files, isDragged = false) => {
    try {
      setIsloading(true);
      const images = await uploadImages(files, firebaseId, 'xataka', type);
      const numberOfImages = images.length || 0;

      if (type === 'post') {
        openModal(isDragged, images[0]);
        return
      }

      if (multiple) {
        onSubmit(images);
        return;
      }

      if (numberOfImages === 1) {
        openModal(
          'imageCropper',
          'add',
          { name: 'imagePanel', mode: 'add', data: { ...images[0] } },
          onSubmit
        );
      } else {
        openModal('imagePanel', 'add', {}, handleSubmit);
      }
    } catch (e) {
    } finally {
      setIsloading(false);
    }
  };

  const getImage = () => {
    if (imageSrc) {
      return (
        <>
          <div className={style.dropAreaImage}>
            {images.length > 0 && (
              <div className={style.watermark}>+ {images.length}</div>
            )}
            <Image
              src={imageSrc}
              onLoad={() => setIsloading(false)}
              size="fullWidth"
              alt=""
            />

            {isLoading && !multiple && (
              <Backdrop>
                <CircularLoader show />
              </Backdrop>
            )}
          </div>
        </>
      );
    }

    const dragAndDropHelperText =
      errorText !== '' ? errorText : 'Arrastra aquí la imagen';
    return (
      <div className={classNames.join(' ')}>
        <DragAndDropUploader
          isUploading={false}
          boxContent={
            <>
              <InsertPhotoIcon /> {dragAndDropHelperText}
            </>
          }
          handleFileDrop={(files) => handleSelect(files, true)}
        />
        {(isLoading || isImageUploading) && (
          <Backdrop>
            <CircularLoader show />
          </Backdrop>
        )}
      </div>
    );
  };

  const handleSubmit = (url) => {
    setIsloading(true);
    onSubmit(url);
  };

  const handleClick = () => {
    let modelName = 'imagePanel';
    if (multiple) {
      modelName = 'multipleImagePanel';
    }
    openModal(modelName, 'add', {}, handleSubmit, {}, { images });
  };

  return (
    <div>
      <SubTitle isBold="true">{title}</SubTitle>
      {getImage()}
      <div className={style.button}>
        <NormalButton
          label={multiple ? 'Elegir imagenes' : 'Elegir imagen'}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default UploadImage;
