export const containerStyles = {
  position: 'relative',
  width: 'inherit',
};

const styles = {
  textAlign: 'left',
  padding: '2px 8px',
  boxSizing: 'border-box',
  alignSelf: 'flex-end',
  color: '#212121',
};

export const ReactSelectStyles = {
  noOptionsMessage: () => styles,
  loadingMessage: () => styles,
  dropdownIndicator: () => ({
    padding: '0',
    display: 'flex',
    alignItems: 'flex-end',
    height: 'inherit',
  }),
  control: (base, state) => {
    if (state.isFocused) {
      return {
        ...base,
        outline: 'none',
        backgroundColor: 'white',
        padding: '0 0 0 10px',
        flexWrap: 'nowrap',
        borderColor: '#3f51b5 !important',
        borderRadius: '4px',
        boxSizing: 'border-box',
        minHeight: '40px',
        boxShadow: 'inset 0 0 0px 1px #3f51b5',
      };
    }

    if (state.isDisabled) {
      return {
        ...base,
        boxShadow: 'none',
        outline: 'none',
        backgroundColor: 'white',
        padding: '0 0 0 10px',
        flexWrap: 'nowrap',
        borderColor: '#3f51b5 !important',
        color: '#f2f2f2',
        pointerEvents: 'none',
        opacity: '0.4',
        borderRadius: '4px',
        boxSizing: 'border-box',
        minHeight: '40px',
      };
    }

    return {
      ...base,
      borderColor: 'rgba(0,0,0,0.23)',
      display: 'flex',
      backgroundColor: 'white',
      padding: '0 0 0 10px',
      flexWrap: 'nowrap',
      borderRadius: '4px',
      boxSizing: 'border-box',
      minHeight: '40px',

      '@media(max-width: 1280px)': {
        fontSize: '14px',
      },
    };
  },
  option: (base, state) => {
    return {
      ...base,
      color: '#000000',
      backgroundColor: state.isSelected ? '#EEEEEE' : 'inherit',
      '&:hover': {
        backgroundColor: state.isSelected ? '#EEEEEE' : '#FCE4EC',
      },
    };
  },

  groupHeading: () => ({
    textTransform: 'capitalize',
    color: '#999',
    padding: '12px 12px',
  }),

  indicatorsContainer: () => ({
    display: 'flex',
    alignItems: 'flex-end',
    transform: 'scale(0.6)',
  }),

  singleValue: () => ({
    height: '20px',
    color: '#212121',
    marginLeft: '2px',
    marginRight: '2px',
    maxWidth: 'calc(100% - 8px)',
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    top: '20px',
    transform: 'translateY(-50%)',
    boxSizing: 'border-box',
    bottom: '0',
    left: '10px',
    lineHeight: '20px',
    paddingRight: '22px',
  }),

  menuList: () => ({
    color: '#212121',
    minHeight: '48px',
    maxHeight: '240px',
    overflowY: 'auto',
    padding: '10px 0 0',
    boxSizing: 'border-box',
    WebkitOverflowScrolling: 'touch',
  }),

  valueContainer: () => ({
    paddingLeft: '0',
    color: '#212121',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '3px 0',
  }),

  menu: (base, state) => ({
    ...base,
    zIndex: '2',
    minWidth: '150px',
  }),

  multiValue: (provided, state) => {
    const display = 'inline-flex';
    return { ...provided, display };
  },

  multiValueLabel: (base, state) => ({
    ...base,
    maxWidth: '168px',
  }),

  placeholder: () => ({
    color: 'rgba(0,0,0,0.4)',
    marginLeft: '2px',
    marginRight: '2px',
    position: 'absolute',
    top: '5px',
    boxSizing: 'border-box',
    left: '10px',
  }),

  Input: () => ({
    margin: '0',
    padding: '0',
  }),
};
