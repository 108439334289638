//@flow
import type { Node } from 'React';
import React from 'react';

import { ParagraphSmall } from 'components/Editor/Common/Typography/Typo';
import { Dialog } from 'components/Editor/Common';
import { Grid } from '../Grid/v2/Grid';
import { TextButton } from 'components/Editor/Common/Buttons/Button';

type Props = {
  title: string,
  submitLabel?: string,
  handleCancel: Function,
  handleSubmit: Function,
};

export const ConfirmationDialog = ({
  title,
  submitLabel = 'ELIMINAR',
  handleCancel,
  handleSubmit,
}: Props): Node => {
  return (
    <Dialog
      showTitle={false}
      backgroundColor="yellow"
      spacing="medium"
      size="small"
      position="center"
      dividers={false}
    >
      <Grid
        container
        alignItems="center"
        gutter="custom"
        spacing={1}
        justify="space-between"
      >
        <Grid item md={!!handleCancel ? 8 : 10}>
          <ParagraphSmall>{title}</ParagraphSmall>
        </Grid>

        <Grid item md={!!handleCancel ? 4 : 2}>
          <Grid container gutter="custom" spacing={1} justify="flex-end">
            {!!handleCancel && (
              <Grid item>
                <TextButton
                  label="CANCELAR"
                  style={{ opacity: '0.7' }}
                  onClick={handleCancel}
                />
              </Grid>
            )}

            <Grid item>
              <TextButton label={submitLabel} onClick={handleSubmit} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
