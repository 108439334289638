import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MuiTextField from '@mui/material/TextField';
import { getTimeZone } from './lib/momentHelpers';

type Props = {
  label: string,
  value: string | null,
  onDateChange: Function,
  errorText: string,
  adminUrl: string,
  placeholder?: string,
};

const theme = createTheme({
  palette: {
    error: {
      main: '#C52100 !important',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: '10px 0 6px 14px !important',
          textTransform: 'lowercase',

          '&::placeholder': {
            color: '#757575 !important',
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
  },
});

export const DateTimePickerField = ({
  value = null,
  label = '',
  onDateChange,
  errorText,
  adminUrl,
  placeholder = 'dd/mm/aaaa hh:mm',
}: Props) => {
  const [updatedValue, setUpdatedValue] = useState(null);

  useEffect(() => {
    const parsedDate = value ? moment(value) : null;
    setUpdatedValue(parsedDate);
  }, [value]);

  useEffect(() => {
    const timezone = getTimeZone(adminUrl);
    moment.tz.setDefault(timezone);
  }, [adminUrl]);

  const handleChange = (newValue) => {
    setUpdatedValue(newValue);
    const formattedDate = newValue
      ? newValue.format('YYYY-MM-DD HH:mm:ss')
      : newValue;
    onDateChange(formattedDate);
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
        localeText={{
          okButtonLabel: 'GUARDAR',
          fieldDayPlaceholder: () => 'dd',
          fieldMonthPlaceholder: () => 'MM',
          fieldYearPlaceholder: () => 'aaaa',
        }}
      >
        <DateTimePicker
          label={label}
          value={updatedValue}
          onChange={handleChange}
          slotProps={{
            textField: {
              helperText: errorText,
              error: !!errorText,
              placeholder: placeholder,
            },
          }}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          disablePast
          defaultValue={null}
          skipDisabled
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
