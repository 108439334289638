// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from 'components/Editor/Common/TextField';

const styles = (theme) => ({
  root: {
    top: '0',
  },
});

type Props = {
  floatingLabelText: any,
  hintText?: string,
  minRows?: number,
  maxRows?: number,
  value: string,
  onChange: () => void,
};

const DefaultTextArea = ({
  hintText = '...',
  minRows = 4,
  maxRows = 4,
  ...restProps
}: Props) => (
  // $FlowFixMe This comment suppresses a Flow error
  <TextField
    hintText={hintText}
    multiline
    minRows={minRows}
    maxRows={maxRows}
    {...restProps}
  />
);

export const TextArea: any = withStyles(styles)(DefaultTextArea);
