// @flow
import isoFetch from 'isomorphic-fetch';
import { Image } from 'lib/flowTypes';
import config from 'config/configs';
import memoize from 'memoize-one';

const s3ApiDomain = config.s3ApiDomain || '';
let s3ApiKey = '';

export const initS3ApiKey = memoize((key: string) => {
  s3ApiKey = key;
});

export const postImage = async (
  site: string,
  params: FormData,
  size: number | null = null
): Promise<Image> => {
  const res = await isoFetch(`${s3ApiDomain}/v2/${site}/images`, {
    method: 'post',
    headers: {
      Authorization: `${s3ApiKey}`,
    },
    body: params,
  });
  if (res.ok) {
    const data = await res.json();
    return { ...data, size };
  } else {
    return Promise.reject(res);
  }
};

export const postZip = async (
  site: string,
  file: FormData,
  size: number | null = null
): Promise<Image> => {
  const res = await isoFetch(`${s3ApiDomain}/v2/${site}/files`, {
    method: 'post',
    headers: {
      Authorization: `${s3ApiKey}`,
    },
    body: file,
  });
  if (res.ok) {
    const data = await res.json();
    return { ...data, size };
  } else {
    return Promise.reject(res);
  }
};

export const putImage = async (
  site: string,
  params: { url: string }
): Promise<any> => {
  const res = await isoFetch(`${s3ApiDomain}/v2/${site}/images`, {
    method: 'PUT',
    headers: {
      Authorization: `${s3ApiKey}`,
    },
    body: JSON.stringify(params),
  });

  if (res.ok) {
    return res.json();
  } else {
    return Promise.reject(res);
  }
};
