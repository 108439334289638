// @flow
import type { Node } from 'React';
import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './Loaders.module.css';

type Props = {
  show?: boolean,
  size?: number,
  children?: any,
};

type BackdropProps = {
  children?: any,
  preventScroll?: boolean,
};

export const CircularLoader = ({
  show,
  size = 24,
  ...rest
}: Props): null | Node => {
  return show ? (
    // $FlowFixMe: suppressing this error until we can refactor
    <CircularProgress size={size} className={styles.loader} {...rest} />
  ) : null;
};

export const Backdrop = ({
  children,
  preventScroll = false,
}: BackdropProps) => {
  useEffect(() => {
    if (!preventScroll || !document.body) {
      return;
    }

    const handleScroll = (e) => {
      e.preventDefault();
    };

    document.body.style.overflow = 'hidden';
    window.addEventListener('scroll', handleScroll);

    return () => {
      if (!document.body) {
        return;
      }
      document.body.style.overflow = 'auto';
      window.removeEventListener('scroll', handleScroll);
    };
  }, [preventScroll]);

  return children === undefined ? (
    <div className={styles.center}>
      <div className={styles.backdrop}>
        <CircularLoader show className={'progress-bar'} size={40} />
      </div>
    </div>
  ) : (
    <div className={styles.backdrop}>{children}</div>
  );
};
