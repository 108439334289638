/*@flow*/
import React from 'react';

import { ImageCropper } from 'components/Editor/Publish/ImageCropper/ImageCropper';

import { initCrop } from 'lib/constants';
import { getImageUrl } from 'lib/helpers';
import {
  uploadImageFromNativeFileUploader,
  preloadImage,
} from 'components/Editor/ImageUploader/lib/imageUploadService';

type Props = {
  imageSrc: string,
  crop: Object,
  siteName: string,
  isMobile: boolean,
  errorText?: string,
  setImage: (imageSrc: string) => void,
  setCrop: (crop: Object) => void,
  isImageUploading: boolean,
  setIsImageUploading: (isImageUploading: boolean) => void,
  isImageValidating: boolean,
  setIsImageValidating: (value: boolean) => void,
};

const CrossPostImageCropper = (props: Props) => {
  const {
    errorText,
    siteName,
    imageSrc,
    crop,
    setImage,
    setCrop,
    isImageUploading,
    setIsImageUploading,
    isImageValidating,
    setIsImageValidating,
  } = props;

  const handleImageUploadClick = async () => {
    setIsImageUploading(true);
    const images = await uploadImageFromNativeFileUploader(siteName);
    const imageUrl = getImageUrl(images[0]);
    await preloadImage(imageUrl);
    setImage(imageUrl);
    setCrop({});
    setIsImageUploading(false);
  };

  if (!crop) {
    return null;
  }
  return (
    <ImageCropper
      crop={crop.square ? crop : initCrop}
      imageSrc={imageSrc}
      errorText={errorText}
      siteName={siteName}
      onCropComplete={(image) => setImage(image)}
      handleCropChange={(crop) => setCrop(crop)}
      onImageUploadClick={handleImageUploadClick}
      afterSuccessfulDrop={(images) => {
        const image = `${images[0].src}/original.${images[0].extension}`;
        setImage(image);
        setCrop({});
      }}
      isImageUploading={isImageUploading}
      isImageValidating={isImageValidating}
      setIsImageValidating={setIsImageValidating}
    />
  );
};

export default CrossPostImageCropper;
