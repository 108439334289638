export const isMobile = () => {
  if (window.innerWidth <= 960) {
    return true;
  }
  return false;
};

export const isTablet = () => {
  if (window.innerWidth <= 1023) {
    return true;
  }
  return false;
};
