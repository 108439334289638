// @flow
import type { Node } from 'React';
import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';

import CropWidget from './CropWidget';
import { PrimaryImageThumbnail } from './PrimaryImageThumbnail';
import { Grid } from 'components/Editor/Common/Grid/Grid';
import { Checkbox } from 'components/Editor/Common/Checkbox/Checkbox';
import { Label } from 'components/Editor/Common/Label';
import Error from 'components/Editor/Common/Error';

import { getInitialCrop } from 'lib/helpers';
import { putImage } from 'lib/s3ImageUploadService';

import style from './ImageCropper.module.css';
import 'components/Editor/Common/ReactCrop.css';
import { Backdrop, CircularLoader } from 'components/Editor/Common';

const allImagesValidated = (crop) => {
  return (
    crop['golden']['validate'] &&
    crop['panoramic']['validate'] &&
    crop['square']['validate']
  );
};

type Props = {
  imageSrc: string,
  siteName: string,
  crop: Object,
  handleCropChange: (crop: Object) => void,
  onCropComplete: (image: string) => void,
  errorText?: string,
  postType?: string,
  isMobile?: boolean,
  onImageUploadClick?: Function,
  afterSuccessfulDrop: Function,
  isImageUploading?: boolean,
  isImageValidating: boolean,
  setIsImageValidating: (value: boolean) => void,
};

export const ImageCropper = (props: Props): null | Node => {
  const {
    siteName,
    crop,
    errorText,
    isMobile,
    handleCropChange,
    onCropComplete,
    postType = '',
    onImageUploadClick,
    afterSuccessfulDrop,
    imageSrc,
    isImageUploading = false,
    isImageValidating,
    setIsImageValidating,
  } = props;

  const [imageAspectRatio, setImageAspectRatio] = useState(1.5);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showWatermark, setShowWatermark] = useState(true);
  const [prevCrop, setPrevCrop] = useState(crop);
  if (JSON.stringify(crop) !== JSON.stringify(prevCrop)) {
    setPrevCrop(crop);
    setIsImageValidating(allImagesValidated(crop));
  }

  useEffect(() => {
    if (
      isLoaded &&
      (crop.square === undefined || crop.square.aspect === undefined)
    ) {
      const initialCrop = getInitialCrop(imageAspectRatio);
      handleCropChange(initialCrop);
    }
  }, [imageAspectRatio, crop, isLoaded, handleCropChange]);

  useEffect(() => {
    async function validateImage() {
      const data = {
        url: imageSrc,
        meta_data: JSON.stringify(crop),
      };
      try {
        const imageWithNewPath = await putImage(siteName, data);
        onCropComplete(
          `${imageWithNewPath['src']}/original.${imageWithNewPath['extension']}`
        );
      } catch {
        onCropComplete('');
      } finally {
        setIsImageValidating(false);
      }
    }
    if (isImageValidating) {
      validateImage();
    }
  }, [
    isImageValidating,
    onCropComplete,
    imageSrc,
    siteName,
    crop,
    setIsImageValidating,
  ]);

  const onCropChange = (shape, cropData) => {
    const updatedCrop = {
      ...crop,
      [shape]: {
        ...crop[shape],
        ...cropData,
      },
    };
    handleCropChange(updatedCrop);
  };

  const onCropValidate = (shape, validate) => {
    const updatedCrop = {
      ...crop,
      [shape]: {
        ...crop[shape],
        validate: validate,
      },
    };
    handleCropChange(updatedCrop);
  };

  const onCropValidateAll = () => {
    const updatedCrop = {
      ...crop,
      golden: {
        ...crop['golden'],
        validate: true,
      },
      square: {
        ...crop['square'],
        validate: true,
      },
      panoramic: {
        ...crop['panoramic'],
        validate: true,
      },
    };
    handleCropChange(updatedCrop);
  };

  const onLoad = (height, width) => {
    setIsLoaded(true);
    setImageAspectRatio(width / height);
  };

  if (!crop) {
    return null;
  }
  return (
    <>
      <Grid container>
        <Grid item sm={12} xs={12}>
          <Label
            label="Imagen para portada y listados"
            hint="Por favor, ajusta los recuadros para que el recorte se vea correctamente"
          />
        </Grid>
      </Grid>

      {errorText && <Error message={errorText} />}

      <Grid container className={style.relativeContainer}>
        {isImageUploading ? (
          <Backdrop>
            <CircularLoader show size={30} />
          </Backdrop>
        ) : (
          ''
        )}

        <Grid item sm={3} xs={12}>
          <div className={style.imageCropperContainer}>
            <PrimaryImageThumbnail
              siteName={siteName}
              onLoad={onLoad}
              crop={crop}
              imageSrc={imageSrc}
              postType={postType}
              isMobile={isMobile}
              isImageValidating={isImageValidating}
              onCropValidateAll={onCropValidateAll}
              onImageUploadClick={onImageUploadClick}
              afterSuccessfulDrop={afterSuccessfulDrop}
            />
          </div>
        </Grid>
        <Grid item sm={3} xs={12}>
          <div className={style.imageCropperContainer}>
            <CropWidget
              imageSrc={imageSrc}
              crop={crop.square}
              shape="square"
              onCropChange={onCropChange}
              onCropValidate={onCropValidate}
              isImageValidating={isImageValidating}
            />
          </div>
        </Grid>
        <Grid item sm={3} xs={12}>
          <div className={style.imageCropperContainer}>
            <CropWidget
              imageSrc={imageSrc}
              crop={crop.golden}
              shape="golden"
              onCropChange={onCropChange}
              onCropValidate={onCropValidate}
              isImageValidating={isImageValidating}
            />
          </div>
        </Grid>
        <Grid item sm={3} xs={12}>
          <div className={style.imageCropperContainer}>
            <CropWidget
              imageSrc={imageSrc}
              showWatermark={showWatermark}
              type="cf"
              crop={crop.panoramic}
              shape="panoramic"
              onCropChange={onCropChange}
              onCropValidate={onCropValidate}
              isImageValidating={isImageValidating}
            />
          </div>
          <div className={style.checkboxMargin}>
            <Checkbox
              checked={showWatermark}
              label="Ver guía de Top Story"
              onChange={() => setShowWatermark(!showWatermark)}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo<Props>(ImageCropper);
