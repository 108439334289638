// @flow
import * as React from 'react';
import { SnackbarConfirmation } from './SnackbarConfirmation';

type Props = {
  isUnsavedContent: boolean,
  onConfirmation: Function,
  message: string,
  onClose: Function,
  confirmationMsg?: string,
  cancelMsg?: string,
};

export const UnsavedConfirmationSnackbar = ({
  isUnsavedContent,
  onConfirmation,
  message,
  onClose,
  confirmationMsg = 'Seguir en el formulario',
  cancelMsg = 'Descartar cambios',
}: Props): React.Node => {
  if (!isUnsavedContent) {
    return null;
  }

  return (
    <SnackbarConfirmation
      message={message}
      messageType="warning"
      confirmationButtonLabel={confirmationMsg}
      horizontal="center"
      handleClose={onClose}
      handleConfirmation={onConfirmation}
      layout="column"
      size="small"
      closeButtonLabel={cancelMsg}
    />
  );
};
