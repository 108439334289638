import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MouseHoverLink } from 'components/Editor/Common/MouseHoverLink';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  entityKey: PropTypes.string,
  getEditorState: PropTypes.func.isRequired,
  target: PropTypes.string
};

class Link extends Component {
  state = {
    showPopOver: false
  };
  showPopOver = () => {
    clearTimeout(this.hideTimerId);
    this.showTimerId = setTimeout(() => {
      this.setState({
        showPopOver: true
      });
    }, 1000);
  };

  hidePopOver = () => {
    clearTimeout(this.showTimerId);
    this.hideTimerId = setTimeout(() => {
      this.setState({
        showPopOver: false
      });
    }, 1000);
  };

  getLinkData = () => {
    const { entityKey, getEditorState } = this.props;
    const entity = getEditorState()
      .getCurrentContent()
      .getEntity(entityKey);
    const entityData = entity ? entity.get('data') : undefined;
    let url;

    if (entityData && entityData.url) {
      const [pageUrl, fragment] = entityData.url.split('#');
      if (fragment && (!pageUrl || pageUrl === window.location.href)) {
        const attributes = {
          url: '#' + fragment,
          id: entityData.rel,
          anchorSources: [[fragment]]
        };

        return attributes;
      }

      url = entityData.url;
    }

    if (entityData && entityData.href) {
      url = entityData.href;
    }

    return { url };
  };

  render() {
    const { children, className, target } = this.props;
    const { url, id, anchorSources } = this.getLinkData();

    return (
      <span
        onMouseEnter={this.showPopOver}
        onMouseLeave={this.hidePopOver}
        onClick={this.hidePopOver}
        style={{ position: 'relative' }}
      >
        <a
          className={className}
          href={url}
          id={id}
          target={target}
          rel="noopener noreferrer"
        >
          {children}
        </a>
        {this.state.showPopOver && (
          <MouseHoverLink
            url={url}
            anchorSources={anchorSources}
            isAnchorOrigin
          />
        )}
      </span>
    );
  }
}

Link.propTypes = propTypes;
export default Link;
