import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from './Link/Link';
import { Snackbar } from './Snackbar';

const REINDEX_MSG =
  'Hay un Reindex programado. Para publicar cambios en este artículo antes de la fecha programada,';

type Props = {
  status: string,
  isReindexScheduled: boolean,
  isContentUpdated: boolean,
  firebaseId: string,
};

export const ReindexScheduleWarning = ({
  status,
  isReindexScheduled,
  firebaseId,
}: Props) => {
  const showReindexWarning = 'publish' === status && isReindexScheduled;
  const navigate = useNavigate();

  if (!showReindexWarning) {
    return null;
  }

  const switchToDifundir = (e) => {
    e.preventDefault();
    navigate(`/difundir/${firebaseId}`);
  };

  const message = (
    <>
      <div>
        {REINDEX_MSG}{' '}
        <Link color="inherit" href="" onClick={switchToDifundir}>
          elimina el Reindex en Difusión.
        </Link>
      </div>
    </>
  );

  return (
    <Snackbar
      message={message}
      messageType="warning"
      iconType="info"
      size="large"
    />
  );
};
