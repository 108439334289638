import * as React from 'react';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createMarkdownPlugin from 'draft-js-markdown-shortcuts-plugin';
import createSingleLinePlugin from 'draft-js-single-line-plugin';
import createLinkPlugin from '../draft-js-anchor-plugin';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';

import {
  StrikeThrough,
  BoldButton,
  ItalicButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  HeadlineFourButton,
  BlockquoteButton,
  UnorderedListButton,
  OrderedListButton,
} from './TextToolbarButtons';
import extractSummaryPlugin from '../summary-plugin';
import { HighlightButton } from './HighlightButton';
import { TOOLBAR_OPTIONS } from './helpers';
import './toolbarStyles.css';

type ToolbarProps = {
  includeOptions?: string[],
  excludeOptions?: string[],
  addSummary?: Function,
  addLink?: Function,
  highlightOptions?: Object,
};

export const singleLinePlugin = createSingleLinePlugin({
  stripEntities: false,
});

const getToolbarButton = (option: string) => {
  switch (option) {
    case 'bold':
      return BoldButton;
    case 'italic':
      return ItalicButton;
    case 'line-through':
      return StrikeThrough;
    case 'header-two':
      return HeadlineTwoButton;
    case 'header-three':
      return HeadlineThreeButton;
    case 'header-four':
      return HeadlineFourButton;
    case 'blockquote':
      return BlockquoteButton;
    case 'unordered-list':
      return UnorderedListButton;
    case 'ordered-list':
      return OrderedListButton;
    default:
      return '';
  }
};

const getToolbarOptions = (
  includeOptions: string[] = [],
  excludeOptions: string[] = []
) => {
  if (excludeOptions.length) {
    const options = [...TOOLBAR_OPTIONS];
    return options.filter((option) => !excludeOptions.includes(option));
  }

  return [...includeOptions];
};

export const draftJSCustomToolbar = ({
  includeOptions = [],
  excludeOptions = [],
  addSummary = null,
  addLink = null,
  updateAnchor = null,
  highlightOptions = null,
}: ToolbarProps) => {
  const inlineToolbarPlugin = createInlineToolbarPlugin();
  const mdPlugin = createMarkdownPlugin();
  const { InlineToolbar } = inlineToolbarPlugin;
  const plugins = [mdPlugin, inlineToolbarPlugin];
  const options = getToolbarOptions(includeOptions, excludeOptions);

  let linkPlugin = '',
    summaryPlugin = '';

  if (options.includes('link') || options.includes('anchor')) {
    linkPlugin = createLinkPlugin(
      {
        placeholder: 'https://…',
      },
      addLink,
      updateAnchor
    );

    plugins.unshift(linkPlugin);
  }

  if (options.includes('summary') && !!addSummary) {
    summaryPlugin = extractSummaryPlugin(addSummary);
    plugins.push(summaryPlugin);
  }

  const Toolbar = (
    <InlineToolbar>
      {(externalProps) => (
        <React.Fragment>
          {options.map((option) => {
            let Button = '';
            if ('link' === option) {
              Button = linkPlugin.LinkButton;
            } else if ('anchor' === option) {
              Button = linkPlugin.AnchorButton;
            } else if ('summary' === option && !!addSummary) {
              Button = summaryPlugin.ExtractSummaryButton;
            } else if ('highlight' === option && !!highlightOptions) {
              const { isHighlighted, disabled, toggleHighlight } =
                highlightOptions;
              return (
                <HighlightButton
                  key="highlight"
                  isHighlighted={isHighlighted}
                  toggleHighlight={toggleHighlight}
                  disabled={disabled}
                  {...externalProps}
                />
              );
            } else {
              Button = getToolbarButton(option);
            }
            return Button ? <Button key={option} {...externalProps} /> : null;
          })}
        </React.Fragment>
      )}
    </InlineToolbar>
  );

  return {
    plugins,
    Toolbar,
    shortcuts: options,
  };
};
