import React, { useState } from 'react';
import clsx from 'clsx';
import { HighlightTableIcon } from 'components/Editor/Common/CustomSVGIcons';
import styles from './TextToolbarButtons.module.css';

type Props = {
  isHighlighted: boolean,
  disabled: Boolean,
  toggleHighlight: Function,
  theme: { button: string, buttonWrapper: string, active: string },
};

export const HighlightButton = (props: Props) => {
  const { isHighlighted, disabled, toggleHighlight, theme } = props;

  const [highlighted, setHighlighted] = useState(isHighlighted);

  const onMouseDown = (event) => {
    event.preventDefault();
  };

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlighted(!highlighted);
    toggleHighlight(highlighted);
  };

  const className = highlighted
    ? clsx(theme.button, theme.active)
    : theme.button;

  return (
    <div className={theme.buttonWrapper} onMouseDown={onMouseDown}>
      <button
        className={className}
        onClick={onClick}
        type="button"
        disabled={disabled}
      >
        <HighlightTableIcon color={disabled ? 'disabled' : 'inherit'} />
        <span className={styles.toolTiptext}>Resaltar texto</span>
      </button>
    </div>
  );
};
