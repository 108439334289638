import {
  SmallLeftToolbar,
  SmallRightToolbar,
  SmallCenterToolbar,
  NormalCenterToolbar,
  LargeCenterToolbar,
  EdgeToolbar
} from 'components/Editor/Escribir/lib/Toolbar';
import { POST_TYPE_LANDING } from 'lib/constants';

export const layoutMapping = {
  smallLeft: {
    layout: 'small',
    align: 'left',
    toolbar: SmallLeftToolbar
  },
  smallRight: {
    layout: 'small',
    align: 'right',
    toolbar: SmallRightToolbar
  },
  smallCenter: {
    layout: 'small',
    align: 'center',
    toolbar: SmallCenterToolbar
  },
  normal: {
    layout: 'normal',
    align: '',
    toolbar: NormalCenterToolbar
  },
  large: {
    layout: 'large',
    align: '',
    toolbar: LargeCenterToolbar
  },
  edge: {
    layout: 'edge',
    align: '',
    toolbar: EdgeToolbar
  }
};

export const getLayoutOptions = (
  type: string,
  postType: string,
  subType: string
) => {
  let layout = [];
  switch (type) {
    case 'title':
      layout = ['smallCenter', 'normal', 'large', 'edge'];
      break;
    case 'video':
      if (subType === 'facebook') {
        layout = ['normal'];
      } else if (subType === 'vimeo') {
        layout = ['normal', 'large'];
      } else {
        layout = ['normal', 'large'];

        if (postType === POST_TYPE_LANDING) {
          layout.push('edge');
        }
      }
      break;
    case 'richContent':
    case 'image':
      layout = ['smallLeft', 'smallCenter', 'smallRight', 'normal', 'large'];
      if (postType === POST_TYPE_LANDING) {
        layout.push('edge');
      }
      break;
    case 'summary':
    case 'giphy':
      layout = ['smallLeft', 'smallCenter', 'smallRight', 'normal'];
      break;
    case 'table':
      layout = ['normal', 'large'];
      break;
    case 'infogram':
    case 'datawrapper':
      layout = ['normal', 'large'];
      if (postType === POST_TYPE_LANDING) {
        layout.push('edge');
      }
      break;
    default:
      break;
  }
  return layout;
};
