// @flow
import type { Node } from 'React';
import React, { useState } from 'react';
import { CheckIcon } from 'components/Editor/Common/Icons';

import {
  Grid,
  CircularLoader,
  FullWidthDivider,
  Dialog,
  BasicLabel,
} from 'components/Editor/Common';
import { Caption } from 'components/Editor/Common/Typography/Typo';
import {
  AddRoundIcon,
  RemoveRoundIcon,
} from 'components/Editor/Common/CustomSVGIcons';
import { FabButton } from 'components/Editor/Common/Buttons/Button';
import { Image } from 'lib/flowTypes';
import { Uploader } from '.';
import styles from './ImagePanel.module.css';
import imageStyles from './MultipleImagePanel.module.css';
import { getTrimmedText } from 'lib/helpers';

type Props = {
  open: boolean,
  imageUrls: Array<Image>,
  id: string,
  siteName: string,
  isImageUploading: boolean,
  closeModal: () => void,
  onSubmit: (Array<Image>) => {},
  changeSelectedImage: (image: Image) => void,
  startUpload: () => void,
  endUpload: () => void,
  mode: string,
  isMobile: boolean,
  type: 'post' | 'product',
  images: Array<Image>,
};

const getImageWidget = (
  image,
  index,
  handleClick,
  Icon,
  isDisabled = false
) => (
  <li key={index} className={styles.imagePanel}>
    <span
      className={isDisabled ? imageStyles.disable : imageStyles.container}
      onClick={() => handleClick(image)}
    >
      <div className={imageStyles.image}>
        <img
          src={`${image.src}/original.${image.extension}`}
          alt=""
          className={imageStyles.size}
        />
      </div>
      <div className={imageStyles.icons}>
        {!isDisabled && <Icon fontSize="small" />}
        <Caption>{getTrimmedText(image.name, 30)}</Caption>
      </div>
    </span>
  </li>
);

const MultipleImagePanel = ({
  open,
  imageUrls,
  id,
  siteName,
  closeModal,
  changeSelectedImage,
  isImageUploading,
  startUpload,
  endUpload,
  mode,
  isMobile,
  onSubmit,
  type,
  images = [],
}: Props): Node => {
  const [selectedImages, setSelectedImages] = useState(images);

  const handleImageDeselect = (image) => {
    const index = selectedImages.findIndex((img) => img.src === image.src);
    setSelectedImages([
      ...selectedImages.slice(0, index),
      ...selectedImages.slice(index + 1),
    ]);
  };

  const handleImageSelect = (image) => {
    setSelectedImages([...selectedImages, image]);
  };

  const handleSubmit = () => {
    onSubmit(selectedImages);
    closeModal();
  };

  const getSelectedImageList = () =>
    [...selectedImages]
      .reverse()
      .map((image, index) =>
        getImageWidget(image, index, handleImageDeselect, RemoveRoundIcon)
      );

  const getGalleryList = () =>
    [...imageUrls].reverse().map((image, index) => {
      const isDisabled =
        selectedImages.filter((img) => image.src === img.src).length > 0;
      return getImageWidget(
        image,
        index,
        handleImageSelect,
        AddRoundIcon,
        isDisabled
      );
    });

  const getSaveButton = () => {
    if (selectedImages.length > 0 || images.length > 0) {
      return (
        <div className={imageStyles.button}>
          <FabButton
            Icon={CheckIcon}
            color="primary"
            handleClick={handleSubmit}
          />
        </div>
      );
    }
  };

  return (
    <Dialog
      open={open}
      title="Selecciona imágenes"
      titleHint="Puedes seleccionar más de una"
      onClose={closeModal}
      size="large"
      spacing="zero"
    >
      <div className={imageStyles.height}>
        <ul className={imageStyles.imagePanelContainer}>
          {getSelectedImageList()}
        </ul>
        {0 === selectedImages.length && (
          <div className={imageStyles.placeholder}>
            <Caption faded>Aún no has seleccionado ninguna imagen</Caption>
          </div>
        )}
        {getSaveButton()}
      </div>

      <div className={imageStyles.imgUploader}>
        <Grid container gutter="custom" spacing={2}>
          <Grid item xs={12}>
            <FullWidthDivider />
          </Grid>
          <Grid item xs={12}>
            <BasicLabel
              label="Imágenes disponibles"
              hint="Haz click en las que quieras seleccionar"
            />
          </Grid>
        </Grid>
      </div>

      <div className={styles.height}>
        {imageUrls.length > 0 && (
          <ul className={styles.imagePanelContainer}>
            {isImageUploading && (
              <li className={styles.imagePanel}>
                <CircularLoader
                  show={isImageUploading}
                  className={'progress-bar'}
                  size={40}
                />
              </li>
            )}
            {getGalleryList()}
          </ul>
        )}
      </div>
      <div className={styles.imgUploader}>
        <Grid container gutter="custom" spacing={2}>
          <Grid item xs={12}>
            <FullWidthDivider />
          </Grid>
          <Grid item xs={12}>
            <BasicLabel label="Subir imágenes" hint="Máximo 8Mb cada una" />
          </Grid>
          <Grid item xs={12}>
            <FullWidthDivider />
          </Grid>
        </Grid>
        <Uploader
          id={id}
          isMobile={isMobile}
          site={siteName}
          isUploading={isImageUploading}
          startUpload={startUpload}
          endUpload={endUpload}
          type={type}
        />
      </div>
    </Dialog>
  );
};

export default MultipleImagePanel;
