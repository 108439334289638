// @flow
import * as React from 'react';
import styles from './Main.module.css';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

type Props = {
  children: any,
  baseTheme?: 'default' | 'white' | 'light',
  spacing?: 'zero',
};

export const Main = ({
  children,
  baseTheme = 'default',
  spacing = '',
}: Props): React.Node => {
  const className = [styles.outerWrapper];

  if (baseTheme === 'white') {
    className.push(styles.outerWrapperWhite);
  } else if (baseTheme === 'light') {
    className.push(styles.outerWrapperLight);
  }

  if (spacing === 'zero') {
    className.push(styles.zero);
  }

  return (
    <MuiThemeProvider theme={theme}>
      <div className={className.join(' ')}>{children}</div>
    </MuiThemeProvider>
  );
};
