// @flow
import * as React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

type Props = {
  color?: string,
};

export const SmallLeftIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path d="m5,20l18,0l0,-1.77778l-18,0l0,1.77778l0,0zm7,-12l0,1.77778l16,0l0,-1.77778l-16,0l0,0zm0,3l0,1.77778l16,0l0,-1.77778l-16,0l0,0zm0,3l0,1.77778l16,0l0,-1.77778l-16,0l0,0zm-12,2.44556l10,0l0,-8.89l-10,0l0,8.89l0,0zm5,-12.44556l0,1.77778l18,0l0,-1.77778l-18,0l0,0z" />
  </SvgIcon>
);

export const SmallCenterIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path d="m3,20l18,0l0,-1.77778l-18,0l0,1.77778l0,0zm4,-3.55444l10,0l0,-8.89l-10,0l0,8.89l0,0zm-4,-12.44556l0,1.77778l18,0l0,-1.77778l-18,0l0,0z" />
  </SvgIcon>
);

export const SmallRightIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path
      d="m3,20l18,0l0,-1.77778l-18,0l0,1.77778l0,0zm7,-12l0,1.77778l16,0l0,-1.77778l-16,0l0,0zm0,3l0,1.77778l16,0l0,-1.77778l-16,0l0,0zm0,3l0,1.77778l16,0l0,-1.77778l-16,0l0,0zm-12,2.44556l10,0l0,-8.89l-10,0l0,8.89l0,0zm5,-12.44556l0,1.77778l18,0l0,-1.77778l-18,0l0,0z"
      transform="rotate(-180 12,12)"
    />
  </SvgIcon>
);

export const NormalIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path d="m3,20l18,0l0,-1.77778l-18,0l0,1.77778l0,0zm0,-3.55444l18,0l0,-8.89l-18,0l0,8.89l0,0zm0,-12.44556l0,1.77778l18,0l0,-1.77778l-18,0l0,0z" />
  </SvgIcon>
);

export const BigIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path d="m2.90978,20.017716l18.18045,0l0,-1.78171l-18.18046,0l0,1.78171l0,0l0.00001,0zm-3.03008,-3.56231l24.2406,0l0,-8.90968l-24.2406,0l0,8.90968l0,0zm3.03008,-12.47312l0,1.78172l18.18045,0l0,-1.78172l-18.18046,0l0,0l0.00001,0z" />
  </SvgIcon>
);

export const YoutubeIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path
      id="youtube-a"
      d="m21.4563,7.23306c-0.23266,-0.83365 -0.88409,-1.48507 -1.71773,-1.71773c-1.53839,-0.41216 -7.72979,-0.41216 -7.72979,-0.41216c0,0 -6.17577,-0.00823 -7.72978,0.41216c-0.83365,0.23266 -1.48507,0.88409 -1.71773,1.71773c-0.29162,1.575 -0.43538,3.17382 -0.42943,4.77559c-0.00483,1.59572 0.13893,3.18842 0.42943,4.75748c0.23266,0.83365 0.88409,1.48507 1.71773,1.71773c1.53674,0.41298 7.72978,0.41298 7.72978,0.41298c0,0 6.17495,0 7.72979,-0.41298c0.83365,-0.23266 1.48507,-0.88409 1.71773,-1.71773c0.28443,-1.56963 0.42213,-3.16233 0.41133,-4.75748c0.01193,-1.60121 -0.12578,-3.20002 -0.41133,-4.77559zm-11.42357,7.72978l0,-5.91745l5.15319,2.96325l-5.15319,2.95419l0,0.00001z"
      stroke="null"
    />
  </SvgIcon>
);

export const VimeoIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path
      id="vimeo-a"
      stroke="null"
      d="m21.02873,6.19037c-0.20441,-0.95664 -0.71543,-1.58621 -1.6598,-1.89283c-0.94438,-0.30661 -2.65324,-0.18397 -3.84699,0.68682c-1.22236,0.89122 -1.94598,2.44065 -2.19944,3.4259c0.60096,-0.25755 0.98117,-0.31479 1.5944,-0.28208c0.61323,0.0327 1.00161,0.49058 1.01795,1.03431c0.01227,0.40065 -0.00817,0.7645 -0.14717,1.13243c-0.44152,1.17331 -1.13243,2.30983 -1.94598,3.30325c-0.11856,0.14718 -0.26165,0.28209 -0.40882,0.40473c-0.417,0.33932 -0.76858,0.24938 -1.0384,-0.21258c-0.22076,-0.3802 -0.36794,-0.77267 -0.49876,-1.18966c-0.50693,-1.62301 -0.68682,-3.30735 -0.97298,-4.97124c-0.13491,-0.7972 -0.28618,-1.6271 -0.73587,-2.32618c-0.47423,-0.7277 -1.16923,-1.00569 -2.0441,-0.8994c-0.60096,0.07359 -1.50854,0.71543 -1.95416,1.07928c0,0 -2.28938,1.91736 -3.34413,2.91897l0.8667,1.10381c0,0 0.73179,-0.51102 1.12425,-0.74814c0.23303,-0.139 0.50693,-0.16761 0.70317,0.00818c0.18397,0.15944 0.39246,0.36794 0.50285,0.57643c0.23303,0.43743 0.45788,0.89531 0.60096,1.36546c0.53964,1.81107 1.04249,3.62623 1.54534,5.44956c0.25755,0.9321 0.56826,1.80698 1.14469,2.60008c0.78902,1.08746 1.61892,1.33684 2.89853,0.87896c1.0384,-0.37202 1.9051,-1.07111 2.6982,-1.79472c1.3532,-1.23463 2.41612,-2.67367 3.4954,-4.13725c0.83399,-1.13243 1.5249,-2.27712 2.10133,-3.55673c0.56826,-1.26733 0.79311,-2.59599 0.50285,-3.95736z"
    />
  </SvgIcon>
);

export const DailymotionIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path
      d="m14.52278,11c-1.70449,0 -3.02278,1.38121 -3.02278,3.01135c0,1.69805 1.3181,2.98865 3.15911,2.98865c1.54539,0 2.84089,-1.26791 2.84089,-2.94344c0,-1.74344 -1.2955,-3.05656 -2.97722,-3.05656zm6.47722,8.93574l-3.46518,0l0,-1.28563c-1.06449,0.98561 -2.1515,1.34989 -3.60103,1.34989c-1.47205,0 -2.74032,-0.45002 -3.80481,-1.34989c-1.40412,-1.1784 -2.12898,-2.72125 -2.12898,-4.52098c0,-1.6499 0.67945,-3.12831 1.94772,-4.28541c1.13242,-1.04987 2.51402,-1.58563 4.054,-1.58563c1.47205,0 2.60447,0.47132 3.41977,1.45693l0,-5.01362l3.57833,-0.7014l0,15.93574l0.00018,0zm-21,4.06426l24,0l0,-24l-24,0l0,24z"
      id="dailymotion-a"
    />
  </SvgIcon>
);

export const FacebookIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path
      id="facebook-a"
      d="m9.9053,21.69697l0,-8.67424l-2.9053,0l0,-3.44697l2.9053,0l0,-2.71591c0,-2.95076 1.80303,-4.55682 4.43561,-4.55682c1.26136,0 2.3447,0.0947 2.65909,0.13636l0,3.08334l-1.82576,0c-1.43182,0 -1.70833,0.68182 -1.70833,1.67803l0,2.375l3.23106,0l-0.44318,3.44697l-2.78788,0l0,8.67424"
    />
  </SvgIcon>
);

export const EdgeIcon = (props: Props): React.Node => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      stroke="null"
      id="path-1"
      d="m18.44644,20l0,-1.77778l-12.21432,0l0,1.77778l12.21432,0zm-18.32149,-3.55444l23.75008,0l0,-8.89l-23.75008,0l0,8.89l0,0zm6.10717,-12.44556l0,1.77778l12.10782,0l0.1065,-1.77778l-12.21432,0l0,0z"
    />
  </SvgIcon>
);

export const TwitterIcon = (props: Props): React.Node => (
  <SvgIcon {...props} viewBox=" 0 0 24 24">
    <path
      stroke="null"
      id="svg_1"
      d="m8.28957,21.65236c7.54727,0 11.67531,-7.42774 11.67531,-13.86905c0,-0.21097 0,-0.42099 -0.012,-0.63007a8.34888,9.9176 0 0 0 2.04722,-2.52311a8.19047,9.72943 0 0 1 -2.35682,0.76691a4.11764,4.89133 0 0 0 1.80402,-2.69607a8.22328,9.7684 0 0 1 -2.60562,1.18316a4.10724,4.87897 0 0 0 -6.99286,4.44562a11.64971,13.83864 0 0 1 -8.45688,-5.09279a4.10644,4.87802 0 0 0 1.27041,6.50688a4.07284,4.83811 0 0 1 -1.86242,-0.61011l0,0.06177a4.10484,4.87612 0 0 0 3.29203,4.77824a4.09684,4.86662 0 0 1 -1.85282,0.08363a4.10804,4.87992 0 0 0 3.83364,3.38506a8.23368,9.78075 0 0 1 -5.09605,2.09072a8.35288,9.92235 0 0 1 -0.97681,-0.07032a11.61691,13.79968 0 0 0 6.28966,2.18575"
    />
  </SvgIcon>
);

export const PlayVideoIcon = (props: Props): React.Node => (
  <SvgIcon {...props}>
    <path
      id="play-video-icon"
      d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M10,15.5v-7c0-0.41,0.47-0.65,0.8-0.4l4.67,3.5
		c0.27,0.2,0.27,0.6,0,0.8l-4.67,3.5C10.47,16.15,10,15.91,10,15.5z"
    />
  </SvgIcon>
);

export const FlipboardIcon = (props: Props): React.Node => (
  <SvgIcon {...props} fontSize="inherit">
    <path
      stroke="null"
      id="logo"
      fill="#FFFFFF"
      d="m0,0.07811c8.00654,0 15.961756,0 23.951188,0c0,2.658267 0,5.273934 0,7.949241c-2.634631,0 -5.235045,0 -7.946662,0c0,2.683827 0,5.282453 0,7.974801c-2.711617,0 -5.303478,0 -7.96377,0c0,2.666787 0,5.265414 0,7.923681c-2.711617,0 -5.354801,0 -8.040756,0c0,-7.940721 0,-15.855881 0,-23.847723z"
    />
  </SvgIcon>
);

export const WhatsappIcon = (props: Props): React.Node => (
  <SvgIcon {...props} fontSize="inherit">
    <path
      stroke="null"
      d="m12.1973,1.12498c-5.89659,0 -10.67694,4.74306 -10.67694,10.5946c0,2.00178 0.56006,3.87384 1.53182,5.47091l-1.92721,5.68453l5.91135,-1.87827a10.7018,10.7018 0 0 0 5.16097,1.31821c5.89737,0 10.67772,-4.74384 10.67772,-10.59538s-4.78035,-10.5946 -10.67772,-10.5946zm5.30934,14.61914c-0.2509,0.62298 -1.38734,1.19081 -1.88837,1.21723s-0.51501,0.38839 -3.24542,-0.79776c-2.72963,-1.18615 -4.37176,-4.07037 -4.50148,-4.25602s-1.05721,-1.50697 -1.00749,-2.8376c0.05049,-1.33064 0.77912,-1.95906 1.03934,-2.22006s0.55773,-0.30838 0.7395,-0.31149c0.21517,-0.00388 0.35422,-0.00621 0.51346,-0.00078s0.39772,-0.0334 0.60434,0.51656c0.20663,0.54997 0.70144,1.9008 0.76436,2.03829s0.10254,0.29751 0.00466,0.47462c-0.09788,0.17711 -0.14759,0.28819 -0.28974,0.44122s-0.29984,0.34179 -0.42723,0.45908c-0.14138,0.12972 -0.28974,0.27032 -0.1406,0.54686s0.6626,1.18227 1.4456,1.92954c1.00594,0.96011 1.87361,1.27859 2.14083,1.42307c0.26799,0.14604 0.42723,0.12972 0.59347,-0.04505c0.16546,-0.17478 0.71076,-0.76436 0.90263,-1.02769s0.37208,-0.21206 0.61832,-0.11341c0.24624,0.09943 1.56134,0.80397 1.82933,0.94923s0.44665,0.21983 0.51035,0.3348c0.0637,0.11574 0.0435,0.65716 -0.2074,1.27937l0.00155,0z"
      fill="#b8b8b8"
      id="logo"
    />
  </SvgIcon>
);

export const PlaystoreIcon = (props: Props): React.Node => (
  <SvgIcon {...props} fontSize="inherit">
    <path
      id="Shape"
      d="m1.5,22.35823l0,-20.71405c0,-0.72283 0.40742,-1.34765 0.99867,-1.64418l11.64925,11.99767l-11.65394,12.00233c-0.58871,-0.29743 -0.99398,-0.9208 -0.99398,-1.64177zm16.34368,-6.55441l-12.73771,7.57403l10.04592,-10.3463l2.69179,2.77227zm3.95936,-5.25479c0.42366,0.33398 0.69696,0.86024 0.69696,1.45218c0,0.58304 -0.26501,1.10223 -0.6778,1.43682l-2.70563,1.60887l-2.96082,-3.04923l2.95881,-3.0473l2.68848,1.59866zm-16.68961,-9.92389l12.72824,7.56836l-2.68978,2.77018l-10.03846,-10.33854z"
    />
  </SvgIcon>
);

export const FichaAppIcon = (props: Props): React.Node => (
  <SvgIcon {...props} fontSize="inherit">
    <path
      id="Shape"
      d="m17.24999,0l-10.49998,0c-0.96514,0 -1.75001,0.89695 -1.75001,2.00002l0,20.00001c0,1.10302 0.78487,1.99997 1.75001,1.99997l10.49998,0c0.96514,0 1.75001,-0.89695 1.75001,-2.00002l0,-19.99996c0,-1.10307 -0.78487,-2.00002 -1.75001,-2.00002zm-3.96549,21.98438l-2.61614,0c-0.24101,0 -0.43604,-0.22015 -0.43604,-0.49222c0,-0.27206 0.19503,-0.49216 0.43604,-0.49216l2.61614,0c0.24101,0 0.43604,0.22015 0.43604,0.49221c0,0.27206 -0.19503,0.49217 -0.43604,0.49217zm3.97139,-2.95313l-10.51178,0l0,-17.01562l10.51178,0l0,17.01562zm-8.58896,-9.40649l2.2035,0c0.203,0 0.36726,-0.16335 0.36726,-0.36521l0,-2.19121c0,-0.20187 -0.16426,-0.36521 -0.36726,-0.36521l-2.2035,0c-0.203,0 -0.36727,0.16334 -0.36727,0.36521l0,2.19121c0,0.20189 0.16427,0.36521 0.36727,0.36521zm4.06371,-0.36521c0,0.20186 0.16426,0.36521 0.36726,0.36521l2.20351,0c0.20299,0 0.36726,-0.16335 0.36726,-0.36521l0,-2.19121c0,-0.20187 -0.16427,-0.36521 -0.36726,-0.36521l-2.20351,0c-0.203,0 -0.36726,0.16334 -0.36726,0.36521l0,2.19121zm-4.06371,4.72458l2.2035,0c0.203,0 0.36726,-0.16334 0.36726,-0.36521l0,-2.19121c0,-0.20186 -0.16426,-0.36521 -0.36726,-0.36521l-2.2035,0c-0.203,0 -0.36727,0.16335 -0.36727,0.36521l0,2.19121c0,0.2019 0.16427,0.36521 0.36727,0.36521zm6.63448,-2.92163l-2.20351,0c-0.203,0 -0.36726,0.16335 -0.36726,0.36521l0,2.19121c0,0.20187 0.16426,0.36521 0.36726,0.36521l2.20351,0c0.20299,0 0.36726,-0.16334 0.36726,-0.36521l0,-2.19121c0,-0.20186 -0.16427,-0.36521 -0.36726,-0.36521z"
    />
  </SvgIcon>
);
