//@flow
import type { Node } from 'React';
import React from 'react';

import { ParagraphSmall } from 'components/Editor/Common/Typography/Typo';

import { Grid, Dialog } from 'components/Editor/Common';
import { TextButton } from 'components/Editor/Common/Buttons/Button';

type Props = {
  title: string,
  description: any,
  submitLabel?: string,
  handleSubmit: () => any,
  actions: Array<{ label: string, onClick: Function }>,
};

export const ConfirmationModal = ({
  title,
  description,
  submitLabel = '',
  handleSubmit,
  actions,
}: Props): Node => {
  return (
    <Dialog
      showTitle={false}
      backgroundColor="yellow"
      spacing="dense"
      size="small"
      position="center"
      dividers={false}
    >
      <Grid
        container
        alignItems="center"
        gutter="custom"
        spacing={1}
        justify="space-between"
      >
        <Grid item xs={12}>
          <ParagraphSmall>{title}</ParagraphSmall>
          {description}
        </Grid>

        <Grid item xs={12}>
          <Grid container gutter="custom" spacing={1} justify="flex-end">
            {actions.map((action) => (
              <Grid item key={action.label}>
                <TextButton
                  label={action.label}
                  style={{ opacity: '0.7' }}
                  onClick={action.onClick}
                />
              </Grid>
            ))}

            {'' !== submitLabel && (
              <Grid item>
                <TextButton label={submitLabel} onClick={handleSubmit} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
