import React, { lazy, Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  Route,
  useLocation,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import { Layout } from 'containers/Layout';

import NotFoundPage from 'components/NotFoundPage';
import NotAuthorized from 'components/NotAuthorized';
import CrossPost from './CrossPost';

const Config = lazy(() =>
  import(/* webpackChunkName: "Config" */ '../components/Config')
);
const PostVersionList = lazy(() =>
  import(/* webpackChunkName: "Version" */ '../components/PostVersionList')
);

const DeepLinkingNews = lazy(() =>
  import(/* webpackChunkName: "DeepLinkingNews" */ 'containers/DeepLinkingNews')
);

const VipLinks = lazy(() =>
  import(/* webpackChunkName: "VipLinks" */ 'containers/VipLinks')
);

const EvergreenLinks = lazy(() =>
  import(/* webpackChunkName: "EvergreenLinks" */ 'containers/EvergreenLinks')
);

const Recipes = lazy(() =>
  import(/* webpackChunkName: "Recipes" */ 'containers/Recipes')
);

const ProductDashboard = lazy(() =>
  import(
    /* webpackChunkName: "ProductDashboard" */ 'containers/ProductDashboard'
  )
);

const ProductoLayout = lazy(() =>
  import(/* webpackChunkName: "ProductoLayout" */ 'containers/ProductoLayout')
);

const VideoFooter = lazy(() =>
  import(/* webpackChunkName: "VideoFooter" */ 'containers/VideoFooter')
);

const StreamingList = lazy(() =>
  import(/* webpackChunkName: "StreamingList" */ 'containers/StreamingList')
);

const StreamingLayout = lazy(() =>
  import(/* webpackChunkName: "StreamingLayout" */ 'containers/StreamingLayout')
);

const AuthorsDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AuthorsDashboard" */ 'containers/AuthorsDashboard'
  )
);

const UserProfile = lazy(() =>
  import(/* webpackChunkName: "UserProfile" */ 'containers/UserProfile')
);

const Categories = lazy(() =>
  import(/* webpackChunkName: "Categories" */ 'containers/Categories')
);

const CategoriesListing = lazy(() =>
  import(
    /* webpackChunkName: "CategoriesListing" */ 'containers/CategoriesListing'
  )
);

const Tags = lazy(() =>
  import(/* webpackChunkName: "tags" */ 'containers/Tags')
);

const TagsListing = lazy(() =>
  import(/* webpackChunkName: "tagsListing" */ 'containers/TagsListing')
);

const VideosOnCover = lazy(() =>
  import(/* webpackChunkName: "videosOnCover" */ 'containers/VideosOnCover')
);

const VideosOnCoverListing = lazy(() =>
  import(
    /* webpackChunkName: "videosOnCoverListing" */ 'containers/VideosOnCoverListing'
  )
);

const AuthorsListing = lazy(() =>
  import(/* webpackChunkName: "authorsListing" */ 'containers/AuthorsListing')
);

const BreakingTags = lazy(() =>
  import(/* webpackChunkName: "breakingTags" */ 'containers/BreakingTags')
);

const BreakingTagsListing = lazy(() =>
  import(
    /* webpackChunkName: "breakingTagListing" */ 'containers/BreakingTagsListing'
  )
);

const SectionModule = lazy(() =>
  import(/* webpackChunkName: "SectionModule" */ 'containers/SectionModule')
);

const BlogInfo = lazy(() =>
  import(/* webpackChunkName: "BlogInfo" */ 'containers/BlogInfo')
);

const RobotsText = lazy(() =>
  import(/* webpackChunkName: "RobotsText" */ 'containers/RobotsText')
);

const AdsText = lazy(() =>
  import(/* webpackChunkName: "AdsText" */ 'containers/AdsText')
);

type Props = {
  visitorId: number,
};

const PostVersionListWrapper = () => {
  const location = useLocation();
  const { pathname } = location;
  return <PostVersionList pathname={pathname} />;
};

export const Main = ({ visitorId }: Props) => (
  <Suspense fallback={<div className="grid-wrapper">Loading...</div>}>
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route path="/">
            <Route path="post/new" element={<Layout visitorId={visitorId} />} />
            <Route
              path="escribir/*"
              element={
                <DndProvider backend={HTML5Backend}>
                  <Layout visitorId={visitorId} />
                </DndProvider>
              }
            />
            <Route path="backup/*" element={<Layout visitorId={visitorId} />} />
            <Route
              path="publicar/*"
              element={
                <DndProvider backend={HTML5Backend}>
                  <Layout visitorId={visitorId} />
                </DndProvider>
              }
            />
            <Route
              path="difundir/*"
              element={<Layout visitorId={visitorId} />}
            />
            <Route path="edit/*" element={<Layout visitorId={visitorId} />} />
            <Route
              path="/producto/*"
              element={
                <DndProvider backend={HTML5Backend}>
                  <ProductoLayout />
                </DndProvider>
              }
            />
            <Route
              path="/ficha/*"
              element={
                <DndProvider backend={HTML5Backend}>
                  <ProductoLayout />
                </DndProvider>
              }
            />
            <Route path="/version/*" element={<PostVersionListWrapper />} />
            <Route path="/streamings" element={<StreamingList />} />
            <Route
              path="/streamings/*"
              element={
                <DndProvider backend={HTML5Backend}>
                  <StreamingLayout />
                </DndProvider>
              }
            />
            <Route path="/videofooter" element={<VideoFooter />} />
            <Route path="deeplinkingnews/*" element={<DeepLinkingNews />} />
            <Route path="/viplinks" element={<VipLinks />} />
            <Route path="/evergreen" element={<EvergreenLinks />} />
            <Route path="/recipes" element={<Recipes />} />
            <Route
              path="/products"
              element={<ProductDashboard activeTab={'products'} />}
            />
            <Route
              path="/families"
              element={<ProductDashboard activeTab={'families'} />}
            />
            <Route path="/configs/*" element={<Config />} />
            <Route path="/notAuthorized" element={<NotAuthorized />} />
            <Route
              path="/users/*"
              element={<AuthorsDashboard activeTab={'users'} />}
            />
            <Route path="/users" element={<AuthorsListing />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/categories" element={<CategoriesListing />} />
            <Route path="/categories/*" element={<Categories />} />
            <Route path="/tags" element={<TagsListing />} />
            <Route path="/tags/*" element={<Tags />} />
            <Route path="/videos/*" element={<VideosOnCover />} />
            <Route path="/videos" element={<VideosOnCoverListing />} />
            <Route
              path="/cross_post"
              element={
                <DndProvider backend={HTML5Backend}>
                  <CrossPost />
                </DndProvider>
              }
            />
            <Route
              path="/cross_post/branded"
              element={
                <DndProvider backend={HTML5Backend}>
                  <CrossPost isBranded />
                </DndProvider>
              }
            />
            <Route
              path="/breakingtags"
              element={
                <DndProvider backend={HTML5Backend}>
                  <BreakingTagsListing />
                </DndProvider>
              }
            />
            <Route
              path="/breakingtags/*"
              element={
                <DndProvider backend={HTML5Backend}>
                  <BreakingTags />
                </DndProvider>
              }
            />
            <Route
              path="/sectionmodules"
              element={
                <DndProvider backend={HTML5Backend}>
                  <SectionModule />
                </DndProvider>
              }
            />
            <Route
              path="/bloginfo"
              element={
                <DndProvider backend={HTML5Backend}>
                  <BlogInfo />
                </DndProvider>
              }
            />
            <Route
              path="/robotstext"
              element={
                <DndProvider backend={HTML5Backend}>
                  <RobotsText />
                </DndProvider>
              }
            />
            <Route
              path="/ads"
              element={
                <DndProvider backend={HTML5Backend}>
                  <AdsText />
                </DndProvider>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        )
      )}
    />
  </Suspense>
);
