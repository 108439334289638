// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Main } from 'components/Editor/Common/Main';
import { sectionType, InputEvent } from 'lib/flowTypes';
import { receivePost } from 'actions/post';
import { ModuleWrapper } from 'components/Editor/Common/ModuleWrapper';
import {
  Label,
  Grid,
  Warning,
  Error,
  ConfirmationModal,
  SuccessMessage,
} from 'components/Editor/Common';
import { NormalButtonWithLeftIcon } from 'components/Editor/Common/Buttons/Button';
import { UploadIcon, DownloadIcon } from 'components/Editor/Common/Icons';
import { listenToPost, updatePostInFirebase, getPost } from './lib/service';
import { POST_TYPE_LANDING } from 'lib/constants';
import { isMobile } from 'lib/deviceDetect';
import { isCountryMismatch, dumpJson, getContentHashCode } from 'lib/helpers';

type Props = {
  firebaseId: string,
  postName: String,
  siteName: string,
  sections: sectionType[],
  receivePost: Function,
  postType: string,
  status: string,
  title: string,
  subTitle: string,
  primaryImage: string,
  maxId: Number,
  isTocExists: boolean,
};

const isValidCurrency = (sections, sourceSiteName, siteName) => {
  const foundNode = sections.filter(
    (section) => ['pivotEcommerce', 'fichaCompra'].indexOf(section.type) !== -1
  );

  if (foundNode.length > 0 && isCountryMismatch(sourceSiteName, siteName)) {
    return false;
  }

  return true;
};

const Backup = ({
  firebaseId,
  sections,
  siteName,
  postName,
  receivePost,
  postType,
  status,
  title,
  subTitle,
  primaryImage,
  maxId,
  isTocExists,
}: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!firebaseId) {
      // Fetch data from firebase only if redux store is empty
      listenToPost(postName, receivePost);
    }
  });

  const createBackup = () => {
    const content = {
      postType,
      sections,
      siteName,
      title,
      primaryImage,
      subTitle,
      maxId,
      isTocExists,
    };

    return dumpJson(content, firebaseId);
  };

  const handleFileSelection = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('accept', '.dat');
    fileSelector.addEventListener('change', handleFile);
    fileSelector.click();
  };

  const restorePost = async (data, fileName) => {
    if (postType === POST_TYPE_LANDING && data.postType !== POST_TYPE_LANDING) {
      setErrorMessage(
        `${fileName} es una copia de seguridad de post y no se puede restaurar en una landing.`
      );
      return;
    }

    if (postType !== POST_TYPE_LANDING && data.postType === POST_TYPE_LANDING) {
      setErrorMessage(
        `${fileName} es una copia de seguridad de landing y no se puede restaurar en un post.`
      );
      return;
    }

    if (!isValidCurrency(data.sections, data.siteName, siteName)) {
      setErrorMessage(
        'no se puede restaurar una copia de seguridad de un medio de otro país si incluye pivot ecommerce o ficha de compra.'
      );
      return;
    }

    await updatePostInFirebase(firebaseId, {
      sections: data.sections,
      title: data.title,
      subTitle: data.subTitle || '',
      primaryImage: data.primaryImage,
      maxId: data.maxId,
      productsCreated: 1,
      isTocExists: data.isTocExists || false,
    });
    const postData = await getPost(postName);
    receivePost(postData);
    setSuccessMessage(
      `Se ha restaurado la copia de seguridad ${fileName} correctamente.`
    );
  };

  const fileParser = (event: InputEvent, fileName: string) => {
    try {
      const { hashCode, ...data } = JSON.parse(
        decodeURIComponent(escape(atob(event.target.result)))
      );
      if (hashCode !== getContentHashCode(JSON.stringify(data))) {
        throw new Error('Invalid hash');
      }
      restorePost(data, fileName);
    } catch (e) {
      setErrorMessage(
        'esta copia de seguridad está dañada y no puede restaurarse.'
      );
    }
  };

  const handleFile = (e: InputEvent) => {
    e.preventDefault();
    setShowConfirmation(false);
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    reader.addEventListener('load', (event: InputEvent) =>
      fileParser(event, fileName)
    );
    reader.readAsText(e.target.files[0]);
  };

  const getWarningMessage = () => {
    if ('publish' === status) {
      return 'Para restaurar una copia de seguridad, pasa el post a borrador antes.';
    }
    if ('' === errorMessage && '' === successMessage) {
      return 'Cuidado: al restaurar una copia de seguridad, el contenido del post se reemplazará totalmente.';
    }

    return '';
  };

  const openConformation = () => {
    setSuccessMessage('');
    setErrorMessage('');
    setShowConfirmation(true);
  };

  const warningMessage = getWarningMessage();

  return (
    <>
      <Main baseTheme="white">
        <ModuleWrapper id="container" margin="min">
          <Grid container gutter="custom" spacing={6}>
            <Grid item xs={12}>
              <Label label="Copia de seguridad" />
            </Grid>
          </Grid>

          <Grid
            container
            gutter="custom"
            spacing={isMobile() ? 4 : 6}
            justify={isMobile() ? 'center' : 'space-between'}
          >
            <Grid item>
              <NormalButtonWithLeftIcon
                icon={UploadIcon}
                label="Restaurar copia"
                disabled={'publish' === status || !firebaseId}
                onClick={openConformation}
              />
            </Grid>

            <Grid item>
              <NormalButtonWithLeftIcon
                icon={DownloadIcon}
                label="Descargar copia"
                onClick={createBackup}
                disabled={!firebaseId}
              />
            </Grid>
          </Grid>

          <Grid container gutter="custom" spacing={6}>
            <Grid item md={6} xs={12}>
              {'' !== warningMessage && (
                <Warning margin="min" message={warningMessage} />
              )}
              {'' !== successMessage && (
                <SuccessMessage message={successMessage} />
              )}
              {'' !== errorMessage && <Error message={errorMessage} />}
            </Grid>
          </Grid>
        </ModuleWrapper>
      </Main>
      {showConfirmation && (
        <ConfirmationModal
          title="Al restaurar la copia, el contenido del post se reemplazará completamente"
          submitLabel="Restaurar"
          handleSubmit={handleFileSelection}
          actions={[
            { label: 'Cancelar', onClick: () => setShowConfirmation(false) },
          ]}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.post.status,
    postType: state.post.postType,
    firebaseId: state.post.id,
    sections: state.sections,
    title: state.post.title,
    subTitle: state.post.subTitle || '',
    primaryImage: state.post.primaryImage,
    maxId: state.post.maxId,
    isTocExists: state.post.isTocExists || false,
  };
};

export default connect(mapStateToProps, { receivePost })(React.memo(Backup));
