import { OPEN_MODAL, CLOSE_MODAL } from 'actions/modal';
import {
  ADD_SECTION,
  ADD_GRID_SECTION,
  EDIT_SECTION,
  EDIT_GRID_SECTION,
  EDIT_TABLE
} from 'actions/post';

const initialState = {
  modalName: '',
  prevModalData: { section: {}, name: '', mode: '' },
  mode: 'add'
};

const modals = function(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      const { modalName, mode, prevModalData } = action;
      return {
        modalName,
        mode,
        prevModalData: prevModalData || state.prevModalData
      };
    case ADD_SECTION:
    case ADD_GRID_SECTION:
    case CLOSE_MODAL:
    case EDIT_SECTION:
    case EDIT_TABLE:
    case EDIT_GRID_SECTION:
      return {
        ...state,
        modalName: '',
        mode: '',
        prevModalData: initialState.prevModalData
      };
    default:
      return state;
  }
};

export default modals;
