import React from 'react';
import MuiMenuList from '@mui/material/MenuList';
import MuiMenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import MuiMenu, { MenuProps } from '@mui/material/Menu';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '15px',

          '&.Mui-selected': {
            backgroundColor: '#fce4ec',

            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },

          '@media(min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '36px',
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: '#FCE4EC',
          },
        },
      },
    },
  },
});

type Props = {
  onClick?: () => void,
  selected?: boolean,
  children: any,
};

export const MenuList = ({ children, ...rest }: Props) => {
  return <MuiMenuList {...rest}>{children}</MuiMenuList>;
};

export const MenuItem = ({
  selected = false,
  onClick,
  children,
  ...rest
}: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiMenuItem selected={selected} onClick={onClick} {...rest}>
        {children}
      </MuiMenuItem>
    </ThemeProvider>
  );
};

export const Menu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <MuiMenu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const ListItemIcon = ({ children, ...rest }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiListItemIcon {...rest}>{children}</MuiListItemIcon>
    </ThemeProvider>
  );
};

export const ListItemText = ({ children, ...rest }: Props) => {
  return <MuiListItemText {...rest}>{children}</MuiListItemText>;
};
