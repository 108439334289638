import React, { Component } from 'react';
import { ClickAwayListener } from 'components/Editor/Common';

import URLUtils from '../../utils/URLUtils';
import EditorUtils from '../../utils/EditorUtils';
import AddLinkComponent from './AddLinkComponent';

type Props = {
  getEditorState: Function,
  setEditorState: Function,
  onOverrideContent: Function,
  theme: Object,
  placeholder: string,
  addLink?: Function,
  getUrl: Function,
};

type State = {
  value: string,
  isInvalid: boolean,
};

export default class AddLinkForm extends Component<Props, State> {
  state = {
    value: '',
    mode: 'add',
    isInvalid: false,
  };

  componentDidMount() {
    const editorState = this.props.getEditorState();
    const { url, linkKey, isAnchor } = this.props.getUrl(editorState);
    if (url && !isAnchor) {
      this.setState({
        value: url,
        mode: 'edit',
        linkKey,
      });
    }
  }

  onChange = ({ target: { value } }) => {
    const nextState: State = { value };
    if (this.state.isInvalid && URLUtils.isUrl(URLUtils.normalizeUrl(value))) {
      nextState.isInvalid = false;
    }
    this.setState(nextState);
  };

  onClose = () => {
    this.props.onOverrideContent(undefined);
  };

  onKeyDown = (e: SyntheticKeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submit();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      this.onClose();
    }
  };

  submit = () => {
    const { getEditorState, setEditorState, addLink } = this.props;
    let { value: url, mode, linkKey } = this.state;

    if (!URLUtils.isMail(URLUtils.normaliseMail(url))) {
      url = URLUtils.normalizeUrl(url);
      if (!URLUtils.isUrl(url)) {
        this.setState({ isInvalid: true });
        return;
      }
    } else {
      url = URLUtils.normaliseMail(url);
    }
    const newEditorState =
      mode === 'add'
        ? EditorUtils.createLinkAtSelection(getEditorState(), url)
        : EditorUtils.replaceLinkAtSelection(getEditorState(), url, linkKey);
    setEditorState(newEditorState);
    this.onClose();
    mode === 'add' && addLink && addLink(url);
  };

  preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { getEditorState, setEditorState } = this.props;
    const newEditorState = EditorUtils.removeLinkAtSelection(getEditorState());
    setEditorState(newEditorState);
    this.onClose();
  };

  handleClickAway = (e) => {
    this.onClose();
  };

  render() {
    const { placeholder } = this.props;
    const { value, isInvalid, mode } = this.state;
    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <AddLinkComponent
          mode={mode}
          placeholder={placeholder}
          value={value}
          onClick={this.preventDefault}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          onDelete={this.onDelete}
          isInvalid={isInvalid}
          onBlur={this.submit}
        />
      </ClickAwayListener>
    );
  }
}
