// @flow
import type { Node } from 'React';
import React, { PureComponent } from 'react';
import styles from './Anchor.module.css';

import {
  AnchorIcon,
  AnchorDeLink,
} from 'components/Editor/Common/CustomSVGIcons';
import { Toolbar, SeparatorToolbar } from 'components/Editor/Common/Toolbar';
import EditorUtils from 'components/Editor/Common/draft-js-anchor-plugin/utils/EditorUtils';

type Props = {
  theme: Object,
  getUrl: Function,
  onClose: Function,
  updateAnchor: Function,
  getEditorState: Function,
  setEditorState: Function,
};

export class AnchorToolbar extends PureComponent<Props> {
  deleteAnchor: (e: InputEvent) => void = (e: InputEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const { getEditorState, setEditorState, updateAnchor, onClose, getUrl } =
      this.props;
    const editorState = getEditorState();
    const newEditorState = EditorUtils.removeLinkAtSelection(editorState);
    setEditorState(newEditorState);

    const { url, linkId } = getUrl(editorState);
    const fragment = url.split('#')[1];
    const id = fragment.split('-')[1];
    updateAnchor('delete', { id, linkId });
    onClose();
  };

  editAnchor: (e: InputEvent) => void = (e: InputEvent) => {
    const { updateAnchor, getEditorState, setEditorState, onClose } =
      this.props;
    this.deleteAnchor(e);
    updateAnchor('add', { store: { getEditorState, setEditorState } });
    onClose();
  };

  render(): Node {
    const { theme } = this.props;
    return (
      <Toolbar>
        <div className={theme.buttonWrapper}>
          <button
            className={theme.button}
            onClick={this.editAnchor}
            type="button"
          >
            <AnchorIcon />
            <span className={styles.toolTiptext}>Cambiar ancla</span>
          </button>
        </div>
        <SeparatorToolbar />
        <div className={theme.buttonWrapper}>
          <button
            className={theme.button}
            onClick={this.deleteAnchor}
            type="button"
          >
            <AnchorDeLink fontSize="default" />
          </button>
        </div>
      </Toolbar>
    );
  }
}
