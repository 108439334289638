import React from 'react';
import { EditorState, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Editor from '@draft-js-plugins/editor';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';

import { draftJSCustomToolbar } from 'components/Editor/Common/DraftJSToolbar';
import { initializeEditor } from 'components/Editor/Common/DraftJSToolbar/helpers';
import { withLabel } from './withLabel';
import {
  handleKeyCommand,
  myKeyBindingFn,
  checkPosition,
  handlePastedText,
  getCurrentBlock,
} from './helpers';
import styles from './styles.module.css';

type Props = {
  text: string,
  hoverBackground?: boolean,
  editorId?: string,
  toolbarKey?: string,
  placeholder?: string,
  label?: string,
  error?: boolean,
  errorText?: string,
  toolbarOptions?: {
    includeOptions?: string[],
    excludeOptions?: string[],
    addSummary?: Function,
    addLink?: Function,
  },
  allowedShortcuts?: string[],
  index?: string,
  stripStylesOnPaste?: Boolean,
  changePosition?: Function,
  addDropArea?: Function,
  setEditorEmpty?: (boolean) => void,
  updateParent?: (EditorState) => void,
  extraProps?: Object,
  collapseOnBlur?: boolean,
  helperText?: string,
};

type State = {
  editorState: EditorState,
};

const HANDLED = 'handled';
const NOT_HANDLED = 'not_handled';

export default class DraftJSEditor extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      editorState: initializeEditor(this.props.text),
      isFocused: false,
    };
    this._editor = React.createRef();
    const inlineToolbar = draftJSCustomToolbar(this.props.toolbarOptions || {});
    this.plugins = inlineToolbar.plugins;
    this.InlineToolbar = inlineToolbar.Toolbar;
    this.shortcuts = this.props.allowedShortcuts || inlineToolbar.shortcuts;
  }

  componentDidUpdate(prevProps, prevState) {
    const { editorState } = this.state;
    const isEmpty = this.isEmpty(editorState);
    if (
      this.isEmpty(prevState.editorState) !== isEmpty &&
      this.props.setEditorEmpty
    ) {
      this.props.setEditorEmpty(isEmpty);
    }

    const { index, changePosition, addDropArea } = this.props;
    if (index && changePosition && addDropArea) {
      checkPosition(index, changePosition, editorState, addDropArea);
    }
  }

  componentDidCatch(error, info) {
    this.setState({ editorState: initializeEditor('') });
  }

  getText = () => {
    const options = {
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          const data = entity.getData();
          let url = data.url;
          const [pageUrl, fragment] = data.url.split('#');
          if (fragment && (!pageUrl || pageUrl === window.location.href)) {
            url = '#' + fragment;
          }
          return {
            element: 'a',
            attributes: {
              href: url,
              rel: data.rel,
              'data-id': data.rel,
            },
          };
        }
      },
    };
    const currentContent = this.state.editorState.getCurrentContent();
    return stateToHTML(currentContent, options);
  };

  onChange = (editorState) => {
    const { text, updateParent } = this.props;
    try {
      this.setState({ editorState }, () => {
        if (updateParent && text !== this.getText()) {
          updateParent();
        }
      });
    } catch (e) {
      console.log('Caught error: ' + e);
    }
  };

  onFocus = (e) => {
    this._editor.current.focus();
    this.setState({ isFocused: true });
  };

  onBlur = () => this.setState({ isFocused: false });

  isEmpty(editorState) {
    // Added next line for cases when the editor crashes
    if (typeof editorState.getCurrentContent !== 'function') {
      return true;
    }
    return !editorState.getCurrentContent().getPlainText().trim();
  }

  getPlainText = () =>
    this.state.editorState.getCurrentContent().getPlainText();

  reinitializeEditor = (text) =>
    this.setState({ editorState: initializeEditor(text) });

  keyCommandHandler = (command, editorState) => {
    let newState = handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return HANDLED;
    }
    return NOT_HANDLED;
  };

  handlePastedText = (text: string, html?: string, editorState: EditorState) =>
    handlePastedText(text, html, editorState, this.onChange);

  handleReturn = (e) => {
    const currentBlock = getCurrentBlock(this.state.editorState);
    const blockType = currentBlock.getType();
    if ('blockquote' === blockType && isSoftNewlineEvent(e)) {
      this.onChange(RichUtils.insertSoftNewline(this.state.editorState));
      return HANDLED;
    }
    return NOT_HANDLED;
  };

  render() {
    const {
      placeholder,
      label = '',
      error = false,
      errorText = '',
      editorId = 'draftJsEditor',
      extraProps = {},
      toolbarOptions,
      toolbarKey,
      hoverBackground = false,
      collapseOnBlur = false,
      helperText,
    } = this.props;
    const { editorState, isFocused } = this.state;

    const activeClasses = [styles.container];

    if (hoverBackground) {
      isFocused
        ? activeClasses.push(styles.active)
        : activeClasses.push(styles.inActive);
    }

    if (collapseOnBlur && !isFocused) {
      activeClasses.push(styles.collapsed);
    }

    const editor = (
      <div
        id={editorId}
        onClick={this.onFocus}
        onBlur={this.onBlur}
        className={activeClasses.join(' ')}
      >
        <Editor
          ref={this._editor}
          placeholder={placeholder}
          editorState={editorState}
          onChange={this.onChange}
          plugins={this.plugins}
          keyBindingFn={(e) => myKeyBindingFn(e, this.shortcuts, editorId)}
          handleKeyCommand={this.keyCommandHandler}
          spellCheck
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          handlePastedText={this.handlePastedText}
          helperText={helperText}
          {...extraProps}
        />
        {toolbarOptions && <div key={toolbarKey}>{this.InlineToolbar}</div>}
      </div>
    );

    return !!label || error || !!helperText
      ? withLabel({
          label,
          isFocused,
          error,
          errorText,
          editor,
          helperText,
        })
      : editor;
  }
}
