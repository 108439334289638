import React from 'react';
import Fab from '@material-ui/core/Fab';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiFab: {
      root: {
        position: 'fixed',
        right: '16px',
        bottom: '16px',
        backgroundColor: '#ffffff',

        '@media(min-width: 768px)': {
          right: '24px',
          bottom: '24px',
        },
      },
    },
  },
});

export const NavigateToTop = () => (
  <MuiThemeProvider theme={theme}>
    <Fab onClick={() => window.scrollTo(0, 0)}>
      <ArrowUpward />
    </Fab>
  </MuiThemeProvider>
);
