/*@flow*/
import * as React from 'react';
import ReactCrop from 'react-image-crop';
import { Image } from 'components/Editor/Common/Image';
import { CircularLoader } from 'components/Editor/Common/Loaders';
import { NormalButton } from 'components/Editor/Common/Buttons/Button';
import styles from './CropWidget.module.css';

type Props = {
  imageSrc: string,
  crop: { validate: boolean, unit: string, aspect: number },
  shape: string,
  errorText?: string,
  onCropChange: (shape: string, crop: Object, pixelCrop?: Object) => void,
  onCropValidate: (shape: string, validate: boolean) => any,
  isImageValidating: boolean,
  onImageLoaded?: Function,
  onCropComplete?: Function,
  crossOrigin?: string,
  showWatermark?: boolean,
  type?: string,
};

const CropWidget = ({
  imageSrc,
  crop,
  shape,
  onCropChange,
  onCropValidate,
  isImageValidating,
  onImageLoaded = () => {
    return false;
  },
  onCropComplete = () => {},
  crossOrigin,
  showWatermark = false,
  type = 'ficha',
}: Props): React.Element<'div'> => {
  const { aspect, ...restCrop } = crop;

  const getWatermark = () => {
    if (showWatermark) {
      return (
        <Image
          alt={shape}
          size="fullWidth"
          src={`https://img.weblogssl.com/g/r/png/${type}-cropper-guide-${shape}.png`}
        />
      );
    }
    return null;
  };

  if (!imageSrc) {
    return (
      <div className={`uploader ${styles.defaultImageArea}`}>
        {getWatermark()}
      </div>
    );
  }

  const getClassName = () => {
    if (!showWatermark) {
      return styles.crop;
    }
    if ('square' === shape) {
      return `${styles.crop} ${styles.square}`;
    }
    if ('golden' === shape) {
      return `${styles.crop} ${styles.golden}`;
    }
    if ('panoramic' === shape) {
      return `${styles.crop} ${styles.panoramic}`;
    }
  };

  return (
    <div className={!crop.validate ? 'image-cropper-error' : 'image-cropper'}>
      <ReactCrop
        crop={{ unit: crop.unit || '%', ...restCrop }}
        aspect={aspect}
        disabled={crop.validate}
        keepSelection
        onChange={(crop, percentCrop) => onCropChange(shape, percentCrop, crop)}
        className={getClassName()}
        style={{ animation: 'none' }}
        onComplete={() => onCropComplete(shape)}
        renderSelectionAddon={getWatermark}
      >
        <img
          src={imageSrc}
          crossOrigin={crossOrigin}
          onLoad={onImageLoaded}
          alt=""
        />
      </ReactCrop>

      {crop.validate ? (
        <div className={styles.btnContainer}>
          <NormalButton label="VALIDADO" disabled />
          <NormalButton
            label="EDITAR"
            onClick={(e) => {
              e.preventDefault();
              onCropValidate(shape, false);
            }}
          />
        </div>
      ) : (
        <div className={styles.btnContainer}>
          <NormalButton
            disabled={isImageValidating}
            onClick={(e) => {
              e.preventDefault();
              onCropValidate(shape, true);
            }}
          >
            {isImageValidating && (
              <CircularLoader
                show
                size={24}
                className="button-left-icon"
                color="inherit"
              />
            )}
            VALIDAR
          </NormalButton>
        </div>
      )}
    </div>
  );
};

export default CropWidget;
