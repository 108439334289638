import idx from 'idx';

import { currentTime, getCurrentUtcTime } from 'containers/lib/momentHelper';
import {
  defaultCommentStatus,
  initialMeta,
  initCrop,
  MAX_GIF_SIZE,
} from 'lib/constants';
import { getInitialPublishRegions, getImageUrl } from 'lib/helpers';
import {
  RECEIVE_POST,
  CHANGE_LAYOUT,
  EDIT_TABLE,
  EDIT_DESIGN_FIELD,
  EDIT_COLUMN_DESIGN_FIELD,
  ALTER_TABLE_ROWS,
  ALTER_TABLE_COLUMNS,
  CHANGE_TABLE_ALIGNMENT,
  MODIFY_TABLE_ROW_COLUMN,
  UPDATE_ANCHOR_SOURCE,
  ADD_SECTION,
  ADD_GRID_SECTION,
  CHANGE_POSITION,
  CHANGE_CURRENT_INDEX,
  CHANGE_TITLE,
  CHANGE_SUB_TITLE,
  DELETE_SECTION,
  DELETE_GRID_SECTION,
  EDIT_SECTION,
  EDIT_GRID_SECTION,
  APPEND_SECTION,
  CHANGE_SELECTED_PRODUCT,
  CHANGE_CONTENT,
  UNDO_GRID,
  CROP_CHANGE,
  CROP_COMPLETE,
  EDIT_FIELD,
  SUBMIT_POST,
} from 'actions/post';
import { OPEN_TOOLBAR } from 'actions/common';
import { OPEN_MODAL } from 'actions/modal';

const initialState = {
  productToEmbed: {},
  lastUpdatedAt: getCurrentUtcTime('DD/MM/YYYY HH:mm'),
};

const getUpdatedState = (state, section) => {
  const { type } = section;
  if ('pivotEcommerce' === type) {
    return {
      ...state,
      productsCreated: 1,
    };
  }

  return state;
};

const getPrimaryImage = (section) => {
  if ('video' === section.type) {
    return getImageUrl(section.thumbnail);
  }

  if ('gif' === section.extension && section.hasOwnProperty('size')) {
    if (MAX_GIF_SIZE <= section.size) {
      return '';
    }
  }

  return getImageUrl(section);
};

const post = function (state = initialState, action) {
  switch (action.type) {
    case RECEIVE_POST:
      const { post } = action;
      if (!post || !post.id) {
        return state;
      }

      return {
        ...state,
        id: post.id,
        postType: post.postType,
        title: post.title,
        subTitle: post.subTitle,
        status: post.status,
        siteName: post.blogName,
        userId: post.user_id,
        meta: post.meta || initialMeta,
        postCategories: post.postCategories || [],
        category: post.category || -1,
        categoryNicename: post.categoryNicename || '',
        tags: post.tags || [],
        publishedDate:
          post.status === 'draft' ? currentTime() : post.publishData.postDate,
        isSensitive: post.isSensitive || false,
        commentStatus:
          post.commentStatus || defaultCommentStatus[post.postType],
        postId: idx(post, (_) => _.publishData.postId) || '',
        primaryImage: post.primaryImage || '',
        publishRegion:
          idx(post, (_) => _.publishData.publishRegion) ||
          getInitialPublishRegions(),
        maxId: post.maxId || 3,
        online: post.online ? Object.keys(post.online).length : 0,
        republishDate: post.republishDate || '',
        productsCreated: post.productsCreated || 0,
        reindexedNames: post.reindexedNames || [],
        isContentUpdated: post.isContentUpdated || false,
        crop: post.crop || initCrop,
        isReindexScheduled: post.isReindexScheduled || false,
        twitter: post.twitter || '',
        author: post.user_name || '',
        specialPost: post.specialPost || false,
        isTocExists: post.isTocExists || false,
      };
    case CHANGE_TITLE:
      let twitter = state.twitter;

      if (state.title === twitter) {
        twitter = action.title;
      }
      return {
        ...state,
        isAtFirstPosition: false,
        title: action.title,
        twitter,
        isContentUpdated: true,
      };

    case CHANGE_SUB_TITLE:
      return {
        ...state,
        isAtFirstPosition: false,
        subTitle: action.subTitle,
        isContentUpdated: true,
      };

    case EDIT_FIELD:
      const { field, value } = action;
      if (field === 'primaryImage') {
        return {
          ...state,
          crop: initCrop,
          [field]: value,
        };
      }
      return {
        ...state,
        [field]: value,
      };
    case EDIT_TABLE:
    case EDIT_GRID_SECTION:
    case CHANGE_LAYOUT:
    case EDIT_DESIGN_FIELD:
    case EDIT_COLUMN_DESIGN_FIELD:
    case UPDATE_ANCHOR_SOURCE:
    case ALTER_TABLE_ROWS:
    case ALTER_TABLE_COLUMNS:
    case CHANGE_TABLE_ALIGNMENT:
    case MODIFY_TABLE_ROW_COLUMN:
      return {
        ...state,
        isContentUpdated: true,
      };
    case EDIT_SECTION:
      if (action.section.index === '1') {
        if (action.section.type === 'video') {
          return {
            ...state,
            isAtFirstPosition: false,
            isContentUpdated: true,
            crop: initCrop,
            primaryImage: getImageUrl(action.section.thumbnail),
          };
        } else if (action.crop) {
          return {
            ...state,
            isAtFirstPosition: false,
            isContentUpdated: true,
            crop: action.crop,
            primaryImage: getPrimaryImage(action.section),
          };
        } else {
          return {
            ...state,
            isAtFirstPosition: false,
            isContentUpdated: true,
          };
        }
      } else {
        let maxId = state.maxId;
        if ('grid' === action.section.type && action.section.columnDiff) {
          const diff =
            action.section.columnDiff < 0 ? 2 : action.section.columnDiff;
          maxId = state.maxId + diff;
        }
        return {
          ...getUpdatedState(state, action.section),
          isContentUpdated: true,
          maxId,
        };
      }
    case ADD_GRID_SECTION:
    case ADD_SECTION:
      if (action.section.id <= state.maxId) {
        console.log(action.section.id, state.maxId, 'id and maxId in reducer');
      }
      let maxId = state.maxId + 3;
      if ('grid' === action.section.type) {
        maxId += action.section.columnLayout.length;
      }

      return {
        ...getUpdatedState(state, action.section),
        maxId,
        isAtFirstPosition: false,
        isContentUpdated: true,
        isTocExists:
          'tableOfContents' === action.section.type ? true : state.isTocExists,
      };
    case APPEND_SECTION:
      return {
        ...getUpdatedState(state, action.section),
        maxId: state.maxId + 2,
        isAtFirstPosition: false,
        isContentUpdated: true,
      };
    case UNDO_GRID:
      return {
        ...state,
        maxId: state.maxId + 2,
        isContentUpdated: true,
      };
    case DELETE_GRID_SECTION:
    case DELETE_SECTION:
      return {
        ...getUpdatedState(state, { type: action.data.sectionType }),
        maxId: state.maxId + 1,
        isContentUpdated: true,
        isTocExists:
          'tableOfContents' === action.data.sectionType
            ? false
            : state.isTocExists,
      };
    case CHANGE_POSITION:
      return {
        ...state,
        currentIndex: action.index,
        splitPosition: action.splitPosition,
        coordinates: action.coordinates,
        isAtFirstPosition: action.isAtFirstPosition,
      };
    case OPEN_MODAL:
      return {
        ...state,
        isAtFirstPosition: false,
      };
    case CHANGE_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.index,
        isAtFirstPosition: false,
      };
    case CHANGE_CONTENT:
      return {
        ...state,
        isContentUpdated: true,
      };
    case CHANGE_SELECTED_PRODUCT:
      return {
        ...state,
        productToEmbed: action.productToEmbed,
      };
    case OPEN_TOOLBAR:
      return {
        ...state,
        isAtFirstPosition: false,
      };
    case CROP_CHANGE:
      return {
        ...state,
        crop: action.crop,
      };
    case CROP_COMPLETE:
      return {
        ...state,
        primaryImage: action.primaryImage,
        isContentUpdated: true,
      };
    case SUBMIT_POST:
      return {
        ...state,
        productsCreated: 0,
        isContentUpdated: false,
      };
    default:
      return state;
  }
};

export default post;
