import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import styles from './basicLabel.module.css';

const theme = createTheme({
  typography: {
    caption: {
      display: 'inline-flex',
      lineHeight: '16px',
      letterSpacing: '0',

      '@media(max-width: 767px)': {
        display: 'block',
        marginLeft: '0 !important',
      },
    },
  },
});

type Props = {
  label?: string,
  hint?: string | React.Node,
};

const getClassName = (label) => {
  if ('' === label) {
    return ``;
  }

  return `${styles.mMarginLeft}`;
};

export const BasicLabel = ({ label = '', hint }: Props) => (
  <ThemeProvider theme={theme}>
    <Typography variant="h6">
      {label}
      <Typography
        variant="caption"
        color="inherit"
        className={getClassName(label)}
      >
        {hint}
      </Typography>
    </Typography>
  </ThemeProvider>
);
