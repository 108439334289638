import { Section } from 'lib/flowTypes';
import { POST_TYPE_LANDING } from 'lib/constants';

export const initialState = [
  {
    id: 0,
    type: 'title'
  },
  {
    id: 1,
    type: ''
  },
  {
    id: 2,
    type: 'content',
    text: ''
  }
];

const isEmpty = text => {
  return '' === text || '<p><br></p>' === text;
};

export const joinTexts = (text1, text2) => {
  if (text1 === '<p><br></p>') {
    text1 = '';
  } else if (text2 === '<p><br></p>') {
    text2 = '';
  }
  return text1 + text2;
};

export const getInitialState = postType => {
  if (POST_TYPE_LANDING === postType) {
    let titleSection = { ...initialState[0], layout: 'normal' };
    return [titleSection, ...initialState.splice(1)];
  }
  return initialState;
};

export const handleTextChange = (
  index: string,
  sections: Section[],
  text: string
) => {
  if (index.indexOf('-') > -1) {
    const [gridIndex, colIndex, sectionIndex] = index.split('-');
    const gridSection = {...sections[gridIndex]};
  
    const colSections = [...gridSection['columns'][colIndex]];
  
    colSections[sectionIndex] = {
      ...colSections[sectionIndex],
      text
    };

    gridSection['columns'] = [
      ...gridSection['columns'].slice(0, colIndex),
      colSections,
      ...gridSection['columns'].slice(parseInt(colIndex) + 1)
    ];

    return { index: gridIndex, section: gridSection };
  }

  const section = sections.slice(index, parseInt(index) + 1)[0];
  return { index, section: { ...section, text } };
};

export const handleTableEdit = (index: string, sections: Section[], data) => {
  if (index.indexOf('-') > -1) {
    const [gridIndex, columnIndex, sectionIndex] = index.split('-');
    const [tableIndex, tableRowIndex, tableColIndex] = sectionIndex.split(':');
    const section = sections.slice(gridIndex, parseInt(gridIndex) + 1)[0];
    const sectionAttributes = section['columns'][columnIndex][tableIndex];
    const rows = [...sectionAttributes.rows];
    const rowData = [...rows[tableRowIndex]];
    const tableCellSection = rowData[tableColIndex];
    if (data.type === 'image') {
      delete tableCellSection.text;
    }
    rowData[tableColIndex] = {
      ...tableCellSection,
      ...data
    };
    rows[tableRowIndex] = rowData;
    section['columns'][columnIndex][tableIndex].rows = rows;
    return { index: gridIndex, section };
  } else {
    const [tableIndex, rowIndex, colIndex] = index.split(':');
    const sectionAttributes = sections[tableIndex];
    const rows = [...sectionAttributes.rows];
    const rowData = [...rows[rowIndex]];
    const tableCellSection = rowData[colIndex];
    if (data.type === 'image') {
      delete tableCellSection.text;
    }
    rowData[colIndex] = {
      ...tableCellSection,
      ...data
    };
    rows[rowIndex] = rowData;
    const section = {
      ...sectionAttributes,
      rows
    };
    return { index: tableIndex, section };
  }
};

const delimiter = '\n';

const getContentSection = (
  tokens,
  section,
  id,
  startPosition,
  endPosition = undefined
) => {
  let { design = {}, isHidden = false } = section;
  const text = tokens.slice(startPosition, endPosition).join(delimiter);

  if (isEmpty(text)) {
    design = {};
    isHidden = false;
  }

  return {
    id,
    type: 'content',
    text,
    design,
    isHidden
  };
};

export const getSectionsToAdd = (
  sections: Section[],
  index: string,
  action: {},
  newSection: Section
) => {
  const { position, extractedFromText } = action;
  const tokens = sections[index].text.split(delimiter);
  let start = position;
  const elements = ['<p><ol>', '<ol>', '</ol>', '<ul>', '</ul>'];
  for (let i = 0; i <= start; i++) {
    if (elements.includes(tokens[i])) {
      start++;
    }
  }

  const contentSection1 = getContentSection(
    tokens,
    sections[index],
    newSection.id + 1,
    0,
    start
  );
  const splitPosition2 =
    extractedFromText || !isEmpty(tokens.slice(start, start + 1)[0])
      ? start
      : start + 1;
  const contentSection2 = getContentSection(
    tokens,
    sections[index],
    newSection.id + 2,
    splitPosition2
  );

  if (
    (!newSection.hasOwnProperty('design') ||
      Object.keys(newSection.design).length === 0) &&
    !isEmpty(contentSection1.text) &&
    !isEmpty(contentSection2.text) &&
    sections[index].design
  ) {
    newSection = { ...newSection, design: sections[index].design };
  }

  return [contentSection1, newSection, contentSection2];
};

export const getGridColumns = (columnLayout: number[], gridId: number) => {
  const columns = [];
  let id = gridId;

  columnLayout.forEach((size, i) => {
    columns[i] = [
      {
        id,
        type: 'content',
        text: ''
      }
    ];
    id += 1;
  });
  return { columns, id };
};

export const addNewGridColumns = (
  sectionAttributes: Section,
  modifiedAttributes: Section
) => {
  const currentLayout = sectionAttributes.columnLayout;
  const newLayout = modifiedAttributes.columnLayout;
  const { columns } = getGridColumns(
    newLayout.slice(currentLayout.length),
    modifiedAttributes.id
  );
  modifiedAttributes.columns.push(...columns);

  return modifiedAttributes;
};

export const releaseGridColumns = (section: Section) => {
  const newLayoutCount = section.columnLayout.length;
  const releasedColumns = section.columns.slice(newLayoutCount);
  const releasedNodes = joinGridColumns(releasedColumns);
  section.columns = section.columns.slice(0, newLayoutCount);

  return { section, releasedNodes };
};

export const join2SectionArrays = (sections1, sections2, newId) => {
  const joinedSections = join2Sections(
    sections1[sections1.length - 1],
    sections2[0],
    newId
  );
  return [...sections1.slice(0, -1), ...joinedSections, ...sections2.slice(1)];
};

export const joinTextSections = (section1, section2, newId) => {
  const isHidden = section1.isHidden || section2.isHidden || false;
  let sectionToAdd = {
    id: newId,
    type: 'content',
    text: joinTexts(section1['text'], section2['text']),
    isHidden
  };
  if (section1['design']) {
    sectionToAdd.design = section1['design'];
  } else if (section2['design']) {
    sectionToAdd.design = section2['design'];
  }
  return sectionToAdd;
};

const join2Sections = (section1, section2, newId) => {
  let newSection = [];
  if (section1.type === 'content' && section2.type === 'content') {
    newSection.push(joinTextSections(section1, section2, newId));
  } else {
    newSection = [section1, section2];
  }
  return newSection;
};

export const joinGridColumns = columns => {
  let joinedSections = [];

  columns.forEach(column => {
    if (joinedSections.length === 0) {
      joinedSections = column;
    } else {
      joinedSections = join2SectionArrays(joinedSections, column, column[0].id);
    }
  });
  let finalSections = joinedSections.map(section => {
    if (section.design && section.design.backgroundColor === 'transparent') {
      delete section.design.backgroundColor;
    }
    return section;
  });
  return finalSections;
};

export const findNodeById = (sections: Object[], targetId: number) => {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].id === targetId) {
      const anchorSources = sections[i].anchorSources || [];
      return {
        index: i,
        anchorSources: [...anchorSources]
      };
    }
  }

  return {};
};

const updateRows = (rows, rowIndex, operation) => {
  const updatedRows = [...rows];
  if ('add' === operation) {
    const emptyRow = [];
    for (let i = 0; i < rows[0].length; i++) {
      const emptyCell = {
        type: 'content',
        text: ''
      };
      if (rows[0][i].isColHighlighted) {
        emptyCell.isColHighlighted = true;
      }
      emptyRow.push(emptyCell);
    }
    updatedRows.splice(rowIndex, 0, emptyRow);
  } else {
    updatedRows.splice(rowIndex, 1);
  }
  return updatedRows;
};

const updateColumns = (rows, colIndex, operation) => {
  const updatedRows = rows.map(rowData => {
    const row = [...rowData];
    if ('add' === operation) {
      const emptyCol = { type: 'content', text: '' };
      if (row[0].isRowHighlighted) {
        emptyCol.isRowHighlighted = true;
      }
      row.splice(colIndex, 0, emptyCol);
    } else {
      row.splice(colIndex, 1);
    }
    return row;
  });
  return updatedRows;
};

export const alterTable = ({
  subject,
  sections,
  index,
  rowIndex,
  colIndex,
  operation
}) => {
  if (index.indexOf('-') > -1) {
    const [gridIndex, columnIndex, sectionIndex] = index.split('-');
    const section = sections.slice(gridIndex, parseInt(gridIndex) + 1)[0];
    const sectionAttributes = section['columns'][columnIndex][sectionIndex];

    if ('delete' === operation) {
      if (
        'rows' === subject &&
        rowIndex === '0' &&
        sectionAttributes.hasRowHeader
      ) {
        sectionAttributes.hasRowHeader = false;
      } else if (
        'cols' === subject &&
        colIndex === '0' &&
        sectionAttributes.hasColumnHeader
      ) {
        sectionAttributes.hasColumnHeader = false;
      }
    }

    const rows =
      'rows' === subject
        ? updateRows(sectionAttributes.rows, rowIndex, operation)
        : updateColumns(sectionAttributes.rows, colIndex, operation);
    section['columns'][columnIndex][sectionIndex] = {
      ...sectionAttributes,
      rows
    };

    return { index: gridIndex, section };
  } else {
    const sectionAttributes = sections[index];
    const rows =
      'rows' === subject
        ? updateRows(sectionAttributes.rows, rowIndex, operation)
        : updateColumns(sectionAttributes.rows, colIndex, operation);
    const section = {
      ...sectionAttributes,
      rows
    };

    if ('delete' === operation) {
      if ('rows' === subject && rowIndex === '0' && section.hasRowHeader) {
        section.hasRowHeader = false;
      } else if (
        'cols' === subject &&
        colIndex === '0' &&
        section.hasColumnHeader
      ) {
        section.hasColumnHeader = false;
      }
    }

    return { index, section };
  }
};

export const changeTableAlignment = (rows, justify) =>
  rows.map(row => row.map(node => ({ ...node, justify })));

export const editTableColumn = (rows, colIndex, modifiedData) => {
  return rows.map(row =>
    row.map((node, i) => {
      return i.toString() === colIndex ? { ...node, ...modifiedData } : node;
    })
  );
};

export const editTableRow = (rows, rowIndex, modifiedData) => {
  const updatedRow = rows[rowIndex].map(node => ({ ...node, ...modifiedData }));
  rows.splice(rowIndex, 1, updatedRow);
  return rows;
};

export const resetTableHeaderFormatting = (headerType, sectionAttributes) => {
  let rows = [...sectionAttributes.rows];

  if ('row' === headerType) {
    const updatedFirstRow = rows[0].map(node => {
      const cellData = { ...node };
      delete cellData.isHighlighted;
      return cellData;
    });
    rows.splice(0, 1, updatedFirstRow);
  } else {
    rows = sectionAttributes.rows.map(rowData => {
      const row = [...rowData];
      const cellData = { ...row[0] };
      delete cellData.isHighlighted;
      row[0] = cellData;
      return row;
    });
  }

  return {
    ...sectionAttributes,
    rows
  };
};
