let CSS_VERSION = '';
if (process.env.REACT_APP_ENV !== 'development') {
  CSS_VERSION = process.env.REACT_APP_cssVersion.substr(0, 10);
} else {
  const date = new Date();
  CSS_VERSION = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}`;
}

const addCSS = path => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = path;
  document.head.appendChild(link);
};

export const loadCss = (alfaEditor = true) => {
  const cssPath1 =
    'https://img.weblogssl.com/css/diariodelviajero/skin-admin-writeboard/main.css';
  const cssPath2 =
    'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css';
  const extraParameters = '?v=' + CSS_VERSION;

  if (alfaEditor) {
    addCSS(cssPath1 + extraParameters);
  } else {
    addCSS(cssPath2 + extraParameters);
    addCSS('/css/simplemde.min.css');
    addCSS(
      'https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css'
    );
  }
};

const loadCloudinaryJS = () => {
  const script = document.createElement('script');
  script.src = '//widget.cloudinary.com/global/all.js';
  script.async = true;
  document.body.appendChild(script);
};

export const loadLfeResources = () => {
  loadCloudinaryJS();
  loadCss(false);
};
