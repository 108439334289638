// @flow
import * as React from 'react';
import MaterialDivider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import styles from './Divider.module.css';

const grey400 = grey[400];

type Props = {
  type?: string,
  fullWidth?: boolean,
};

const style = {
  faded: {
    backgroundColor: grey400,
  },
};

export const Divider = ({ type = '', ...restProps }: Props): React.Node => {
  switch (type) {
    case 'faded':
      // $FlowFixMe: suppressing this error until we can refactor
      return <MaterialDivider style={style.faded} {...restProps} />;
    default:
      return <MaterialDivider {...restProps} />;
  }
};

export const FullWidthDivider = ({ ...restProps }: Props): React.Node => {
  // $FlowFixMe: suppressing this error until we can refactor
  return <Divider className={styles.fullWidthDivider} {...restProps} />;
};
