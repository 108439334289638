import React from 'react';
import {
  createInlineStyleButton,
  createBlockStyleButton,
} from '@draft-js-plugins/buttons';
import { AnchorIcon } from 'components/Editor/Common/CustomSVGIcons';
import styles from './TextToolbarButtons.module.css';

export const StrikeThrough = createInlineStyleButton({
  style: 'STRIKETHROUGH',
  children: (
    <React.Fragment>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="14"
        viewBox="0 0 17 14"
      >
        <defs>
          <path
            id="toolbar-strike-a"
            d="M22.5333333,31 L26.2666667,31 L26.2666667,28.2 L22.5333333,28.2 L22.5333333,31 L22.5333333,31 Z M17.8666667,17 L17.8666667,19.8 L22.5333333,19.8 L22.5333333,22.6 L26.2666667,22.6 L26.2666667,19.8 L30.9333333,19.8 L30.9333333,17 L17.8666667,17 L17.8666667,17 Z M16,26.3333333 L32.8,26.3333333 L32.8,24.4666667 L16,24.4666667 L16,26.3333333 L16,26.3333333 Z"
          />
        </defs>
        <g transform="translate(-16 -17)">
          <use xlinkHref="#toolbar-strike-a" />
        </g>
      </svg>
      <span className={styles.toolTiptext}>Tachado [Ctrl + K]</span>
    </React.Fragment>
  ),
});

export const BoldButton = createInlineStyleButton({
  style: 'BOLD',
  children: (
    <React.Fragment>
      {React.createElement(
        'svg',
        {
          height: '24',
          viewBox: '0 0 24 24',
          width: '24',
          xmlns: 'http://www.w3.org/2000/svg',
        },
        React.createElement('path', {
          d: 'M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4H7v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zM10 6.5h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9H10v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z',
        }),
        React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' })
      )}
      <span className={styles.toolTiptext}>Negrita [Ctrl + B]</span>
    </React.Fragment>
  ),
});

export const ItalicButton = createInlineStyleButton({
  style: 'ITALIC',
  children: (
    <React.Fragment>
      {React.createElement(
        'svg',
        {
          height: '24',
          viewBox: '0 0 24 24',
          width: '24',
          xmlns: 'http://www.w3.org/2000/svg',
        },
        React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' }),
        React.createElement('path', {
          d: 'M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4z',
        })
      )}
      <span className={styles.toolTiptext}>Cursiva [Ctrl + i]</span>
    </React.Fragment>
  ),
});

export const HeadlineTwoButton = createBlockStyleButton({
  blockType: 'header-two',
  children: (
    <React.Fragment>
      H2
      <span className={styles.toolTiptext}>H2 [Ctrl + 2]</span>
    </React.Fragment>
  ),
});

export const HeadlineThreeButton = createBlockStyleButton({
  blockType: 'header-three',
  children: (
    <React.Fragment>
      H3
      <span className={styles.toolTiptext}>H3 [Ctrl + 3]</span>
    </React.Fragment>
  ),
});

export const HeadlineFourButton = createBlockStyleButton({
  blockType: 'header-four',
  children: (
    <React.Fragment>
      H4
      <span className={styles.toolTiptext}>H2 [Ctrl + 4]</span>
    </React.Fragment>
  ),
});

export const BlockquoteButton = createBlockStyleButton({
  blockType: 'blockquote',
  children: (
    <React.Fragment>
      {React.createElement(
        'svg',
        {
          height: '24',
          viewBox: '0 0 24 24',
          width: '24',
          xmlns: 'http://www.w3.org/2000/svg',
        },
        React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' }),
        React.createElement('path', {
          d: 'M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z',
        })
      )}
      <span className={styles.toolTiptext}>Texto citado</span>
    </React.Fragment>
  ),
});

export const UnorderedListButton = createBlockStyleButton({
  blockType: 'unordered-list-item',
  children: (
    <React.Fragment>
      {React.createElement(
        'svg',
        {
          height: '24',
          viewBox: '0 0 24 24',
          width: '24',
          xmlns: 'http://www.w3.org/2000/svg',
        },
        React.createElement('path', { d: 'M0 0h24v24H0V0z', fill: 'none' }),
        React.createElement('path', {
          d: 'M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z',
        })
      )}
      <span className={styles.toolTiptext}>Lista desordenada</span>
    </React.Fragment>
  ),
});

export const OrderedListButton = createBlockStyleButton({
  blockType: 'ordered-list-item',
  children: (
    <React.Fragment>
      {React.createElement(
        'svg',
        {
          height: '24',
          viewBox: '0 0 24 24',
          width: '24',
          xmlns: 'http://www.w3.org/2000/svg',
        },
        React.createElement('path', { d: 'M0 0h24v24H0z', fill: 'none' }),
        React.createElement('path', {
          d: 'M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z',
        })
      )}
      <span className={styles.toolTiptext}>Lista ordenada</span>
    </React.Fragment>
  ),
});

export const AnchorButton = createInlineStyleButton({
  children: (
    <React.Fragment>
      <AnchorIcon />
      <span className={styles.toolTiptext}>Ancla</span>
    </React.Fragment>
  ),
});
