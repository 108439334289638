import React from 'react';
import ReactAsyncSelect from 'react-select/async';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ReactSelectStyles, containerStyles } from './ReactSelectStyles';
import { ErrorText, DropdownIndicator, Placeholder } from './helpers';
import type { ValueType } from 'lib/flowTypes';
import styles from './AsyncSelect.module.css';

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        lineHeight: '1 !important',
        position: 'absolute',
        top: '0',
      },
    },
  },
});

type Props = {
  placeholder: string,
  value?: ValueType,
  loadOptions: Function,
  onChange: Function,
  noOptionsMessage: Function,
  errorText?: string,
  isMulti?: boolean,
  isClearable?: boolean,
  hasError?: boolean,
};

export const AsyncSelect = ({
  placeholder,
  value,
  loadOptions,
  onChange,
  noOptionsMessage,
  errorText,
  isMulti = true,
  isClearable = false,
  hasError = false,
}: Props) => {
  const selectClassName =
    errorText || hasError ? styles.fieldError : styles.select;

  return (
    <div style={containerStyles} className={selectClassName}>
      <MuiThemeProvider theme={theme}>
        <Placeholder text={placeholder} />
      </MuiThemeProvider>
      <ReactAsyncSelect
        placeholder="..."
        defaultOptions
        cacheOptions
        isMulti={isMulti}
        getOptionValue={({ id }) => id}
        loadOptions={loadOptions}
        onChange={onChange}
        value={value}
        noOptionsMessage={noOptionsMessage}
        styles={ReactSelectStyles}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        isClearable={isClearable}
      />
      <ErrorText text={errorText} />
    </div>
  );
};
