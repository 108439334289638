import { EditorState, ContentState } from 'draft-js';
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';

export const BOLD = 'bold';
export const ITALIC = 'italic';
export const LINE_THROUGH = 'line-through';
export const HEADER_TWO = 'header-two';
export const HEADER_THREE = 'header-three';
export const HEADER_FOUR = 'header-four';
export const BLOCKQUOTE = 'blockquote';
export const UNORDERED_LIST = 'unordered-list';
export const ORDERED_LIST = 'ordered-list';
export const LINK = 'link';
export const SUMMARY = 'summary';
export const HIGHLIGHT = 'highlight';
export const ANCHOR = 'anchor';

export const TOOLBAR_OPTIONS = [
  BOLD,
  ITALIC,
  LINE_THROUGH,
  HEADER_TWO,
  HEADER_THREE,
  BLOCKQUOTE,
  UNORDERED_LIST,
  ORDERED_LIST,
  LINK,
  SUMMARY
];

export const TOOLBAR_LANDING = [
  BOLD,
  ITALIC,
  LINE_THROUGH,
  HEADER_TWO,
  HEADER_THREE,
  HEADER_FOUR,
  BLOCKQUOTE,
  UNORDERED_LIST,
  ORDERED_LIST,
  LINK,
  SUMMARY,
  ANCHOR
];

export const initializeEditor = text => {
  let editorState = null;
  if (isEmpty(text)) {
    editorState = EditorState.createEmpty();
  } else {
    const processedHTML = DraftPasteProcessor.processHTML(text);
    const contentState = ContentState.createFromBlockArray(processedHTML);
    editorState = EditorState.createWithContent(contentState);
  }
  return editorState;
};

export const isEmpty = htmlString => {
  let plainText = htmlString.replace(/<[^>]+>/g, '');
  plainText = plainText.replace(/[\r\n]+/g, '');
  plainText = plainText.replace(/(&nbsp;)+/g, '');
  return !plainText;
};
