// @flow
import type { Node } from 'React';
import React, { PureComponent, Fragment } from 'react';
import { Grid } from 'components/Editor/Common/Grid/Grid';

import { uploadImage } from './lib/helpers';
import { Errors, UrlUploader, FileUploader } from '.';

const STATUS_FAILURE = 'failure';
const STATUS_SUCCESS = 'success';

type Props = {
  id: string,
  site: string,
  isMobile: boolean,
  isUploading: boolean,
  startUpload: () => void,
  endUpload: () => void,
  type: 'post' | 'product',
  mode?: string,
  erroredFiles?: {
    invalidDimensionFile: string,
    invalidSizeFiles: Array<String>,
  },
};

type State = {
  url: string,
  urlError: string,
  erroredFiles: Object,
};

export class Uploader extends PureComponent<Props, State> {
  state: State = {
    url: '',
    urlError: '',
    erroredFiles: {},
  };

  componentDidUpdate(prevProps: Props) {
    const {
      erroredFiles = { invalidDimensionFile: '', invalidSizeFiles: [] },
    } = this.props;
    const {
      erroredFiles: previousErrorFiles = {
        invalidDimensionFile: '',
        invalidSizeFiles: [],
      },
    } = prevProps;
    if (
      (prevProps.isUploading === false && this.props.isUploading === true) ||
      erroredFiles.invalidDimensionFile !==
        previousErrorFiles.invalidDimensionFile ||
      erroredFiles.invalidSizeFiles.length !==
        previousErrorFiles.invalidSizeFiles.length
    ) {
      this.setState({
        url: '',
        urlError: '',
        erroredFiles,
      });
    }
  }

  uploadFileToDb = async (file: Object) => {
    const { id, site, type } = this.props;
    const { data, name, size } = file;

    try {
      const response = await uploadImage({ id, site, data, type, size });
      if (response.status !== 200) {
        return { name, status: STATUS_FAILURE };
      }
    } catch (err) {
      return { name, status: STATUS_FAILURE };
    }

    return { name, status: STATUS_SUCCESS };
  };

  uploadFiles = async (files: Object[]) => {
    const { startUpload, endUpload } = this.props;
    let failedFiles = [];
    let promises = [];

    startUpload();

    for (let file of files) {
      const promise = () => {
        return this.uploadFileToDb(file);
      };
      promises.push(promise());
    }

    const values = await Promise.all(promises);
    values.forEach((value) => {
      if (STATUS_FAILURE === value.status) {
        failedFiles.push(value.name);
      }
    });

    endUpload();

    return failedFiles;
  };

  setUrlUploadError: (urlError: string) => void = (urlError: string) =>
    this.setState({ urlError });

  setFileUploadErrors: (erroredFiles: any) => void = (erroredFiles: Object) =>
    this.setState({ erroredFiles });

  resetErrors: () => void = () =>
    this.setState({ erroredFiles: {}, urlError: '' });

  handleUrlChange: (url: string) => void = (url: string) =>
    this.setState({ url: url.trim(), urlError: '' });

  render(): Node {
    const { url, urlError, erroredFiles } = this.state;
    const { isUploading, isMobile } = this.props;

    return (
      <Fragment>
        {Object.keys(erroredFiles).length > 0 && (
          <Errors erroredFiles={erroredFiles} mode={this.props.mode} />
        )}
        <Grid container gutter="custom" spacing={2}>
          <FileUploader
            isUploading={isUploading}
            isMobile={isMobile}
            setFileUploadErrors={this.setFileUploadErrors}
            resetErrors={this.resetErrors}
            uploadFiles={this.uploadFiles}
            mode={this.props.mode}
          />
          <UrlUploader
            url={url}
            urlError={urlError}
            isUploading={isUploading}
            resetErrors={this.resetErrors}
            setUrlUploadError={this.setUrlUploadError}
            handleUrlChange={this.handleUrlChange}
            uploadFiles={this.uploadFiles}
          />
        </Grid>
      </Fragment>
    );
  }
}
