import * as React from 'react';
import MuiGrid from '@material-ui/core/Grid';
import { isMobile } from 'lib/deviceDetect';

type Props = {
  container?: boolean,
  gutter?: string,
  spacing?: number,
  justify?: string,
  container?: boolean,
  children: React.Node,
};

export const Grid = ({
  children,
  gutter = 'default',
  justify = 'flex-start',
  ...props
}: Props) => {
  if (props.container) {
    let spacing = isMobile() ? 3 : 6;

    if (gutter === 'dense') {
      spacing = isMobile() ? 3 : 1;
    } else if (gutter === 'custom') {
      spacing = props.spacing;
    }

    props.spacing = spacing;
    props.justifyContent = justify;
  }

  return <MuiGrid {...props}>{children}</MuiGrid>;
};
