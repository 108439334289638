//@flow
import type { Node as $IMPORTED_TYPE$_Node } from 'React';
import React from 'react';
import { ClickAwayListener as MuiClickAwayListener } from '@material-ui/core';

type Props = {
  children: any,
};

export const ClickAwayListener = ({
  children,
  ...props
}: Props): $IMPORTED_TYPE$_Node => {
  return <MuiClickAwayListener {...props}>{children}</MuiClickAwayListener>;
};
