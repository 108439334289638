// @flow
import React, { useState } from 'react';
import type { Node } from 'React';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import styles from './RichContentEditor.module.css';

import { ModalLabel } from '..';

type Props = {
  text: string,
  handleChange: (text: string) => void,
  label: Node,
  collapseOnBlur: boolean,
  placeholder?: string,
};

export const RichContentEditor = ({
  text = '',
  handleChange,
  label,
  collapseOnBlur = false,
  placeholder = "<iframe id='audio_26402304' allowfullscreen='' scrolling='no' height='200' style='border:1px solid #EEE; box-sizing:border-box; width:100%;' src='https://www.ivoox.com/player_ej_26402304_4_1.html?c1=128c3b'></iframe>",
}: Props) => {
  const [focussed, setFocussed] = useState(null);
  const collapsedClass = collapseOnBlur && !focussed ? styles.collapsed : '';
  return (
    <div className={styles.wrapper}>
      <ModalLabel label={label} />
      <div className={styles.EditorContainer + ' ' + collapsedClass}>
        <Editor
          onFocus={() => setFocussed(true)}
          onBlur={() => setFocussed(false)}
          value={text}
          onValueChange={handleChange}
          highlight={(text) => highlight(text, languages.js)}
          className={styles.editor}
          textareaId="rcTextarea"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};
