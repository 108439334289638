// @flow
import type { Node } from 'React';
import React, { useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';

import { CircularLoader, Dialog, Error } from 'components/Editor/Common';
import ReactCrop from 'react-image-crop';
import { cancelLabel } from 'components/Editor/Escribir/lib/helpers';
import styles from './ImageCropper.module.css';
import {
  getImageUrl,
  getCroppedImg,
  getImageType,
  getInitialCrop,
} from 'lib/helpers';
import { postImage } from 'lib/s3ImageUploadService';

import 'components/Editor/Common/ReactCrop.css';

type Props = {
  open: boolean,
  mode: string,
  siteName: string,
  imageToEmbed: Object,
  openModal: (name: string, mode: string, data: {}) => void,
  closeModal: () => void,
  prevModalData: {
    name: string,
    mode: string,
    section: Object,
    imageToEmbed?: Object
  },
};

let imageRef;
const ImageCropper = ({
  open,
  siteName,
  mode,
  imageToEmbed,
  closeModal,
  openModal,
  prevModalData,
}: Props): Node => {
  if (!imageToEmbed?.src) {
    imageToEmbed = prevModalData.imageToEmbed || {};
  }
  const [crop, setCrop] = useState(() => {
    const initialCrop = getInitialCrop(
      imageToEmbed.width / imageToEmbed.height
    );
    const { name = '' } = prevModalData;
    if (
      mode === 'headerImage' ||
      'pivotEcommerce' === name ||
      'fichaCompraV2' === name
    ) {
      const { aspect, validate, ...firstCrop } = initialCrop['square'];
      return firstCrop;
    }
    if (mode === 'fichaImage') {
      return initialCrop['panoramic'];
    }
    return initialCrop['golden'];
  });
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');

  const imageUrl = getImageUrl(imageToEmbed);

  const goBack = () => {
    openModal('imagePanel', mode, {
      ...prevModalData,
    });
  };

  const handleSubmit = async () => {
    try {
      const imageType = getImageType(imageToEmbed.extension);
      const imageBase64 = getCroppedImg(imageRef, crop, imageType);
      if (imageBase64) {
        setIsUploading(true);
        const blobBin = atob(imageBase64.split(',')[1]);
        const array = [];
        for (let i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        const file = new Blob([new Uint8Array(array)], { type: imageType });
        const formdata = new FormData();
        formdata.append('file', file);
        const image = await postImage(siteName, formdata);

        setIsUploading(false);
        if (prevModalData.name === 'gameReview') {
          openModal(prevModalData.name, prevModalData.mode, {
            section: {
              ...prevModalData.section,
              [mode]: image,
            },
          });
        } else if (
          ['pivotEcommerce', 'fichaCompraV2'].includes(prevModalData.name)
        ) {
          openModal(prevModalData.name, prevModalData.mode, {
            section: {
              ...prevModalData.section,
              productImage: image,
            },
          });
        } else {
          closeModal();
        }
      }
    } catch (e) {
      setError(e.message);
      setIsUploading(false);
    }
  };

  const handleClose = () => {
    if (prevModalData.hasOwnProperty('name') && '' !== prevModalData.name) {
      const { name, mode } = prevModalData;
      openModal(name, mode, prevModalData);
    } else {
      closeModal();
    }
  };

  const onImageLoaded = (e) => {
    imageRef = e.currentTarget;
  };

  let extraProps = {};
  if (['pivotEcommerce', 'fichaCompraV2'].includes(prevModalData.name)) {
    extraProps = {
      aspect: 1,
    };
  }

  return (
    <Dialog
      open={open}
      title="Ajuste de imágenes"
      titleHint="Acerca el recorte a la imagen del producto todo lo posible"
      onClose={handleClose}
      size="large"
      spacing="zero"
      cancelLabel={cancelLabel('ELEGIR OTRA')}
      onCancel={goBack}
      submitLabel="Usar imagen"
      onSubmit={handleSubmit}
    >
      {error && <Error message={error} />}
      {isUploading && <CircularLoader show />}
      <div className={styles.cropper}>
        <ReactCrop
          crop={{ ...crop }}
          keepSelection
          onChange={(crop, percentCrop) => setCrop(percentCrop)}
          imageStyle={{ maxHeight: 'inherit' }}
          {...extraProps}
        >
          <img
            src={imageUrl + '?v'} // to avoid fetching cached image without crossorigin headers
            onLoad={onImageLoaded}
            crossOrigin="anonymous"
            alt=""
          />
        </ReactCrop>
      </div>
    </Dialog>
  );
};

export default ImageCropper;
