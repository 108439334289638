// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import MuiSnackbar from '@material-ui/core/Snackbar';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import { closeSnackbar } from 'actions/common';
import { InfoIcon, CloseIcon, CheckIcon } from 'components/Editor/Common/Icons';
import { WarningIcon } from 'components/Editor/Common/CustomSVGIcons';
import styles from './Snackbar.module.css';

type Props = {
  message?: React.Node,
  messageType?: 'info' | 'error' | 'success' | 'warning' | 'undo',
  iconType?: 'info' | 'error' | 'success' | 'warning',
  horizontal?: 'left' | 'center',
  duration?: number,
  size?: 'normal' | 'large' | 'medium' | 'extra-large',
  closeSnackbar?: Function,
  action?: React.Node,
  showIcon?: boolean,
  actionColor?: 'white',
  layout?: 'column',
};

let theme = createTheme({
  overrides: {
    MuiSnackbarContent: {
      message: {
        display: 'flex',
        alignItems: 'flex-start',
        fontSize: '15px',
        lineHeight: '20px',
        padding: '6px 14px 6px 6px',

        '@media(min-width: 1280px)': {
          whiteSpace: 'normal',
        },
      },
      action: {
        paddingLeft: '12px',
        margin: '2px 0 0 0',
        marginRight: '0',
        flexShrink: '0',
      },
    },

    MuiSvgIcon: {
      root: {
        marginRight: '6px',
        marginTop: '2px !important',
      },
    },
  },
});

theme.overrides.MuiSnackbar = {
  root: {
    zIndex: '1300',
    background: 'transparent !important',
  },

  anchorOriginTopLeft: {
    top: 80,
    [theme.breakpoints.up('md')]: {
      top: 80,
    },
  },

  anchorOriginTopCenter: {
    top: '112px !important',

    '@media(min-width: 900px)': {
      top: '58px !important',
    },

    '@media(min-width: 960px)': {
      top: '72px !important',
    },

    '@media(max-width: 767px)': {
      width: 'auto',
    },
  },
};

const errorColor = '#C52100';
const infoColor = '#546E7A';
const successColor = '#388E3C';
const warningColor = '#FFD54F';
const primaryColor = '#323232';

const onRequestClose = (reason, closeSnackbar) => {
  if (reason !== 'clickaway' && closeSnackbar) {
    closeSnackbar();
  }
};

export const Snackbar = ({
  messageType = 'info',
  iconType = '',
  message,
  horizontal = 'center',
  duration = 6000,
  size = 'normal',
  closeSnackbar,
  action = null,
  showIcon = true,
  layout = '',
  actionColor = '',
}: Props): React.Node => {
  let backgroundColor = '';
  let Icon = InfoIcon;
  let color = '#FFFFFF';
  const classNames = [];
  if (messageType === 'error') {
    backgroundColor = errorColor;
  } else if (messageType === 'warning') {
    backgroundColor = warningColor;
    Icon = WarningIcon;
    color = 'RGBA(0, 0, 0, 0.87)';
  } else if (messageType === 'success') {
    backgroundColor = successColor;
    Icon = CheckIcon;
  } else if (messageType === 'undo') {
    backgroundColor = primaryColor;
    classNames.push(styles.delete);
  } else {
    backgroundColor = infoColor;
    classNames.push(styles.info);
  }

  if (actionColor === 'white') {
    classNames.push(styles.white);
  }

  if ('info' === iconType) {
    Icon = InfoIcon;
  } else if ('success' === iconType) {
    Icon = CheckIcon;
  } else if ('warning' === iconType) {
    Icon = WarningIcon;
  }

  if ('extra-large' === size) {
    classNames.push(styles.extraLarge);
  }

  if ('large' === size) {
    classNames.push(styles.large);
  }

  if ('medium' === size) {
    classNames.push(styles.medium);
  }

  if ('column' === layout) {
    classNames.push(styles.column);
  }

  theme.overrides.MuiSnackbarContent.root = {
    backgroundColor,
    color,
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    minWidth: '0',
    boxSizing: 'border-box',
    padding: '10px',
    width: '100%',

    '@media(min-width: 600px)': {
      minWidth: '292px',
    },
  };

  let snackbarAction = action;
  if (closeSnackbar) {
    const closeAction = (
      <div className={styles.iconMargin}>
        <CloseIcon key="close" fontSize="small" onClick={closeSnackbar} />
      </div>
    );
    if ('undo' === messageType || 'info' === messageType) {
      snackbarAction = (
        <>
          {action}
          {closeAction}
        </>
      );
    } else {
      snackbarAction = closeAction;
    }
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiSnackbar
        open={!!message}
        message={
          <>
            {showIcon && <Icon fontSize="inherit" />}
            {message}
          </>
        }
        anchorOrigin={{ vertical: 'top', horizontal }}
        onClose={(event, reason) => onRequestClose(reason, closeSnackbar)}
        autoHideDuration={duration === 0 ? null : duration}
        action={snackbarAction}
        className={classNames.join(' ')}
        disableWindowBlurListener
      />
    </MuiThemeProvider>
  );
};

const mapStateToProps = ({
  common: { message, messageType, iconType = '' },
}) => {
  return {
    message,
    messageType,
    iconType,
  };
};
export default (connect(mapStateToProps, { closeSnackbar })(Snackbar): any);
