import { combineReducers } from 'redux';
import images from './imageReducer';
import configs from './Configs';
import post from './postReducer';
import sections from './sectionReducer';
import modal from './ModalReducer';
import errors from './errorReducer';
import common from './commonReducer';

const rootReducer = combineReducers({
  images,
  configs,
  post,
  sections,
  modal,
  errors,
  common,
});

export default rootReducer;
