//@flow
import type { Node as $IMPORTED_TYPE$_Node } from 'React';
import React from 'react';
import type { Node } from 'react';
import MuiTab from '@material-ui/core/Tab';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

type Props = {
  children: Node,
};

const theme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        minWidth: '0',
        padding: '18px 12px 14px',
        fontSize: '14px',
        lineHeight: '16px',
        margin: '0 4px',
        fontWeight: '500',
        minHeight: '0',
        color: '#a4a4a4 !important',

        '&.Mui-selected': {
          color: 'inherit !important',
        },

        '@media(min-width:600px)': {
          minWidth: '0',
        },
      },
    },
  },
});

export const Tab = ({ children, ...props }: Props): $IMPORTED_TYPE$_Node => {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <MuiTab {...props}>{children}</MuiTab>
      </MuiThemeProvider>
    </>
  );
};
