// @flow
import { DropTarget } from 'react-dnd';
import React from 'react';
import styles from './DropTargetBox.module.css';

type Props = {
  content: any,
  accepts: string[],
  onDrop: () => void,
  isOverCurrent: boolean,
  connectDropTarget: (target: any) => void,
};

const boxTarget = {
  drop(props, monitor) {
    if (props.onDrop) {
      props.onDrop(props, monitor);
    }
  },
};

function DropTargetBox({ content, connectDropTarget, isOverCurrent }: Props) {
  let renderedContent = content;
  if (isOverCurrent) {
    renderedContent = <div className={styles.hover}>{content}</div>;
  }
  return connectDropTarget(renderedContent);
}

export default (DropTarget(
  (props) => props.accepts,
  boxTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOverCurrent: monitor.isOver({ shallow: true }),
  })
)(DropTargetBox): any);
