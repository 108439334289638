//@flow
import React from 'react';

import { layoutMapping, getLayoutOptions } from './lib/helpers';

type Props = {
  changeLayout: (layout: string, align: string) => void,
  selectedKey: string,
  type: string,
  postType: string,
  index: string,
  subType?: string,
  disabledOptions?: string[],
  disabledTooltip?: string,
};

export const LayoutBar = ({
  changeLayout,
  selectedKey,
  type,
  postType,
  index,
  subType = '',
  disabledOptions = [],
  disabledTooltip,
}: Props): Array<any> => {
  const isTableCell = index && index.indexOf(':') > -1;
  const isColumnNode = index && index.indexOf('-') > -1;
  let layoutOptions = [];
  if (!isTableCell) {
    layoutOptions = getLayoutOptions(type, postType, subType);
  }
  let i = 1,
    Toolbar = [];

  for (let layoutOption of layoutOptions) {
    let { toolbar: MyToolbar, align, layout } = layoutMapping[layoutOption];
    const isLayoutDisabled = disabledOptions.includes(layout);
    Toolbar.push(
      <MyToolbar
        key={i}
        handleClick={() => changeLayout(layout, align)}
        selectedKey={isColumnNode ? '' : selectedKey}
        disabled={isColumnNode || isLayoutDisabled}
        tooltip={isLayoutDisabled ? disabledTooltip : undefined}
      />
    );
    i++;
  }
  return Toolbar;
};
