// @flow
import type { Node } from 'React';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { handleTocWarning } from 'actions/common';
import { Tabs, Tab } from 'components/Editor/Common';
import { InputEvent } from 'lib/flowTypes';
import { getH2Count } from 'lib/helpers';

import styles from './MenuTabs.module.css';

type Props = {
  activeTab: string,
  postName: string,
  navigate: Object,
  showDifundir: boolean,
  postType: string,
  hasErrors: boolean,
  showErrors: () => void,
  handleErrors: () => void,
  setAnchorSource: Function,
  moduleName: string,
  setShowErrors: (show: boolean) => void,
  sections: Array<Object>,
  isTocExists: boolean,
  handleTocWarning: (show: boolean) => void,
  setIsUnsavedChanges: (data: boolean) => void,
  setShowMessage: (data: boolean) => void,
};

class MenuTabs extends PureComponent<Props> {
  handleChange: (event: any, route: string) => void = (
    event: InputEvent,
    route: string
  ) => {
    const {
      activeTab,
      postName,
      hasErrors,
      handleErrors,
      navigate,
      setAnchorSource,
      setShowErrors,
      sections,
      isTocExists,
      handleTocWarning,
      setIsUnsavedChanges,
      setShowMessage,
    } = this.props;
    if ('backup' === route) {
      setIsUnsavedChanges(false);
      return navigate(`/${route}/${postName}`);
    } else if ('backup' === activeTab && hasErrors) {
      navigate(`/escribir/${postName}`);
      handleErrors();
    } else if ('escribir' === activeTab && hasErrors) {
      handleErrors();
    } else {
      if ('escribir' === activeTab && isTocExists && 2 > getH2Count(sections)) {
        handleTocWarning(true);
        return;
      }
      setShowErrors(false);
      setAnchorSource(null, null);
      if (activeTab === 'ficha' || activeTab === 'producto') {
        return navigate(`/${route}/${postName}`);
      }
      setIsUnsavedChanges(false);
      setShowMessage(false);
      navigate(`/${route}/${postName}`);
    }
  };

  getTabContent: () => Node = () => {
    const { showDifundir, moduleName, activeTab } = this.props;

    if ('createProduct' === moduleName) {
      return (
        <div className={styles.centered}>
          <Tabs value={activeTab} onChange={this.handleChange} centered>
            <Tab label="PRODUCTO" value="producto" />
            <Tab label="FICHA" value="ficha" />
          </Tabs>
        </div>
      );
    }

    return (
      <div className={styles.overflowTabs}>
        <Tabs value={activeTab} onChange={this.handleChange} centered>
          <Tab label={'CONTENIDO'} value={'escribir'} />
          <Tab label={'Herramientas'} value={'backup'} />
          <Tab label={'PUBLICACIÓN'} value={'publicar'} />
          {showDifundir && <Tab label={'DIFUSIÓN'} value={'difundir'} />}
        </Tabs>
      </div>
    );
  };

  render(): Node {
    return this.getTabContent();
  }
}
const mapStateToProps = (state) => {
  const isTocExists = state.post.isTocExists || false;
  return {
    isTocExists,
    sections: isTocExists ? state.sections : [],
  };
};

export default connect(mapStateToProps, {
  handleTocWarning,
})(MenuTabs);
