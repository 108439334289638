import * as React from 'react';
import Rollbar from 'rollbar';

import config from 'config/configs';

type Props = {
  children: React.Node
};
type State = {
  hasError: boolean,
  error: ?any
};

export const RollbarContext = React.createContext();

export default class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  state = {
    hasError: false,
    error: null,
    rollbar: new Rollbar({
      accessToken: config.rollbarToken,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process.env.REACT_APP_ENV
      }
    })
  };

  logInfo = (error: any) => {
    this.state.rollbar.info(error);
  };

  render() {
    if (this.state.hasError) {
      return (
        <div>
          Please send this screenshot with your ticket:-{' '}
          {this.state.error.message}
          <pre>{this.state.error.stack}</pre>
        </div>
      );
    }

    return (
      <RollbarContext.Provider value={{ logInfo: this.logInfo }}>
        {this.props.children}
      </RollbarContext.Provider>
    );
  }
}
