import {
  CLOSE_SNACKBAR,
  SHOW_SNACKBAR,
  TOGGLE_DESIGN_MODE,
  OPEN_TOOLBAR,
  CHANGE_CURRENT_INDEX,
  CHANGE_POSITION,
  ENABLE_DESIGN_MODE_ACCESS,
  SET_ANCHOR_SOURCE,
  TOGGLE_DELINK_WARNING,
  SHOW_TOC_WARNING,
} from 'actions/common';
import {
  DELETE_SECTION,
  DELETE_GRID_SECTION,
  CANCEL_DELETE,
  CONFIRM_DELETE,
  SHOW_REINDEX_CHANGES_WARNING,
  HIDE_REINDEX_CHANGES_WARNING,
} from 'actions/post';

const initialState = {
  showSnackbar: false,
  isDesignMode: false,
  isDesignModeAccessible: false,
  showDelinkConfirmation: false,
  openToolbar: false,
  showReIndexChangesWarning: false,
  showReindexCloseSnackbar: false,
  showTocWarning: false,
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        message: action.message,
        messageType: action.messageType,
        iconType: action.iconType,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        message: null,
        messageType: null,
        showReindexCloseSnackbar: false,
      };
    case TOGGLE_DESIGN_MODE:
      return {
        ...state,
        isDesignMode: !state.isDesignMode,
      };
    case ENABLE_DESIGN_MODE_ACCESS:
      return {
        ...state,
        isDesignModeAccessible: true,
      };
    case CHANGE_POSITION:
      return {
        ...state,
        currentIndex: action.index,
      };
    case CANCEL_DELETE: {
      return {
        ...state,
        openToolbar: false,
        showDeleteConfirmation: false,
      };
    }
    case CONFIRM_DELETE: {
      return {
        ...state,
        showDeleteConfirmation: true,
      };
    }
    case SHOW_REINDEX_CHANGES_WARNING: {
      return {
        ...state,
        showReIndexChangesWarning: true,
        showReindexCloseSnackbar: false,
      };
    }
    case HIDE_REINDEX_CHANGES_WARNING: {
      return {
        ...state,
        showReIndexChangesWarning: false,
        showReindexCloseSnackbar: true,
      };
    }
    case TOGGLE_DELINK_WARNING: {
      return {
        ...state,
        showDelinkConfirmation: !state.showDelinkConfirmation,
      };
    }
    case DELETE_GRID_SECTION:
    case DELETE_SECTION:
      return {
        ...state,
        showDeleteConfirmation: false,
        showTocWarning:
          'tableOfContents' === action.data.sectionType
            ? false
            : state.showTocWarning,
      };
    case CHANGE_CURRENT_INDEX:
      return {
        ...state,
        currentIndex: action.index,
      };
    case OPEN_TOOLBAR:
      return {
        ...state,
        openToolbar: action.open,
        currentIndex:
          action.index !== undefined ? action.index : state.currentIndex,
        anchorEl: action.anchorEl,
        anchorPosition: action.anchorPosition,
      };
    case SET_ANCHOR_SOURCE:
      return {
        ...state,
        anchorSource: action.anchorSource,
        anchorSourceId: action.anchorSourceId,
      };
    case SHOW_TOC_WARNING:
      return {
        ...state,
        showTocWarning: action.showTocWarning,
        openToolbar: false,
        anchorEl: null,
      };
    default:
      return state;
  }
}
