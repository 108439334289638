// @flow
import * as React from 'react';
import style from './Image.module.css';

type Props = {
  size?:
    | 'tiny'
    | 'extraSmall'
    | 'small'
    | 'medium'
    | 'square'
    | 'instagram'
    | 'large'
    | 'extraLarge'
    | 'fullWidth'
    | 'cover'
    | 'coverAbsolute'
    | 'contain'
    | 'preset45'
    | 'preset48',
  shape?: 'circle',
  src: string,
  alt: string,
};

export const Image = ({
  size = 'tiny',
  shape = '',
  src,
  alt,
  ...rest
}: Props): React.Element<'img'> => {
  let classNames = [];
  if ('fullWidth' === size) {
    classNames.push(style.fullWidth);
  } else if ('extraSmall' === size) {
    classNames.push(style.extraSmall);
  } else if ('extraLarge' === size) {
    classNames.push(style.extraLarge);
  } else if ('medium' === size) {
    classNames.push(style.medium);
  } else if ('large' === size) {
    classNames.push(style.large);
  } else if ('small' === size) {
    classNames.push(style.small);
  } else if ('instagram' === size) {
    classNames.push(style.instagram);
  } else if ('square' === size) {
    classNames.push(style.square);
  } else if ('cover' === size) {
    classNames.push(style.cover);
  } else if ('coverAbsolute' === size) {
    classNames.push(style.coverAbsolute);
  } else if ('contain' === size) {
    classNames.push(style.contain);
  } else if ('preset45' === size) {
    classNames.push(style.preset45);
  } else if ('preset48' === size) {
    classNames.push(style.preset48);
  } else {
    classNames.push(style.tiny);
  }

  if ('circle' === shape) {
    classNames.push(style.circle);
  }

  // $FlowFixMe: suppressing this error until we can refactor
  return <img className={classNames.join(' ')} src={src} alt={alt} {...rest} />;
};
