// @flow
import React from 'react';
import { AppBar } from 'components/Editor/Common';
import { Grid } from 'components/Editor/Common/Grid/v2/Grid';
import { getSiteIcon } from 'lib/helpers';
import { H6 } from 'components/Editor/Common/Typography/Typo';
import styles from './TitleBar.module.css';

type Props = {
  siteName: string,
  adminUrl: string,
};

const TitleBar = ({ siteName, adminUrl }: Props) => {
  return (
    <AppBar>
      <Grid
        container
        gutter="custom"
        spacing={0}
        alignItems="flex-end"
        style={{ height: 'inherit' }}
      >
        <Grid item xs={12} style={{ alignSelf: 'center' }}>
          <Grid item>
            <div className={styles.siteLogo}>
              {getSiteIcon(siteName, adminUrl)}

              <H6>{'Crosspost'}</H6>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default TitleBar;
