// @flow
import * as React from 'react';
import indigo from '@material-ui/core/colors/indigo';
import OpenInNew from '@material-ui/icons/CallMade';
import { withStyles } from '@material-ui/core/styles';

import styles from './MouseHoverLink.module.css';
import { AnchorIcon } from 'components/Editor/Common/CustomSVGIcons';

const indigo500 = indigo['500'];

type Props = {
  url?: string,
  showPopOver?: () => void,
  spanStyle?: {},
  anchorSources?: [string, string][],
  isAnchorOrigin?: boolean,
  transformX?: boolean,
};

const style = {
  root: {
    color: indigo500,
    fontSize: 16,
    padding: '6px 2px',
  },
};

const OpenInNewIcon = withStyles(style)(OpenInNew);

export const MouseHoverLink = ({
  showPopOver = () => {},
  url,
  spanStyle = {},
  anchorSources = [],
  isAnchorOrigin = false,
  transformX = true,
}: Props): React.Element<'span'> => {
  const handleClick = (url, fragment, isAnchor) => {
    if (isAnchor) {
      const ele = document.getElementById(fragment);
      if (ele) {
        const { top, left } = ele.getBoundingClientRect();
        window.scrollTo(left + window.scrollX, top + window.scrollY - 70);
      }
    } else {
      window.open(url, 'blank');
    }
  };

  const getLinks = () => {
    if (anchorSources.length) {
      const links = anchorSources.map((source, i) => {
        const [sourceId, text] = source;
        return (
          <span key={i} className={styles.anchorLinkDecorator}>
            <span
              onClick={() => handleClick('', sourceId, true)}
              className={styles.linkHoverInner}
            >
              {isAnchorOrigin && <AnchorIcon />}
              {text || sourceId}
            </span>
            <OpenInNewIcon />
          </span>
        );
      });

      return <span className="">{links}</span>;
    }

    return (
      <>
        <span
          onClick={() => handleClick(url, '', false)}
          className={styles.linkHoverInner}
        >
          {url}
        </span>
        <OpenInNewIcon />
      </>
    );
  };

  const wrapperClasses = [styles.linkDecoratorWrapper];
  if (transformX) {
    wrapperClasses.push(styles.transformX);
  }

  return (
    <span
      readOnly="readonly"
      className={wrapperClasses.join(' ')}
      style={spanStyle}
      onMouseEnter={showPopOver}
    >
      {getLinks()}
    </span>
  );
};
