import React from 'react';

import {
  viewPermissions,
  postTypePermissions,
  MANAGER_ROLES,
  ROLE_COORDINATOR,
  ROLE_FICHA_EDITOR,
  ROLE_EDITOR,
  ROLE_DIRECTOR,
  ROLE_ADMINISTRATOR,
  ROLE_ECOMMERCE_DIRECTOR,
  ROLE_SEO,
} from './constants';
import ErrorBoundary from 'components/Editor/Common/ErrorBoundary';
import memoize from 'memoize-one';

let postType, userRole;

export const init = memoize((type: string, role: string) => {
  postType = type;
  userRole = role;
});

export const isViewPermitted = (component: string): boolean => {
  if (!viewPermissions.hasOwnProperty(component)) {
    return true;
  }

  const componentViewPermissions = viewPermissions[component];

  if (
    componentViewPermissions.hasOwnProperty(userRole) &&
    componentViewPermissions[userRole].includes(postType)
  ) {
    return true;
  }

  if (
    componentViewPermissions.hasOwnProperty('types') &&
    componentViewPermissions['types'].includes(postType)
  ) {
    if ('ROLE_ADMINISTRATOR' === userRole && component !== 'PublicationLabel') {
      return true;
    }

    if (componentViewPermissions['roles'].includes(userRole)) {
      return true;
    }
  }
  return false;
};
type Props = { childName: string, children: Node };
export const Check = (props: Props) => {
  if (isViewPermitted(props.childName)) {
    return <ErrorBoundary>{props.children}</ErrorBoundary>;
  }
  return null;
};

export const isValidUser = (currentUser, userId) => {
  if (postType === 'brandedLongform' || postType === 'longform') {
    return false;
  }

  if ('ROLE_ADMINISTRATOR' === userRole) {
    return true;
  }

  if (postTypePermissions[postType]['roles'].includes(userRole)) {
    if ('ROLE_COLABORADOR' === userRole) {
      return currentUser === userId;
    }
    return true;
  }

  return false;
};

export const isValidFichaUser = (userRole) => {
  if (
    [
      ...MANAGER_ROLES,
      ROLE_FICHA_EDITOR,
      ROLE_COORDINATOR,
      ROLE_ECOMMERCE_DIRECTOR,
    ].includes(userRole)
  ) {
    return true;
  }
  return false;
};

export const isValidStreamingUser = (userRole) => {
  if (
    [
      ROLE_EDITOR,
      ROLE_DIRECTOR,
      ROLE_ADMINISTRATOR,
      ROLE_SEO,
      ROLE_ECOMMERCE_DIRECTOR,
    ].includes(userRole)
  ) {
    return true;
  }
  return false;
};

export const isValidVersionsUser = (userRole) => {
  if (
    [ROLE_ADMINISTRATOR, ROLE_DIRECTOR, ROLE_COORDINATOR].includes(userRole)
  ) {
    return true;
  }
  return false;
};
