// @flow
import type { Element } from 'React';
import React, { PureComponent } from 'react';
import { ToolTipText } from 'components/Editor/Common/Tooltip';

import { POST_TYPE_LANDING } from 'lib/constants';
import { getSiteIcon } from 'lib/helpers';
import { H6 } from 'components/Editor/Common/Typography/Typo';
import styles from './MenuTitle.module.css';

type Props = {
  blogName: string,
  adminUrl: string,
  isDesignMode?: boolean,
  postType: string,
  moduleName: string,
  postId: string | number,
};

export class MenuTitle extends PureComponent<Props> {
  render(): Element<'div'> {
    const { blogName, adminUrl, isDesignMode, postType, moduleName, postId } =
      this.props;
    const className = isDesignMode ? styles.titleLight : styles.titleDark;
    const getTitle = () => {
      if ('createProduct' === moduleName) {
        return 'Crear producto';
      }

      if (!postType) {
        return '';
      }

      let title = POST_TYPE_LANDING === postType ? 'Landing' : 'Post';

      if (postId) {
        title += ` #${postId}`;
      }

      return title;
    };

    return (
      <div className={className}>
        <ToolTipText title="Ir al panel de gestión">
          <span>{getSiteIcon(blogName, adminUrl)}</span>
        </ToolTipText>
        <H6>{getTitle()}</H6>
      </div>
    );
  }
}
