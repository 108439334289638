// @flow
import type { Node } from 'React';
import React from 'react';
import { Grid } from 'components/Editor/Common/Grid/Grid';
import { TextField } from 'components/Editor/Common/TextField';
import { InsertPhotoIcon } from 'components/Editor/Common/Icons';
import { isMobile } from 'lib/deviceDetect';

import { NormalButtonWithLeftIcon } from '../Common/Buttons/Button';
import { InputEvent } from 'lib/flowTypes';
import { validateExtension } from './lib/helpers';
import { getImageExtension } from 'lib/helpers';

const URL_UPLOAD_ERROR = 'Error: la URL no es una imagen';

type Props = {
  url: string,
  urlError: string,
  isUploading: boolean,
  resetErrors: () => void,
  setUrlUploadError: (error: string) => void,
  handleUrlChange: (url: string) => void,
  uploadFiles: (files: Object[]) => string[],
};

export function UrlUploader({
  url,
  urlError,
  isUploading,
  resetErrors,
  setUrlUploadError,
  handleUrlChange,
  uploadFiles,
}: Props): Node {
  const onUrlChange = (e: InputEvent) => handleUrlChange(e.target.value);

  const onKeyPress = (e: InputEvent) => {
    const key = e.which || e.keyCode;

    if (13 === key && url) {
      uploadWithUrl();
    }
  };

  const uploadWithUrl = async () => {
    const imageUrl = url.split('?')[0];
    const imageName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    const extension = getImageExtension(imageName);

    resetErrors();

    if (extension && !validateExtension(extension)) {
      setUrlUploadError(URL_UPLOAD_ERROR);
      return;
    }

    let data = new FormData();
    data.append('url', imageUrl);
    const failedFiles = await uploadFiles([{ data, name: imageUrl }]);

    if (failedFiles.length > 0) {
      setUrlUploadError(URL_UPLOAD_ERROR);
    } else {
      handleUrlChange('');
    }
  };

  return (
    <Grid item sm={7} xs={12}>
      <Grid
        container
        gutter="custom"
        spacing={2}
        justify={isMobile() ? 'center' : 'flex-end'}
      >
        <Grid item xs={isMobile() ? '12' : 'auto'}>
          <TextField
            placeholder="http://..."
            error={!!urlError}
            helperText={urlError}
            disabled={isUploading}
            value={url}
            onChange={onUrlChange}
            onKeyPress={onKeyPress}
          />
        </Grid>
        <Grid item>
          <NormalButtonWithLeftIcon
            label="Desde URL"
            icon={InsertPhotoIcon}
            disabled={isUploading || !url}
            onClick={uploadWithUrl}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
