//@flow
import type { Node } from 'React';
import React, { useState, useEffect } from 'react';
import { InsertPhotoIcon } from 'components/Editor/Common/Icons';
import {
  NormalButtonWithLeftIcon,
  NormalButton,
} from 'components/Editor/Common/Buttons/Button';
import { DragAndDropUploader } from 'components/Editor/ImageUploader';
import { Error, CircularLoader, Grid } from 'components/Editor/Common';
import {
  getErrorMessages,
  uploadImagesToS3Bucket,
} from 'components/Editor/ImageUploader/lib/helpers';
import styles from './PrimaryImageThumbnail.module.css';

type Props = {
  crop: Object,
  imageSrc: string,
  siteName: string,
  postType: string,
  isMobile?: boolean,
  onImageUploadClick: Function,
  afterSuccessfulDrop: Function,
  onCropValidateAll: Function,
  onLoad?: Function,
  watermark?: Node,
  hintText?: string,
  isImageValidating: boolean,
};

const areAllImagesValidated = (crop) => {
  for (let key in crop) {
    if (!crop[key]['validate']) {
      return false;
    }
  }
  return true;
};

export const PrimaryImageThumbnail = ({
  crop,
  imageSrc,
  siteName,
  postType,
  isMobile = false,
  onImageUploadClick,
  afterSuccessfulDrop,
  watermark = null,
  onLoad,
  hintText = 'Arrastra aquí una imagen para la portada',
  isImageValidating,
  onCropValidateAll,
}: Props): Node => {
  const [errors, setErrors] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const errorMessages = errors.map((error, index) => (
    <Error key={index} message={error} />
  ));

  const allImagesValidated = areAllImagesValidated(crop);

  const imgElement = React.useRef(null);

  useEffect(() => {
    if (imageSrc && errors.length !== 0) {
      setErrors([]);
    }
  }, [errors.length, imageSrc]);

  const handleDrop = async (files: Object[]) => {
    const imgErrors = getErrorMessages(files);
    if (imgErrors.length) {
      setErrors(imgErrors);
      return;
    }

    setIsUploading(true);

    try {
      const images = await uploadImagesToS3Bucket(siteName, files);
      afterSuccessfulDrop(images);
    } catch (e) {
      setErrors([e.message]);
    } finally {
      setIsUploading(false);
    }
  };

  const dropAreaContent = isUploading ? (
    <CircularLoader show size={24} />
  ) : (
    <>
      <InsertPhotoIcon />
      {hintText}
    </>
  );

  const getImage = () => {
    if (imageSrc) {
      return (
        <img
          src={imageSrc}
          className={styles.img}
          alt=""
          ref={imgElement}
          onLoad={() => {
            if (onLoad) {
              onLoad(
                imgElement?.current?.naturalHeight,
                imgElement?.current?.naturalWidth
              );
            }
          }}
        />
      );
    }

    return (
      <div className={`uploader ${styles.dropArea}`}>
        {!isMobile && (
          <DragAndDropUploader
            handleFileDrop={handleDrop}
            boxContent={dropAreaContent}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <span className={styles.relative}>
        {watermark}
        {getImage()}
      </span>
      {errorMessages}

      <Grid container gutter="custom" spacing={1} justify="space-between">
        <Grid item>
          <NormalButtonWithLeftIcon
            label={imageSrc ? 'Cambiar' : 'Subir imagen'}
            icon={InsertPhotoIcon}
            onClick={onImageUploadClick}
            disabled={isUploading}
          />
        </Grid>
        {!!imageSrc && (
          <Grid item>
            <NormalButton
              onClick={(e) => {
                onCropValidateAll(e);
              }}
              disabled={isImageValidating || allImagesValidated}
            >
              {allImagesValidated ? 'Validado' : 'Validar todo'}
            </NormalButton>
          </Grid>
        )}
      </Grid>
    </>
  );
};
