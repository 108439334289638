// @flow
import type { Node as $IMPORTED_TYPE$_Node } from 'React';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type { Node } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

type hideOnScrollProps = {
  children: Node,
};

function HideOnScroll({ children }: hideOnScrollProps): Node {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

type Props = {
  children: Node,
  isDesignMode?: boolean,
  isSticky?: boolean,
};

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: '#212121',
        },

        colorSecondary: {
          backgroundColor: '#212121',
          color: '#ffffff',
        },

        root: {
          height: 'auto',
          padding: '0 8px',

          '@media(min-width: 900px)': {
            height: '64px',
            padding: '0 16px',
          },

          '@media(min-width: 1280px)': {
            padding: '0px 16px',
          },
        },
      },
    },
  },
});

export const AppBar = ({
  isDesignMode = false,
  isSticky = false,
  children,
}: Props): $IMPORTED_TYPE$_Node => {
  const renderAction = () => {
    if (isSticky) {
      return (
        <ThemeProvider theme={theme}>
          <HideOnScroll>
            <MuiAppBar color={isDesignMode ? 'primary' : 'secondary'}>
              {children}
            </MuiAppBar>
          </HideOnScroll>
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <MuiAppBar color={isDesignMode ? 'primary' : 'secondary'}>
          {children}
        </MuiAppBar>
      </ThemeProvider>
    );
  };

  return <>{renderAction()}</>;
};
