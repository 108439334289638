// @flow
import React, { useState } from 'react';
import { TextField, Grid } from 'components/Editor/Common';
import { NormalButton } from 'components/Editor/Common/Buttons/Button';
import { Warning } from 'components/Editor/Common/Warning/Warning';
import { getTrimmedText } from 'lib/helpers';
import { fetchRepostData } from './lib/service';
import { Backdrop, CircularLoader } from 'components/Editor/Common';
import { SnackbarConfirmation } from 'components/Editor/Common/Snackbar/SnackbarConfirmation';

type SubmissionErrors = {
  title: string,
  siteName: string,
  description: string,
  image: string,
  repostSites: string,
  clientName?: string,
};

type Props = {
  adminUrl: string,
  isBranded: boolean,
  searchText: string,
  uploadImage: (image: string) => void,
  fields: {
    title: string,
    siteName: string,
    description: string,
    image: string,
    repostSites: string[],
    clientName?: string,
  },
  handleFieldsUpdate: (updates: { [string]: any }) => void,
  resetFields: () => void,
  submissionErrors: SubmissionErrors,
  contentStatus: string,
  setContentStatus: (contentStatus: string) => void,
  setSourceSiteName: (value: string) => void,
  setSearchText: (searchText: string) => void,
  setIsSubmittedSuccessfully: (value: boolean) => void,
  resetIsSubmitClicked: () => void,
  setIsSubmissionAttempted: (value: boolean) => void,
};

export const Content = ({
  adminUrl,
  isBranded,
  searchText,
  uploadImage,
  fields,
  handleFieldsUpdate,
  resetFields,
  submissionErrors,
  contentStatus,
  setContentStatus,
  setSourceSiteName,
  setSearchText,
  setIsSubmittedSuccessfully,
  resetIsSubmitClicked,
  setIsSubmissionAttempted,
}: Props) => {
  const [showWarning, setShowWarning] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onKeyPress = (e: KeyboardEvent) => {
    const key = e.which || e.keyCode;

    if (13 === key) {
      handleSearch();
    }
  };

  const onButtonClick = () => {
    if (contentStatus === 'initial') {
      handleSearch();
    } else {
      setShowSnackbar(true);
    }
  };

  const handleSearch = async () => {
    resetIsSubmitClicked();
    setIsSubmittedSuccessfully(false);
    setErrorText('');
    setShowWarning(false);
    resetFields();
    if (!searchText.trim()) {
      setErrorText('Error: Falta la URL');
      setContentStatus('initial');
      return;
    }

    setContentStatus('parsing');

    try {
      const data = encodeURIComponent(searchText.trim());
      const response = await fetchRepostData(adminUrl, data);
      handleResponse(response);
    } catch (e) {
      console.log(e);
    }
  };

  const onConfirmation = () => {
    setSearchText('');
    setShowSnackbar(false);
    setContentStatus('initial');
    resetIsSubmitClicked();
    setIsSubmissionAttempted(false);
  };

  const handleResponse = (response) => {
    if (!response.error) {
      const fields = {
        title: response['title'] || '',
        description: response['description'] || '',
      };
      uploadImage(response['image'] || '');
      handleFieldsUpdate(fields);
      setContentStatus('loaded');
      setSourceSiteName(response['siteName']);
    } else if (response.error === 'INVALID_SITE_URL') {
      setErrorText('Error: no es una URL de Sensacine o MGG');
      setContentStatus('initial');
    } else if (response.error === 'INVALID_POSTPAGE_URL') {
      setErrorText('Error: la URL no pertenece a un post válido');
      setContentStatus('initial');
    } else if (response.error === 'CONTENT_NOT_FETCHED') {
      setSourceSiteName(response['siteName']);
      resetFields();
      setShowWarning(true);
      setContentStatus('loaded');
    }
  };

  return (
    <Grid container gutter="custom" spacing={7}>
      <Grid item xs={12}>
        <Grid container gutter="custom" spacing={3}>
          <Grid item sm={8} xs={12}>
            <TextField
              disabled={'initial' !== contentStatus}
              value={searchText.replace(/\s/g, '')}
              floatingLabelText="URL del contenido para el crosspost"
              helperText="Se aceptan contenidos de Sensacine o MGG"
              placeholder="http://..."
              onChange={({ target }) => setSearchText(target.value)}
              onKeyPress={onKeyPress}
              errorText={errorText}
            />
          </Grid>
          <Grid item>
            <NormalButton
              disabled={'parsing' === contentStatus}
              onClick={onButtonClick}
            >
              {'loaded' === contentStatus ? 'Editar URL' : 'Buscar datos'}
            </NormalButton>
          </Grid>

          {showWarning ? (
            <Grid item xs={12}>
              <Warning
                margin="zero"
                message="No se han podido importar los datos automáticamente"
                size="large"
              />
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </Grid>

      {'parsing' === contentStatus ? <CircularLoader show /> : ''}

      {'loaded' === contentStatus ? (
        <Grid item xs={12}>
          <Grid container gutter="custom" spacing={6}>
            <Grid item md={9} xs={12}>
              <Grid container gutter="custom" spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    floatingLabelText="Título"
                    multiline
                    minRows={1}
                    maxRows={1}
                    value={fields.title}
                    onChange={(e) =>
                      handleFieldsUpdate({ title: e.target.value })
                    }
                    errorText={submissionErrors.title}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container gutter="custom" spacing={6}>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        floatingLabelText="Texto para portada"
                        multiline
                        minRows={3}
                        maxRows={3}
                        textLimit={229}
                        value={getTrimmedText(fields.description, 229)}
                        onChange={(e) =>
                          handleFieldsUpdate({ description: e.target.value })
                        }
                        errorText={submissionErrors.description}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {isBranded && (
              <Grid item md={3} xs={12}>
                <TextField
                  floatingLabelText="Nombre del cliente"
                  helperText='Se usa con "Ofrecido por" en portada'
                  value={fields.clientName}
                  onChange={(e) =>
                    handleFieldsUpdate({ clientName: e.target.value })
                  }
                  errorText={submissionErrors.clientName}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        ''
      )}

      {showSnackbar && (
        <Backdrop preventScroll>
          <SnackbarConfirmation
            message="Al cambiar de URL, se cargarán datos del nuevo post y se perderán los del crosspost actual"
            messageType="confirmation"
            confirmationButtonLabel="Cargar nuevos datos"
            horizontal="center"
            handleConfirmation={onConfirmation}
            handleClose={() => setShowSnackbar(false)}
            layout="column"
            size="small"
            closeButtonLabel="Seguir con el crosspost actual"
            position="topEdge"
          />
        </Backdrop>
      )}
    </Grid>
  );
};
