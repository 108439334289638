import {
  getDefaultKeyBinding,
  KeyBindingUtil,
  RichUtils,
  Modifier,
  EditorState,
} from 'draft-js';
import markdown from 'marked';
import htmlToDraft from 'html-to-draftjs';
import { List } from 'immutable';

export const handlePastedText = (
  text: string,
  html?: string,
  editorState: EditorState,
  onChange
) => {
  if (!html) {
    html = markdown(text);
    const contentBlock = htmlToDraft(html);
    let contentState = editorState.getCurrentContent();
    contentBlock.entityMap.forEach((value, key) => {
      contentState = contentState.mergeEntityData(key, value);
    });
    contentState = Modifier.replaceWithFragment(
      contentState,
      editorState.getSelection(),
      new List(contentBlock.contentBlocks)
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
    return 'handled';
  }
};

export const getCurrentBlock = (editorState: EditorState) => {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(selectionState.getStartKey());

  return block;
};

const { hasCommandModifier } = KeyBindingUtil;

export const myKeyBindingFn = (
  e: SyntheticKeyboardEvent,
  allowedShortcuts,
  editorId
) => {
  if (
    e.keyCode === 66 /* `B`key */ &&
    hasCommandModifier(e) &&
    !allowedShortcuts.includes('bold')
  ) {
    return 'skip';
  }
  if (
    e.keyCode === 73 /* `I`key */ &&
    hasCommandModifier(e) &&
    !allowedShortcuts.includes('italic')
  ) {
    return 'skip';
  }
  if (e.keyCode === 85 /* `U` key */ && hasCommandModifier(e)) {
    return 'skip';
  }
  if (
    (e.keyCode === 76 || e.keyCode === 75) /* `L` or K key */ &&
    hasCommandModifier(e) &&
    allowedShortcuts.includes('link')
  ) {
    const linkElement = document.querySelector(
      `[id="${editorId}"] .toolbarLink`
    );
    if (linkElement) {
      linkElement.click();
      return 'linkify';
    }
  }
  if (
    e.keyCode === 50 /* `2` key */ &&
    hasCommandModifier(e) &&
    allowedShortcuts.includes('header-two')
  ) {
    return 'header-two';
  }
  if (
    e.keyCode === 51 /* `3` key */ &&
    hasCommandModifier(e) &&
    allowedShortcuts.includes('header-three')
  ) {
    return 'header-three';
  }
  if (
    e.keyCode === 52 /* `4` key */ &&
    hasCommandModifier(e) &&
    allowedShortcuts.includes('header-four')
  ) {
    return 'header-four';
  }

  return getDefaultKeyBinding(e);
};

export const handleKeyCommand = (editorState, command) => {
  let newState = '';

  switch (command) {
    case 'line-through':
      newState = RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH');
      break;
    case 'header-two':
      newState = RichUtils.toggleBlockType(editorState, 'header-two');
      break;
    case 'header-three':
      newState = RichUtils.toggleBlockType(editorState, 'header-three');
      break;
    case 'header-four':
      newState = RichUtils.toggleBlockType(editorState, 'header-four');
      break;
    case 'skip':
      newState = editorState;
      break;
    default:
      newState = RichUtils.handleKeyCommand(editorState, command);
  }

  return newState;
};

export const checkPosition = (
  index,
  changePosition,
  editorState,
  addDropArea
) => {
  const selectionState = editorState.getSelection();
  if (selectionState.getHasFocus()) {
    const currentBlockKey = selectionState.getStartKey();
    const caretPosition = document.querySelector(
      '#content-' +
        index +
        ' span[data-offset-key="' +
        currentBlockKey +
        '-0-0"]'
    );

    if (caretPosition) {
      const currentContent = editorState.getCurrentContent();
      const length = currentContent.getBlockForKey(currentBlockKey).getLength();
      const isAtFirstPosition = 0 === length;
      caretPosition.parentElement.ondragenter = function () {
        if (isAtFirstPosition) {
          addDropArea();
        }
        caretPosition.parentElement.ondragenter = null;
      };
      const splitPosition = currentContent
        .getBlockMap()
        ._map.get(currentBlockKey);

      const coordinates = {
        top: caretPosition.offsetTop - caretPosition.offsetHeight,
        left: caretPosition.offsetLeft,
      };

      changePosition(index, splitPosition, coordinates, isAtFirstPosition);
    }
  }
};
