import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Main, ModuleWrapper, Grid } from 'components/Editor/Common';
import TitleBar from 'components/Editor/CrossPost/TitleBar';
import { Content } from 'components/Editor/CrossPost/Content';
import ImageCropper from 'components/Editor/CrossPost/ImageCropper';
import RepostSiteOptions from 'components/Editor/Difundir/RepostSiteOptions';

import { getImageUrl, getImageData } from 'lib/helpers';
import { useFetchSiteData, useFetchUserData } from './lib/hookHelpers';
import { uploadImageViaUrl } from 'components/Editor/Escribir/Pivot/Product/lib/helpers';
import { areAllImagesValidated } from 'containers/lib/helpers';
import {
  ROLE_ADMINISTRATOR,
  ALL_NON_BRANDED_ROLES,
  ALL_ROLES,
} from 'lib/constants';
import { submitCrossPostToBackend } from './lib/service';
import { initialPublishRegions } from '../lib/constants';

const ERROR_EMPTY_TITLE = 'Error: hay que indicar el título';
const ERROR_LONG_TITLE = '300 caracteres, no puedes escribir más.';
const ERROR_SHORT_TITLE = 'Error: el título debe tener al menos 5 caracteres';
const ERROR_IMAGE_CROPPER =
  'hay que validar los recortes de la imagen principal';
const ERROR_EMPTY_DESCRIPTION = 'Error: hay que indicar el texto de portada';
const ERROR_REPOST_SITES =
  'Error: hay que seleccionar al menos un medio de destino';
const ERROR_EMPTY_SPONSOR_NAME = 'Error: hay que indicar el nombre del cliente';

type Props = {
  isBranded: boolean,
  receiveImages: () => void,
};

const getInitErrors = () => ({
  title: '',
  description: '',
  image: '',
  repostSites: '',
  clientName: '',
});

const getInitFields = () => ({
  title: '',
  description: '',
  image: '',
  repostSites: [],
  clientName: '',
  crop: {},
});

const STATIC_ERRORS = {
  title: ERROR_EMPTY_TITLE,
  description: ERROR_EMPTY_DESCRIPTION,
  repostSites: ERROR_REPOST_SITES,
  clientName: ERROR_EMPTY_SPONSOR_NAME,
};

const CrossPost = ({ isBranded = false }: Props) => {
  const [adminUrl, , siteError, destinationSiteName] = useFetchSiteData();
  const [user, userErrors] = useFetchUserData(destinationSiteName, adminUrl);
  const [crop, setCrop] = useState({});
  const [contentStatus, setContentStatus] = useState('initial'); //initial/parsing/loaded
  const [fields, setFields] = useState(getInitFields());
  const [submissionErrors, setSubmissionErrors] = useState(getInitErrors());
  const [isSubmittedSuccessfully, setIsSubmittedSuccessfully] = useState(false);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [warning, setWarning] = useState('');
  const [isSubmissionAttempted, setIsSubmissionAttempted] = useState(false);
  const [isImageValidating, setIsImageValidating] = useState(false);
  const navigate = useNavigate();
  const sourceSiteName = useRef('');
  const isSubmitClicked = useRef(false);

  const setSourceSiteName = useCallback(
    (value) => {
      sourceSiteName.value = value;
    },
    [sourceSiteName]
  );

  useEffect(() => {
    if (
      user.userRole &&
      user.userRole !== ROLE_ADMINISTRATOR &&
      ((!isBranded && !ALL_NON_BRANDED_ROLES.includes(user.userRole)) ||
        (isBranded && !ALL_ROLES.includes(user.userRole)))
    ) {
      navigate('/notAuthorized');
      return;
    }
  }, [user.userRole, navigate, isBranded]);

  const handleFieldsUpdate = useCallback(
    (updates) => {
      setFields((prevFields) => ({ ...prevFields, ...updates }));
      setIsSubmittedSuccessfully(false);
      setWarning('');

      if (isSubmitClicked.value) {
        setSubmissionErrors((prevErrors) => {
          let newErrors = { ...prevErrors };
          for (const updateKey in updates) {
            newErrors[updateKey] =
              '' === updates[updateKey] ||
              (Array.isArray(updates[updateKey]) &&
                updates[updateKey].length === 0)
                ? STATIC_ERRORS[updateKey]
                : '';
            if ('title' === updateKey) {
              newErrors[updateKey] = getTitleError(updates[updateKey]);
            }
          }
          return newErrors;
        });
        setIsSubmissionAttempted(false);
      }
    },
    [setFields, isSubmitClicked.value]
  );

  const resetFields = useCallback(() => {
    setFields(getInitFields());
  }, [setFields]);

  const handleImageUpload = useCallback(
    async (img) => {
      setIsImageUploading(true);
      setCrop({});
      setSubmissionErrors(getInitErrors());
      try {
        if (!img) {
          handleFieldsUpdate({ image: '' });
          return;
        }
        let image = await uploadImageViaUrl(destinationSiteName, img);
        image = getImageUrl(image);
        handleFieldsUpdate({ image });
      } catch (e) {
        console.log(e);
      } finally {
        setIsImageUploading(false);
      }
    },
    [setSubmissionErrors, handleFieldsUpdate, destinationSiteName]
  );

  const handleSetRepostSites = (siteNames, isChecked) => {
    const sites = siteNames.split(',');
    let updatedSites;
    if (isChecked) {
      updatedSites = [...fields.repostSites, ...sites];
    } else {
      updatedSites = fields.repostSites.filter((site) => !sites.includes(site));
    }
    handleFieldsUpdate({ repostSites: Array.from(new Set(updatedSites)) });
  };

  const getTitleError = (title) => {
    const titleLength = title ? title.length : 0;
    if (!titleLength) {
      return ERROR_EMPTY_TITLE;
    }
    if (titleLength >= 300) {
      return ERROR_LONG_TITLE;
    }
    if (titleLength < 5) {
      return ERROR_SHORT_TITLE;
    }
    return '';
  };

  const validate = () => {
    const trimmedTitle = fields.title.trim();
    const trimmedDescription = fields.description.trim();
    const trimmedClientName = fields.clientName.trim();

    const isImageValidated = areAllImagesValidated(crop);
    const titleError = getTitleError(trimmedTitle);

    const valid =
      !titleError &&
      trimmedDescription &&
      fields.image &&
      isImageValidated &&
      fields.repostSites.length &&
      (isBranded ? trimmedClientName : true);

    const submissionErrors = {
      title: titleError ?? '',
      description: !trimmedDescription ? ERROR_EMPTY_DESCRIPTION : '',
      image: !isImageValidated || !fields.image ? ERROR_IMAGE_CROPPER : '',
      repostSites: !fields.repostSites.length ? ERROR_REPOST_SITES : '',
      clientName:
        isBranded && !trimmedClientName ? ERROR_EMPTY_SPONSOR_NAME : '',
    };

    setSubmissionErrors(submissionErrors);

    return valid;
  };

  const setImage = (image) => {
    setSubmissionErrors({
      ...submissionErrors,
      image: '',
    });
    handleFieldsUpdate({ image });
  };

  const handleSubmit = async () => {
    if (isSubmissionAttempted) {
      setWarning('Cuidado: el crosspost ya se ha enviado');
      return;
    }
    setIsSubmissionAttempted(true);
    setWarning('');
    isSubmitClicked.value = true;
    setIsSubmittedSuccessfully(false);
    const isValid = validate();

    if (!isValid) {
      setIsSubmissionAttempted(false);
      return;
    }

    const imageData = getImageData(fields.image);
    const imageUrl = new URL(imageData.src);
    const imagePath = imageUrl.pathname.substring(1);

    const dataToSubmit = {
      post_title: fields.title.trim(),
      post_content: `${fields.description.trim()}\n[[image: {"src":"${imagePath}","extension":"${
        imageData.extension
      }"}]]`,
      countries: initialPublishRegions.join(','),
      post_status: 'publish',
      post_author: 752,
      primary_image: fields.image,
      repost: fields.repostSites.join(','),
      post_type: isBranded ? 'club' : 'normal',
      disclaimer: isBranded ? fields.clientName.trim() : undefined,
      guid: searchText.trim().split(/[?#]/)[0],
    };

    try {
      await submitCrossPostToBackend(
        dataToSubmit,
        adminUrl,
        sourceSiteName.value
      );
      setIsSubmittedSuccessfully(true);
    } catch (e) {
      console.log(e);
      setIsSubmittedSuccessfully(false);
    }
  };

  if (userErrors.hasError || siteError.hasError) {
    return (
      <Main baseTheme="light">
        <ModuleWrapper type="small">
          Something went wrong.
          <pre>{userErrors.errorStack}</pre>
          <pre>{siteError.errorStack}</pre>
        </ModuleWrapper>
      </Main>
    );
  }

  const hasSubmitError = Object.values(submissionErrors).some(
    (error) => error !== ''
  );

  const handleCrop = (crop) => {
    const isImageValidated = areAllImagesValidated(crop);

    const updatedSubmissionErrors = {
      ...submissionErrors,
      image: submissionErrors.image
        ? !isImageValidated || !fields.image
          ? ERROR_IMAGE_CROPPER
          : ''
        : '',
    };

    setSubmissionErrors(updatedSubmissionErrors);

    setCrop(crop);
  };

  return (
    <Main baseTheme="white" spacing="zero">
      <ModuleWrapper type="large" margin="small">
        <TitleBar siteName={destinationSiteName} adminUrl={adminUrl} />

        <Grid container gutter="custom" spacing={6}>
          <Grid item xs={12}>
            <Content
              adminUrl={adminUrl}
              isBranded={isBranded}
              searchText={searchText}
              uploadImage={handleImageUpload}
              fields={fields}
              handleFieldsUpdate={handleFieldsUpdate}
              resetFields={resetFields}
              submissionErrors={submissionErrors}
              contentStatus={contentStatus}
              setContentStatus={setContentStatus}
              setSourceSiteName={setSourceSiteName}
              setSearchText={setSearchText}
              setIsSubmittedSuccessfully={setIsSubmittedSuccessfully}
              resetIsSubmitClicked={() => (isSubmitClicked.value = false)}
              setIsSubmissionAttempted={setIsSubmissionAttempted}
            />
          </Grid>
          {'loaded' === contentStatus ? (
            <>
              <Grid item xs={12}>
                <ImageCropper
                  imageSrc={fields.image}
                  crop={crop}
                  errorText={submissionErrors.image}
                  isMobile={false}
                  setImage={setImage}
                  setCrop={handleCrop}
                  siteName={destinationSiteName}
                  isImageUploading={isImageUploading}
                  setIsImageUploading={setIsImageUploading}
                  isImageValidating={isImageValidating}
                  setIsImageValidating={setIsImageValidating}
                />
              </Grid>
              <Grid item xs={12}>
                <RepostSiteOptions
                  repostBlogs={fields.repostSites}
                  setRepostBlogs={handleSetRepostSites}
                  submitRepostedBlogs={handleSubmit}
                  showRepostError={submissionErrors.repostSites !== ''}
                  showSubmitError={hasSubmitError}
                  showSuccessMessage={
                    isSubmittedSuccessfully && !hasSubmitError && !warning
                  }
                  warning={warning}
                  loading={isImageValidating}
                />
              </Grid>
            </>
          ) : (
            ''
          )}
        </Grid>
      </ModuleWrapper>
    </Main>
  );
};

export default React.memo(CrossPost);
