import * as React from 'react';
import { Grid as MuiGrid } from '@mui/material';

type Props = {
  container?: boolean,
  gutter?: string,
  spacing?: number,
  justify?: string,
  container?: boolean,
  children: React.Node,
};

export const Grid = ({
  children,
  gutter = 'default',
  justify = 'flex-start',
  ...props
}: Props) => {
  if (props.container) {
    let spacing = 6;

    if (gutter === 'custom') {
      spacing = props.spacing;
    }

    props.spacing = spacing;
    props.justifyContent = justify;
  }

  return <MuiGrid {...props}>{children}</MuiGrid>;
};
