// @flow
import React from 'react';
import styles from './Preview.module.css';

type props = {
  bgColor?: 'grey',
  padding?: 'mini',
  layout?: 'column',
  children: any,
};

export const Preview = ({
  bgColor = '',
  padding = 'mini',
  layout = '',
  children,
}: props) => {
  const classNames = [styles.preview];

  if (layout === 'column') {
    classNames.push(styles.column);
  }

  if (bgColor === 'grey') {
    classNames.push(styles.bgGrey);
  }

  if (padding === 'mini') {
    classNames.push(styles.mini);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};
