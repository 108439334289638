export const RECEIVE_POST = 'RECEIVE_POST';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const CHANGE_SUB_TITLE = 'CHANGE_SUB_TITLE';
export const ADD_SECTION = 'ADD_SECTION';
export const APPEND_SECTION = 'APPEND_SECTION';
export const EDIT_SECTION = 'EDIT_SECTION';
export const EDIT_TABLE = 'EDIT_TABLE';
export const OPEN_IMAGEPANEL = 'OPEN_IMAGEPANEL';
export const CHANGE_CONTENT = 'CHANGE_CONTENT';
export const CHANGE_POSITION = 'CHANGE_POSITION';
export const CONFIRM_DELETE = 'CONFIRM_DELETE';
export const CANCEL_DELETE = 'CANCEL_DELETE';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_GRID_SECTION = 'DELETE_GRID_SECTION';
export const CHANGE_CURRENT_INDEX = 'CHANGE_CURRENT_INDEX';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const SHOW_ERRORS = 'SHOW_ERRORS';
export const HIDE_ERRORS = 'HIDE_ERRORS';
export const CHANGE_SELECTED_PRODUCT = 'CHANGE_SELECTED_PRODUCT';
export const EDIT_FIELD = 'EDIT_FIELD';
export const EDIT_DESIGN_FIELD = 'EDIT_DESIGN_FIELD';
export const ADD_GRID_SECTION = 'ADD_GRID_SECTION';
export const EDIT_GRID_SECTION = 'EDIT_GRID_SECTION';
export const UNDO_GRID = 'UNDO_GRID';
export const EDIT_COLUMN_DESIGN_FIELD = 'EDIT_COLUMN_DESIGN_FIELD';
export const UPDATE_ANCHOR_SOURCE = 'UPDATE_ANCHOR_SOURCE';
export const ALTER_TABLE_ROWS = 'ALTER_TABLE_ROWS';
export const ALTER_TABLE_COLUMNS = 'ALTER_TABLE_COLUMNS';
export const CHANGE_TABLE_ALIGNMENT = 'CHANGE_TABLE_ALIGNMENT';
export const MODIFY_TABLE_ROW_COLUMN = 'MODIFY_TABLE_ROW_COLUMN';
export const SHOW_REINDEX_CHANGES_WARNING = 'SHOW_REINDEX_CHANGES_WARNING';
export const HIDE_REINDEX_CHANGES_WARNING = 'HIDE_REINDEX_CHANGES_WARNING';
export const CROP_CHANGE = 'CROP_CHANGE';
export const CROP_COMPLETE = 'CROP_COMPLETE';
export const SUBMIT_POST = 'SUBMIT_POST';

export const receivePost = (post) => ({
  type: RECEIVE_POST,
  post,
});

export const changeTitle = (title) => ({
  type: CHANGE_TITLE,
  title,
});

export const changeSubTitle = (subTitle) => ({
  type: CHANGE_SUB_TITLE,
  subTitle,
});

export const changeContent = (index, text) => ({
  type: CHANGE_CONTENT,
  content: {
    text,
  },
  index,
});

export const changePosition = (
  index,
  splitPosition,
  coordinates,
  isAtFirstPosition
) => ({
  type: CHANGE_POSITION,
  index,
  splitPosition,
  coordinates,
  isAtFirstPosition,
});

export const undoGrid = (index, id) => ({
  type: UNDO_GRID,
  index,
  id,
});

export const addSection = (section, position, extractedFromText = false) => {
  return function (dispatch, getState) {
    const state = getState();
    const { maxId } = state.post;
    const { currentIndex } = state.common;
    section.id = maxId + 1;
    return dispatch({
      type: ADD_SECTION,
      currentIndex,
      section,
      position,
      extractedFromText,
    });
  };
};

export const addGridSection = (
  section,
  position,
  extractedFromText = false
) => {
  return function (dispatch, getState) {
    const state = getState();
    const { currentIndex } = state.common;
    const { maxId } = state.post;
    section.id = maxId + 1;
    return dispatch({
      type: ADD_GRID_SECTION,
      section,
      currentIndex,
      position,
      extractedFromText,
    });
  };
};

export const appendSection = (section) => {
  return {
    type: APPEND_SECTION,
    section,
  };
};

export const editSection = (section) => {
  return {
    type: EDIT_SECTION,
    section,
  };
};

export const editGridSection = (section) => {
  return {
    type: EDIT_GRID_SECTION,
    section,
  };
};

export const editTable = (index, data) => {
  return {
    type: EDIT_TABLE,
    index,
    data,
  };
};

export const confirmDelete = () => ({
  type: CONFIRM_DELETE,
});

export const cancelDelete = (index) => ({
  type: CANCEL_DELETE,
  data: { index },
});

export const deleteSection = (index, id, sectionType) => ({
  type: DELETE_SECTION,
  data: { index, id, sectionType },
});

export const deleteGridSection = (index, id, sectionType = '') => ({
  type: DELETE_GRID_SECTION,
  data: { index, id, sectionType },
});

export const primarySection = (section, type, crop = null) => ({
  type: EDIT_SECTION,
  section: {
    ...section,
    index: '1',
    type,
  },
  crop,
});

export const changeCurrentIndex = (index) => ({
  type: CHANGE_CURRENT_INDEX,
  index,
});

export const changeLayout = (index, layout, align) => ({
  type: CHANGE_LAYOUT,
  index,
  layout,
  align,
});

export const setShowErrors = (show, message = '', fieldErrors = {}) => ({
  type: show ? SHOW_ERRORS : HIDE_ERRORS,
  message,
  fieldErrors,
});

export const changeSelectedProduct = (productToEmbed) => ({
  type: CHANGE_SELECTED_PRODUCT,
  productToEmbed,
});

export const editField = (field, value) => ({
  type: EDIT_FIELD,
  field,
  value,
});

export const editDesignField = (index, field, value) => ({
  type: EDIT_DESIGN_FIELD,
  index,
  field,
  value,
});

export const editColumnDesignField = (index, field, value) => ({
  type: EDIT_COLUMN_DESIGN_FIELD,
  index,
  field,
  value,
});

export const alterTableRows = (index, rowIndex, operation = 'add') => ({
  type: ALTER_TABLE_ROWS,
  index,
  rowIndex,
  operation,
});

export const alterTableColumns = (index, colIndex, operation = 'add') => ({
  type: ALTER_TABLE_COLUMNS,
  index,
  colIndex,
  operation,
});

export const changeTableAlignment = (index, justify) => ({
  type: CHANGE_TABLE_ALIGNMENT,
  index,
  justify,
});

export const modifyTableRowColumn = ({ index, rowIndex, colIndex, data }) => ({
  type: MODIFY_TABLE_ROW_COLUMN,
  index,
  rowIndex,
  colIndex,
  data,
});

export const updateAnchorSources = ({
  targetId,
  sourceId,
  sourceText,
  event = 'add',
}) => ({
  type: UPDATE_ANCHOR_SOURCE,
  event,
  targetId,
  sourceId,
  sourceText,
});

export const showReIndexChanges = (index) => ({
  type: SHOW_REINDEX_CHANGES_WARNING,
  data: { index },
});

export const hideReIndexChanges = (index) => ({
  type: HIDE_REINDEX_CHANGES_WARNING,
  data: { index },
});

export const onCropComplete = (image) => ({
  type: CROP_COMPLETE,
  primaryImage: image,
});

export const onCropChange = (crop) => ({
  type: CROP_CHANGE,
  crop: crop,
});

export const submitPost = () => ({
  type: SUBMIT_POST,
});
