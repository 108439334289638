// @flow
import type { Element } from 'React';
import React from 'react';
import unionClassNames from 'union-class-names';
import { ClickAwayListener } from 'components/Editor/Common';
import { AnchorIcon } from 'components/Editor/Common/CustomSVGIcons';
import { AnchorToolbar } from './AnchorToolbar';
import styles from './Anchor.module.css';

type Props = {
  store: Object,
  theme: Object,
  getUrl: Function,
  onOverrideContent: Function,
  updateAnchor: Function,
};

const AnchorButton = ({
  theme,
  store,
  getUrl,
  onOverrideContent,
  updateAnchor,
}: Props): Element<'div'> => {
  const { isAnchor } = getUrl(store.getEditorState());

  let className = unionClassNames('toolbarLink', theme.button);
  if (isAnchor) {
    className = unionClassNames(className, theme.active);
  }

  const onClose = () => onOverrideContent(undefined);

  const onAnchorClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAnchor) {
      const content = (props) => (
        <ClickAwayListener onClickAway={onClose}>
          <AnchorToolbar
            onClose={onClose}
            getUrl={getUrl}
            updateAnchor={updateAnchor}
            {...props}
          />
        </ClickAwayListener>
      );
      onOverrideContent(content);
    } else {
      updateAnchor('add', { store });
    }
  };

  return (
    <div className={theme.buttonWrapper}>
      <button className={className} onClick={onAnchorClick} type="button">
        <AnchorIcon />
        <span className={styles.toolTiptext}>Ancla</span>
      </button>
    </div>
  );
};

export default AnchorButton;
