// @flow
import * as React from 'react';
import { ErrorIcon } from './Icons';
import styles from './error.module.css';

type Props = {
  message: string,
  addPrefix?: boolean,
  hint?: string,
  showIcon?: boolean,
  margin?: 'zero' | 'min' | 'normal' | 'dense' | 'fieldError',
  fontsize?: 'small' | 'normal',
};

const Error = (props: Props): React.Node => {
  const {
    message,
    addPrefix = true,
    showIcon = true,
    margin = 'normal',
    hint = '',
    fontsize = 'normal',
  } = props;
  const prefix = addPrefix ? 'Error: ' : '';

  const classNames = [styles.errorAlert];
  if (margin === 'dense') {
    classNames.push(styles.dense);
  } else if (margin === 'zero') {
    classNames.push(styles.zero);
  } else if (margin === 'min') {
    classNames.push(styles.min);
  } else if (margin === 'fieldError') {
    classNames.push(styles.fieldError);
  } else {
    classNames.push(styles.normal);
  }

  if (fontsize === 'small') {
    classNames.push(styles.fontSizeSmall);
  } else {
    classNames.push(styles.fontSizeNormal);
  }

  return (
    <>
      <span className={classNames.join(' ')}>
        <span className={styles.message}>
          {showIcon && <ErrorIcon />}
          {prefix}
          {message}
        </span>
        {hint && <span className={styles.hint}>{hint}</span>}
      </span>
    </>
  );
};

export default Error;
