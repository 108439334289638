import { RichUtils, EditorState } from 'draft-js';

const EditorUtils = {
  createLinkAtSelection(editorState, url, timestamp) {
    const contentState = editorState
      .getCurrentContent()
      .createEntity('LINK', 'MUTABLE', { url, rel: timestamp, id: timestamp });
    const entityKey = contentState.getLastCreatedEntityKey();
    const withLink = RichUtils.toggleLink(
      editorState,
      editorState.getSelection(),
      entityKey
    );
    return EditorState.forceSelection(withLink, editorState.getSelection());
  },

  replaceLinkAtSelection(editorState, url, linkKey) {
    const selection = this.getLinkSelection(editorState);
    const contentState = editorState.getCurrentContent();
    contentState.replaceEntityData(linkKey, { url });
    editorState = EditorState.createWithContent(contentState);
    return EditorState.forceSelection(editorState, selection);
  },

  removeLinkAtSelection(editorState) {
    const selection = this.getLinkSelection(editorState);
    const withoutLink = RichUtils.toggleLink(editorState, selection, null);
    return EditorState.forceSelection(withoutLink, selection);
  },

  getCurrentEntity(editorState) {
    const contentState = editorState.getCurrentContent();
    const entityKey = this.getEntityKey(editorState);
    return entityKey ? contentState.getEntity(entityKey) : null;
  },

  getEntityKey(editorState) {
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const anchorBlock = contentState.getBlockForKey(startKey);
    let entityKey = null;
    for (let i = startOffset; i <= endOffset; i++) {
      entityKey = anchorBlock.getEntityAt(i);
      if (entityKey) {
        return entityKey;
      }
    }
    return entityKey;
  },

  getLinkSelection(editorState) {
    let selection = editorState.getSelection();
    const currentEntityKey = this.getEntityKey(editorState);
    const entityRange = getEntityRange(editorState, currentEntityKey);
    selection = selection.merge({
      anchorOffset: entityRange.start,
      focusOffset: entityRange.end,
    });
    return selection;
  },
  hasEntity(editorState, entityType) {
    const entity = this.getCurrentEntity(editorState);
    return entity && entity.getType() === entityType;
  },
  getSelectionText(editorState: EditorState): string {
    let selectedText = '';
    const currentSelection = editorState.getSelection();
    let start = currentSelection.getAnchorOffset();
    let end = currentSelection.getFocusOffset();
    const selectedBlocks = getSelectedBlocksList(editorState);
    if (selectedBlocks.size > 0) {
      if (currentSelection.getIsBackward()) {
        const temp = start;
        start = end;
        end = temp;
      }
      for (let i = 0; i < selectedBlocks.size; i += 1) {
        const blockStart = i === 0 ? start : 0;
        const blockEnd =
          i === selectedBlocks.size - 1
            ? end
            : selectedBlocks.get(i).getText().length;
        selectedText +=
          ' ' + selectedBlocks.get(i).getText().slice(blockStart, blockEnd);
      }
    }
    return selectedText;
  },
};

/* Copied next 3 function from draftjs-utils */

function getEntityRange(editorState: EditorState, entityKey: string): any {
  const block = getSelectedBlock(editorState);
  let entityRange;
  block.findEntityRanges(
    (value) => value.get('entity') === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get('text').slice(start, end),
      };
    }
  );
  return entityRange;
}

function getSelectedBlocksList(editorState: EditorState): List {
  return getSelectedBlocksMap(editorState).toList();
}

/**
 * Function returns the first selected block.
 */
function getSelectedBlock(editorState: EditorState): ContentBlock {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

function getSelectedBlocksMap(editorState: EditorState): OrderedMap {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

export default EditorUtils;
