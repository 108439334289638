// @flow
import * as React from 'react';

import styles from './Info.module.css';
import { InfoIcon } from 'components/Editor/Common/Icons';

type Props = {
  message: any,
  showIcon?: boolean,
  margin?: 'zero' | 'min' | 'normal' | 'dense',
  size?: 'small' | 'large',
};

export const Info = (props: Props): React.Node => {
  const { message, showIcon = true, margin = 'normal', size = '' } = props;

  const classNames = [styles.info];
  if (margin === 'zero') {
    classNames.push(styles.zero);
  } else if (margin === 'dense') {
    classNames.push(styles.dense);
  } else if (margin === 'min') {
    classNames.push(styles.min);
  } else {
    classNames.push(styles.normal);
  }

  if (size === 'small') {
    classNames.push(styles.small);
  } else if (size === 'large') {
    classNames.push(styles.large);
  }

  return (
    <div className={classNames.join(' ')}>
      <span className={styles.message}>
        {showIcon && <InfoIcon />}
        {message}
      </span>
    </div>
  );
};
