// @flow
import * as React from 'react';
import { connect } from 'react-redux';

import styles from './ModuleWrapper.module.css';
import EditorUtils from 'components/Editor/Common/draft-js-anchor-plugin/utils/EditorUtils';
import { setAnchorSource } from 'actions/common';
import { updateAnchorSources } from 'actions/post';

type Props = {
  type?:
    | 'small'
    | 'medium'
    | 'large'
    | 'fullWidth'
    | 'fullGrid'
    | 'preset816'
    | 'preset1044'
    | 'listing',
  bgcolor?: 'blueGrey',
  id: string,
  anchorSource?: Object,
  setAnchorSource?: Function,
  updateAnchorSources?: Function,
  margin?: 'small' | 'min' | 'medium',
  sectionId?: number,
  sectionType?: string,
  children: any,
};

export const ModuleWrapper = ({
  type = 'large',
  margin = '',
  bgcolor = '',
  children,
  id,
  anchorSource,
  sectionId,
  sectionType,
  setAnchorSource,
  updateAnchorSources,
}: Props): React.Element<'div'> => {
  const classNames = [];

  const handleClick = (e) => {
    if (anchorSource && 'content' !== sectionType) {
      if ('container' !== id && sectionId) {
        const timestamp = Date.now().toString().slice(-6);
        const editorState = anchorSource.getEditorState();
        const anchorText = EditorUtils.getSelectionText(editorState);
        const newEditorState = EditorUtils.createLinkAtSelection(
          editorState,
          `#section-${sectionId}`,
          timestamp
        );
        anchorSource.setEditorState(newEditorState);
        if (updateAnchorSources) {
          updateAnchorSources({
            targetId: sectionId,
            sourceId: timestamp,
            sourceText: anchorText,
          });
        }
      }

      if (setAnchorSource) {
        setAnchorSource(null, null);
      }
    }
  };

  if (bgcolor === 'blueGrey') {
    classNames.push(styles.blueGrey);
  }

  if (type === 'fullWidth') {
    classNames.push(styles.moduleContainerFullWidth);
  } else if (type === 'medium') {
    classNames.push(styles.moduleContainerMedium);
  } else if (type === 'small') {
    classNames.push(styles.moduleContainerSmall);
  } else if (type === 'fullGrid') {
    classNames.push(styles.moduleGridFullWidth);
  } else if (type === 'preset816') {
    classNames.push(styles.preset816);
  } else if (type === 'preset1044') {
    classNames.push(styles.preset1044);
  } else if (type === 'listing') {
    classNames.push(styles.listingContainer);
  } else {
    classNames.push(styles.moduleContainer);
  }

  if (margin === 'min') {
    classNames.push(styles.min);
  } else if (margin === 'medium') {
    classNames.push(styles.medium);
  } else if (margin === 'small') {
    classNames.push(styles.small);
  }

  return (
    <div id={id} className={classNames.join(' ')} onClick={handleClick}>
      {children}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    anchorSource: state.common.anchorSource,
    anchorSourceId: state.common.anchorSourceId,
  };
};

export default (connect(mapStateToProps, {
  setAnchorSource,
  updateAnchorSources,
})(ModuleWrapper): any);
