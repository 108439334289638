export const initalLensValue = {
  lensType: 'Lente estándar',
  megaPixels: null,
  sensorSize: '',
  focalDistance: '',
  zoom: '',
  aperture: null,
  focus: '',
  stabilizer: 'No',
};
