const configs = {
  production: {
    s3ApiDomain: 'https://api2.weblogssl.com',
    host: 'http://admin.weblogssl.com',
    cloudName: 'weblogssl',
    uploadPreset: 'h2sbmprz',
    cssPath:
      'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css',
    rollbarToken: 'be647bf7ecd348548f2ca4ccd4f19956',
  },
  staging: {
    s3ApiDomain: 'https://testapi.weblogssl.com',
    host: 'http://test.admin.weblogssl.com',
    cloudName: 'weblogssl',
    uploadPreset: 'h2sbmprz',
    cssPath:
      'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css',
    rollbarToken: 'b19f089a5d104c76ba25b69d00fb4659',
  },
  development: {
    s3ApiDomain: 'https://testapi.weblogssl.com',
    host: 'http://test.admin.weblogssl.com',
    cloudName: 'weblogssl',
    uploadPreset: 'h2sbmprz',
    cssPath:
      'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css',
    rollbarToken: '1b4088a4c1d54aeeb5f0422b9e0d3dea',
  },
};

const configParams = configs[process.env.REACT_APP_ENV];

export default configParams;
