import * as React from 'react';
import { Button, IconButton as MUIIconButton } from '@material-ui/core';
import { indigo, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { ToolTipText } from 'components/Editor/Common/Tooltip';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from './Button.module.css';
const primary = indigo[500];
const primaryText = grey[900];

const button = createTheme({
  overrides: {
    MuiButton: {
      root: {
        width: '30px',
        height: '30px',
        background: '#fff',
        boxShadow:
          '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        fontSize: '16px',
        minWidth: '0',
        margin: '0',
        '&.Mui-disabled': {
          backgroundColor: '#e0e0e0',
          boxShadow:
            '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        },
      },
    },
  },
});

const defaultbutton = createTheme({
  overrides: {
    MuiButton: {
      root: {
        padding: '10px 16px',
        lineHeight: '16px',
      },
    },
  },
});

type Props = {
  label?: string,
  color?: string,
  icon?: Object,
  children?: React.Node,
  onClick?: Function,
  disabled?: boolean,
  active?: boolean,
  title?: string,
  type?: 'small' | 'large' | 'close',
  marginX?: 'min',
};

type FabProps = {
  Icon: Object,
  iconColor?: 'inherit' | 'disabled',
  title?: string,
  handleClick: (event: SyntheticEvent<HTMLImageElement>) => void,
  color?: string,
  disabled?: boolean,
};

const normalButtonStyles = {
  root: {
    backgroundColor: '#ffffff',
    width: 'auto',
    color: '#212121',
    maxHeight: '36px',
    lineHeight: '16px',
    padding: '10px 16px',

    '&:disabled': {
      color: '#A4A4A4',
      backgroundColor: '#e0e0e0',

      '& .MuiSvgIcon-root': {
        color: '#9e9e9e',
      },
    },
  },
};

const defaultOutlinedButtonStyles = {
  root: {
    background: 'transparent',
    borderColor: 'transparent',
    borderRadius: 0,
    color: primaryText,
    '&:hover': {
      borderColor: primary,
      color: primary,
    },
  },

  outlinedPrimary: {
    color: primary,
    borderColor: primary,
  },
};

const normalOutlinedButtonStyles = {
  root: {
    color: primaryText,
    borderRadius: '5px',
    lineHeight: '18px',

    '&:hover': {
      color: primaryText,
    },
  },
};

const iconButtonStyles = {
  root: {
    minWidth: '44px',
    height: '36px',
    padding: '0px',
    margin: '0 8px',
  },
};

const SmallIconButtonStyles = {
  root: {
    backgroundColor: '#ffffff',
    width: '16px',
    height: '16px',
    minWidth: '0',
    fontSize: '16px',
    padding: '0',

    '&:disabled': {
      backgroundColor: '#e0e0e0',
      boxShadow:
        '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    },
  },
};

const NormalButtonWithIconStyles = {
  root: {
    padding: '0',
    height: '36px',
    width: '40px',
    margin: '2px 4px',
    minWidth: '40px',
    backgroundColor: '#fff',

    '&:disabled': {
      backgroundColor: '#e0e0e0',
      boxShadow:
        '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    },
  },
};

const normalIconButtonStyles = {
  root: {
    minWidth: '44px',
    height: '36px',
    padding: '0px',
    background: '#ffffff',
    color: 'inherit',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      background: '#d5d5d5',
    },

    '&:disabled': {
      backgroundColor: '#e0e0e0',
    },
  },
};

const darkIconButtonStyles = {
  root: {
    minWidth: '44px',
    height: '36px',
    padding: '0px 19px',
    background: '#090909',
    color: '#ffffff',
    borderRadius: '4px',
    letterSpacing: ' 0.5px',
    lineHeight: '17px',

    boxShadow:
      '0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)',

    '&:hover': {
      background: '#212121',
    },

    '&:disabled': {
      backgroundColor: '#212121',
    },
  },
};

const smallButtonStyles = {
  root: {
    fontSize: '13px',
    lineHeight: '1',
    padding: '5px 8px',
    height: '30px',
  },
};

const darkButtonStyles = {
  root: {
    boxShadow: 'none',
    textTransform: 'none',
  },
};

const smallNormalIconButtonStyles = {
  root: {
    minWidth: '44px',
    height: '30px',
    background: '#ffffff',
    fontSize: '14px',
    padding: '8px 12px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      background: '#d5d5d5',
    },

    '&:disabled': {
      backgroundColor: '#e0e0e0',
    },
  },
};

const DefaultIconButtonStyles = {
  root: {
    padding: '0',
    height: '30px',
    width: '30px',
    minWidth: '0',
    background: 'transparent',
    marginRight: '8px',
    fontSize: '30px',

    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
};

const TransparentButtonStyles = {
  root: {
    padding: '0',
    height: '48px',
    width: '48px',
    minWidth: '0',
    background: 'transparent',
    marginRight: '0',
    fontSize: '48px',

    '&:disabled': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
};

const RoundedIconButtonStyles = {
  root: {
    padding: '0',
    height: '40px',
    width: '40px',
    minWidth: '0',
    borderRadius: '50%',
    boxShadow: 'none',
    lineHeight: '0',
    fontSize: '20px',
  },
};

const normalSmallButtonStyles = {
  root: {
    backgroundColor: '#ffffff',
    width: 'auto',
    color: '#212121',
    borderRadius: '2px',
    padding: '8px 8px 6px',
    fontSize: '13px',
    lineHeight: '16px',

    '&:disabled': {
      color: '#A4A4A4',
      backgroundColor: '#e0e0e0',
      boxShadow: 'none',
    },
  },
};

export const PrimaryButton = ({
  label = '',
  children,
  ...restProps
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <MuiThemeProvider theme={defaultbutton}>
    <Button variant="contained" color="primary" {...restProps}>
      {label}
      {children}
    </Button>
  </MuiThemeProvider>
);

export const PrimaryOutlinedButton = ({
  label = '',
  children,
  ...restProps
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <Button variant="outlined" color="primary" {...restProps}>
    {label}
    {children}
  </Button>
);

export const PrimaryOutlinedButtonLeftIcon = ({
  label = '',
  icon = {},
  color = 'inherit',
  children,
  ...restProps
}: Props): React.Node => {
  const Icon = icon;
  return (
    // $FlowFixMe: suppressing this error until we can refactor
    <Button variant="outlined" color="primary" {...restProps}>
      <Icon
        fontSize="small"
        htmlColor={color}
        className={styles.buttonLeftIcon}
      />
      {label}
    </Button>
  );
};

export const SecondaryButton = ({
  label = '',
  children,
  ...restProps
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <Button variant="contained" color="secondary" {...restProps}>
    {label}
    {children}
  </Button>
);
const DefaultButton = ({ label = '', children, ...restProps }: Props) => (
  // $FlowFixMe: suppressing this error until we can refactor
  <Button variant="contained" {...restProps}>
    {children}
    {label}
  </Button>
);

export const NormalButtonWithIcon: React$AbstractComponent<Props, any> =
  React.forwardRef(function NormalButtonIcon(props: Props, ref) {
    const { label = '', children, ...restProps } = props;
    return (
      // $FlowFixMe: suppressing this error until we can refactor
      <Button variant="contained" ref={ref} {...restProps}>
        {children}
        {label}
      </Button>
    );
  });
NormalButtonWithIcon.displayName = 'NormalButtonWithIcon';

export const NormalButtonLeftIcon = ({
  label = '',
  icon = '',
  color = 'inherit',
  children,
  ...restProps
}: Props): React.Node => {
  const Icon = icon;
  return (
    // $FlowFixMe: suppressing this error until we can refactor
    <Button variant="contained" {...restProps}>
      {icon && (
        <Icon
          fontSize="small"
          htmlColor={color}
          className={styles.buttonLeftIcon}
        />
      )}
      {label}
    </Button>
  );
};

export const NormalOutlinedButtonLeftIcon = ({
  label = '',
  icon = '',
  color = 'inherit',
  children,
  ...restProps
}: Props): React.Node => {
  const Icon = icon;
  return (
    // $FlowFixMe: suppressing this error until we can refactor
    <Button variant="outlined" {...restProps}>
      {icon && (
        <Icon
          fontSize="small"
          htmlColor={color}
          className={styles.buttonLeftIcon}
        />
      )}
      {label}
    </Button>
  );
};

export const DefaultButtonOutlined = ({
  label = '',
  active = false,
  children,
  ...restProps
}: Props): React.Node => (
  // $FlowFixMe This comment suppresses a Flow error
  <Button
    variant="outlined"
    color={active ? 'primary' : 'default'}
    {...restProps}
  >
    {label}
    {children}
  </Button>
);

export const NormalButtonOutlined = ({
  label = '',
  active = false,
  children,
  ...restProps
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <Button variant="outlined" {...restProps}>
    {label}
    {children}
  </Button>
);

export const TextButton = ({
  label = '',
  children,
  ...restProps
}: Props): React.Node => (
  <Button {...restProps}>
    {label}
    {children}
  </Button>
);

export const DefaultIconButton = ({
  color = 'primary',
  children,
  title = '',
  ...restProps
}: Props): React.Node => (
  <ToolTipText id="tooltip-icon" title={title}>
    {/* $FlowFixMe: suppressing this error until we can refactor */}
    <Button color={color} fontSize={'inherit'} {...restProps}>
      {children}
    </Button>
  </ToolTipText>
);

export const RoundedButton = ({
  children,
  ...restProps
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <Button variant="contained" fontSize="inherit" {...restProps}>
    {children}
  </Button>
);

export const DefaultSmallIconButton: any = withStyles(DefaultIconButtonStyles)(
  DefaultIconButton
);
export const NormalButton: any = withStyles(normalButtonStyles)(DefaultButton);

export const SmallNormalButton: any = withStyles(normalSmallButtonStyles)(
  DefaultButton
);

export const PrimaryButtonWithIcon: any =
  withStyles(iconButtonStyles)(PrimaryButton);
export const SecondaryButtonWithIcon: any =
  withStyles(iconButtonStyles)(SecondaryButton);

export const IconButton: any = withStyles(NormalButtonWithIconStyles)(
  NormalButtonWithIcon
);

export const SmallIconButton: any = withStyles(SmallIconButtonStyles)(
  DefaultButton
);

export const NormalButtonWithLeftIcon: any =
  withStyles(normalButtonStyles)(NormalButtonLeftIcon);

export const DarkButtonWithLeftIcon: any =
  withStyles(darkIconButtonStyles)(NormalButtonLeftIcon);

export const DarkButton: any = withStyles(darkButtonStyles)(
  DarkButtonWithLeftIcon
);

export const DefaultOutlinedButton: any = withStyles(
  defaultOutlinedButtonStyles
)(DefaultButtonOutlined);

export const NormalOutlinedButton: any = withStyles(normalOutlinedButtonStyles)(
  NormalButtonOutlined
);

export const NormalButtonOutlinedWithLeftIcon: any = withStyles(
  normalOutlinedButtonStyles
)(NormalOutlinedButtonLeftIcon);

export const FabButton = (props: FabProps): React.Node => {
  const {
    Icon,
    iconColor = 'inherit',
    title = '',
    handleClick,
    color = 'default',
    disabled = false,
  } = props;

  return (
    <ToolTipText id="tooltip-icon" title={title}>
      <Fab
        aria-label={title}
        onClick={handleClick}
        color={color}
        disabled={disabled}
      >
        <Icon htmlColor={iconColor} />
      </Fab>
    </ToolTipText>
  );
};

export const RoundedIconButton: any = withStyles(RoundedIconButtonStyles)(
  RoundedButton
);

const DefaultTransparentButton = withStyles(TransparentButtonStyles)(
  DefaultIconButton
);
export const TransparentButton = ({
  title = '',
  ...props
}: Props): React.Node => (
  <ToolTipText title={title}>
    <span>
      <DefaultTransparentButton color="inherit" {...props} />
    </span>
  </ToolTipText>
);

export const SquaredIconButton = ({
  color = 'primary',
  children,
  title = '',
  type,
  marginX = '',
  onClick = () => {},
  disabled = false,
}: Props): React.Node => {
  let sizeClass = [];

  if (type === 'large') {
    sizeClass.push(styles.large);
  } else if (type === 'small') {
    sizeClass.push(styles.small);
  } else if (type === 'close') {
    sizeClass.push(styles.close);
  }

  if (marginX === 'min') {
    sizeClass.push(styles.marginLeft);
  }
  return (
    <ToolTipText title={title}>
      <span>
        <MuiThemeProvider theme={button}>
          <Button
            fontSize="inherit"
            type=" "
            onClick={onClick}
            className={sizeClass.join(' ')}
            disabled={disabled}
          >
            {children}
          </Button>
        </MuiThemeProvider>
      </span>
    </ToolTipText>
  );
};

export const NormalIconButton: any = withStyles(normalIconButtonStyles)(
  DefaultIconButton
);

export const SmallNormalButtonLeftIcon: any = withStyles(
  smallNormalIconButtonStyles
)(NormalButtonWithLeftIcon);

export const SmallFontButtonLeftIcon: any = withStyles(smallButtonStyles)(
  NormalButtonWithLeftIcon
);

export const TransparentIconButton = ({
  children,
  ...props
}: Props): React.Node => {
  return <MUIIconButton {...props}>{children}</MUIIconButton>;
};
