import { ReviewType } from '../lib/flowTypes';

export const removeDeletedReviews = (reviews: ReviewType[]) => {
  const canonicalReview = reviews[0];
  if (canonicalReview && canonicalReview.title === 'deleted') {
    return [];
  }
  return reviews.filter(
    (review: ReviewType) => review && review.title !== 'deleted'
  );
};
