// @flow
import type { Node } from 'React';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { FullWidthDivider } from 'components/Editor/Common/Divider';
import { Grid } from 'components/Editor/Common/Grid/Grid';
import { CircularLoader } from 'components/Editor/Common/Loaders';
import { Image } from 'lib/flowTypes';
import { Uploader, Thumbnail } from '.';
import Dialog from 'components/Editor/Common/Dialog';
import { cancelLabel } from 'components/Editor/Escribir/lib/helpers';
import { editDesignField, editColumnDesignField } from 'actions/post';
import { Caption } from 'components/Editor/Common/Typography/Typo';
import { BasicLabel } from 'components/Editor/Common/Label/BasicLabel';
import { getTrimmedText } from 'lib/helpers';
import { MAX_GIF_SIZE } from 'lib/constants';
import styles from './ImagePanel.module.css';

const IMAGE_MIN_WIDTH = 1200;
const IMAGE_MIN_HEIGHT = 900;

type Props = {
  open: boolean,
  imageUrls: Array<Image>,
  id: string,
  siteName: string,
  isImageUploading: boolean,
  closeModal: () => void,
  openModal: (
    name: string,
    mode: string,
    prevModalData?: {},
    callback?: () => {}
  ) => void,
  editField: (field: string, value: string) => void,
  onSubmit?: () => {},
  changeSelectedImage: (image: Image) => void,
  startUpload: () => void,
  endUpload: () => void,
  mode: string,
  isMobile: boolean,
  type: 'post' | 'product',
  prevModalData: {
    name: string,
    mode: string,
    section: {},
    sectionIndex: string,
  },
};

export function ImagePanel({
  open,
  imageUrls,
  id,
  siteName,
  closeModal,
  openModal,
  changeSelectedImage,
  isImageUploading,
  startUpload,
  endUpload,
  mode,
  isMobile,
  prevModalData,
  editField,
  type,
  onSubmit = () => {},
}: Props): Node {
  const dispatch = useDispatch();
  const [erroredFiles, setErroredFiles] = useState({
    invalidDimensionFile: '',
    invalidSizeFiles: [],
  });
  const {
    name: prevModalName,
    mode: prevModalMode,
    sectionIndex = '',
  } = prevModalData;
  const showPanel = isImageUploading || imageUrls.length > 0;

  const goBack = () => {
    openModal(prevModalName, prevModalMode, prevModalData);
  };

  const dialogActions =
    'richContent' === prevModalName
      ? {
          cancelLabel: cancelLabel('Editar código'),
          onCancel: goBack,
        }
      : {};

  const handleImageClick = (image) => {
    changeSelectedImage(image);
    if (['addProduct', 'hook', 'editFichaCompra'].includes(prevModalName)) {
      openModal(prevModalName, prevModalMode, prevModalData);
    } else if ('landingImage' === mode) {
      if ('product' === type) {
        editField('primaryImage', image);
      } else {
        if (
          image.hasOwnProperty('extension') &&
          image.extension === 'gif' &&
          image.hasOwnProperty('size') &&
          MAX_GIF_SIZE <= image.size
        ) {
          setErroredFiles({
            ...erroredFiles,
            invalidSizeFiles: [image.name],
          });
          return;
        }
        const primaryImageUrl = `${image.src}/original.${image.extension}`;
        editField('primaryImage', primaryImageUrl);
      }
      closeModal();
    } else if ('backgroundImage' === prevModalName) {
      const backgroundImage = `${image.src}/original.${image.extension}`;
      const isColumnNode = sectionIndex && sectionIndex.indexOf('-') > -1;
      if (isColumnNode) {
        dispatch(
          editColumnDesignField(
            sectionIndex,
            'backgroundImage',
            backgroundImage
          )
        );
      } else {
        dispatch(
          editDesignField(sectionIndex, 'backgroundImage', backgroundImage)
        );
      }
      closeModal();
    } else if ('singular' === prevModalName) {
      openModal('imageCrop', '', {
        ...prevModalData,
        name: 'imagePanel',
      });
    } else if ('product' === type) {
      openModal(
        'imageCropper',
        mode,
        { name: 'imagePanel', mode, data: { ...image } },
        onSubmit
      );
    } else if (['pivotEcommerce', 'fichaCompraV2'].includes(prevModalName)) {
      openModal('imageCropper', mode, prevModalData, onSubmit);
    } else if ('gameReview' === prevModalName) {
      openModal('imageCropper', mode, { ...prevModalData });
    } else if (mode === 'mainImage') {
      if (image.width < IMAGE_MIN_WIDTH || image.height < IMAGE_MIN_HEIGHT) {
        setErroredFiles({ ...erroredFiles, invalidDimensionFile: image.name });
        return;
      } else {
        openModal('primaryImageCrop', mode);
      }
    } else {
      openModal('imageAltDialog', mode);
    }
  };

  const imageThumbs = imageUrls.map((image, index) => (
    <li key={index} className={styles.imagePanel}>
      <Thumbnail
        image={image}
        handleClick={onSubmit ? () => handleImageClick(image) : null}
      />
      <Caption>{getTrimmedText(image.name, 30)}</Caption>
    </li>
  ));

  const scrollToTop = () => {
    const panel = document.getElementById('imagePanel');
    if (panel && panel.scrollTop > 0) {
      panel.scrollTop = 0;
    }
  };

  const getImgUploaderHint = () => {
    if ('landingImage' === mode) {
      return 'Máximo 8Mb para imágenes, y 300Kb para GIFs';
    }
    return 'Máximo 8Mb cada una';
  };

  const handleClose = () => {
    const { name = '' } = prevModalData;
    if ('' !== name) {
      goBack();
    } else {
      closeModal();
    }
  };

  return (
    <Dialog
      open={open}
      title={
        'richContent' === prevModalName
          ? 'Código personalizado - Imagen alternativa'
          : 'Imágenes'
      }
      titleHint="Haz click en la que quieras seleccionar"
      onClose={handleClose}
      size="large"
      spacing="zero"
      {...dialogActions}
    >
      <div className={styles.height}>
        {showPanel && (
          <ul id="imagePanel" className={styles.imagePanelContainer}>
            {scrollToTop()}
            {isImageUploading && (
              <li className={styles.imagePanel}>
                <CircularLoader
                  show={isImageUploading}
                  className={'progress-bar'}
                  size={40}
                />
              </li>
            )}
            {imageThumbs.reverse()}
          </ul>
        )}
      </div>
      <div className={styles.imgUploader}>
        <Grid container gutter="custom" spacing={2}>
          <Grid item xs={12}>
            <FullWidthDivider />
          </Grid>
          <Grid item xs={12}>
            <BasicLabel label="Subir imágenes" hint={getImgUploaderHint()} />
          </Grid>
          <Grid item xs={12}>
            <FullWidthDivider />
          </Grid>
        </Grid>
        <Uploader
          id={id}
          isMobile={isMobile}
          site={siteName}
          isUploading={isImageUploading}
          startUpload={startUpload}
          endUpload={endUpload}
          type={type}
          mode={mode}
          erroredFiles={erroredFiles}
        />
      </div>
    </Dialog>
  );
}
