// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MUCheckbox from '@material-ui/core/Checkbox';
import { CheckBoxOutlineBlankIcon } from 'components/Editor/Common/Icons';
import { Caption } from 'components/Editor/Common/Typography/Typo';
import styles from './Checkbox.module.css';

const style = () => ({
  root: (props) => ({
    height: props.size === 'small' ? 'auto' : '18px',
    padding: props.size === 'small' ? '2px 6px' : '3px 8px',
  }),
  disabled: {
    opacity: '0.40',
    pointerEvents: 'none',
  },
});

type Props = {
  label: string | React.Node,
  disabled?: boolean,
  value?: string,
  key?: string,
  checked: boolean | string,
  onChange: Function,
  hint?: string,
  margin?: 'dense' | 'min' | 'zero',
  size?: 'small',
};

const checkbox = ({
  label = '',
  hint = '',
  margin = '',
  size = '',
  ...restProps
}: Props) => {
  const classNames = [styles.layoutCheckboxForm];

  if (margin === 'dense') {
    classNames.push(styles.dense);
  } else if (margin === 'min') {
    classNames.push(styles.min);
  } else if (margin === 'zero') {
    classNames.push(styles.zero);
  }

  if (size === 'small') {
    classNames.push(styles.small);
  }

  return (
    <FormGroup>
      <FormControlLabel
        // $FlowFixMe: suppressing this error until we can refactor
        control={
          <MUCheckbox
            {...restProps}
            color="primary"
            icon={<CheckBoxOutlineBlankIcon />}
          />
        }
        className={classNames.join(' ')}
        label={label}
      />
      {hint !== '' && (
        <span className={styles.hintMargin}>
          <Caption faded>{hint}</Caption>
        </span>
      )}
    </FormGroup>
  );
};

export const Checkbox: any = withStyles(style)(checkbox);
