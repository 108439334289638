import React, { Component } from 'react';
import unionClassNames from 'union-class-names';
import AddLinkForm from './AddLinkForm';
import styles from './Anchor.module.css';

type Props = {
  store: Object,
  ownTheme: Object,
  onOverrideContent: Function,
  ownTheme: Object,
  theme: Object,
  addLink: Function,
  placeholder: string,
  getUrl: Function,
};

export default class LinkButton extends Component<Props> {
  onMouseDown = (event) => {
    event.preventDefault();
  };

  onAddLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { ownTheme, placeholder, onOverrideContent, addLink, getUrl } =
      this.props;
    const content = (props) => (
      <AddLinkForm
        {...props}
        placeholder={placeholder}
        theme={ownTheme}
        addLink={addLink}
        getUrl={getUrl}
      />
    );
    onOverrideContent(content);
  };

  render() {
    const { theme, store, getUrl } = this.props;
    const { url, isAnchor } = getUrl(store.getEditorState());

    let className = unionClassNames('toolbarLink', theme.button);
    if (url && !isAnchor) {
      className = unionClassNames(className, theme.active);
    }

    return (
      <div className={theme.buttonWrapper} onMouseDown={this.onMouseDown}>
        <button
          className={className}
          onClick={this.onAddLinkClick}
          type="button"
        >
          <svg
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
          </svg>
          <span className={styles.toolTiptext}>Enlace [Ctrl + L]</span>
        </button>
      </div>
    );
  }
}
