// @flow
import {
  postImage as postImageToS3,
  postZip as postZipToS3,
} from 'lib/s3ImageUploadService';
import { postImage as postImageToFirebase } from './imageUploadService';
import { getImageExtension, getImageName } from 'lib/helpers';

type UploadImageProps = {
  id: string,
  site: string,
  data: FormData,
  type: 'post' | 'product',
  size?: number,
};

const MAX_FILE_SIZE = 8388608;
const ERROR_INVALID_FORMAT =
  'no es una imagen válida. Puedes usar JPG, PNG y GIF';
const ERROR_INVALID_SIZE = 'es mayor de 8MB';

const validateImage = (name: string, size: number) => {
  if (!validateSize(size)) {
    return false;
  }

  const extension = getImageExtension(name);
  if (extension && !validateExtension(extension)) {
    return false;
  }

  return true;
};

const filterInvalidFiles = (targetFiles: Object[]) => {
  const files = [];

  for (let file of targetFiles) {
    if (!validateImage(file.name, file.size)) {
      continue;
    }

    files.push(file);
  }

  return files;
};

export const uploadImages = async (
  files: Object,
  firebaseId: string,
  site: string,
  type: 'post' | 'product'
) => {
  const validFiles = filterInvalidFiles(files);
  const images = await uploadImagesToS3Bucket(site, validFiles);
  for (let image of images) {
    if (!image.src || !validateExtension(image.extension)) {
      throw new Error('Image not uploaded');
    }
  }
  await uploadImagesToFirebase(firebaseId, images, type);
  return images;
};

export const validateExtension = (
  extension: string
): null | RegExp$matchResult =>
  extension.toLowerCase().match(/(jpg|jpeg|png|gif)$/g);

export const uploadImage = async ({
  id,
  site,
  data,
  type,
  size = null,
}: UploadImageProps): Promise<{| status: number |}> => {
  try {
    const image = await postImageToS3(site, data, size);
    if (image.src) {
      if (!validateExtension(image.extension)) {
        return { status: 500 };
      }

      image.name = getImageName(image);
      postImageToFirebase(id, image, type);
      return { status: 200 };
    } else {
      return { status: 500 };
    }
  } catch (e) {
    return { status: 500 };
  }
};

const validateSize = (size: number) => size <= MAX_FILE_SIZE;

export const getErrorMessages = (files: Object[]): Array<any | string> => {
  const errorMessages = [];
  for (let file of files) {
    const { name, size } = file;
    const extension = getImageExtension(name);

    if (extension && !validateExtension(name)) {
      errorMessages.push(`- ${name} ${ERROR_INVALID_FORMAT}`);
      continue;
    }

    if (!validateSize(size)) {
      errorMessages.push(`- ${name} ${ERROR_INVALID_SIZE}`);
      continue;
    }
  }

  return errorMessages;
};

const getFormData = (files: Object[]) => {
  const formData = [];
  for (let file of files) {
    let data = new FormData();
    data.append('file', file);
    formData.push({ data, name: file.name, size: file.size });
  }

  return formData;
};

export const uploadImagesToS3Bucket = async (site: string, files: Object[]) => {
  const formData = getFormData(files);
  const promises = [];
  for (let file of formData) {
    const { data, size } = file;
    promises.push(postImageToS3(site, data, size));
  }

  return await Promise.all(promises);
};

export const uploadZipToS3Bucket = async (site: string, file: Object) => {
  const formData = getFormData(file);
  const { data, size } = formData[0];
  data.append('extract', 'true');
  return await postZipToS3(site, data, size);
};

export const uploadImagesToFirebase = async (
  id: string,
  images: Object[],
  type: 'post' | 'product'
) => {
  for (let image of images) {
    image.name = getImageName(image);
    postImageToFirebase(id, image, type);
  }
};
