// @flow
import * as React from 'react';

import { BasicLabel } from './BasicLabel';
import styles from './Label.module.css';

type Props = {
  label: string,
  hint?: any,
};

type ElectedItemProps = {
  isManual?: boolean,
  label: number,
};

export const Label = ({ label, hint }: Props): React.Element<'div'> => {
  return (
    <div className={styles.uHSeperator}>
      <BasicLabel label={label} hint={hint} />
    </div>
  );
};

export const ModalLabel = ({ label }: Props): React.Element<'label'> => {
  return <label className={styles.label}>{label}</label>;
};

export const ElectedItemLabel = ({
  label,
  isManual = false,
}: ElectedItemProps): React.Element<'label'> => {
  return (
    <label className={isManual ? styles.electedManualItemLabel : ''}>
      {label}
    </label>
  );
};
