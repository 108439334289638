// @flow
import * as React from 'react';
import {
  WriteModeIcon,
  VisibilityIcon,
} from 'components/Editor/Common/CustomSVGIcons';
import { DesignModeIcon } from 'components/Editor/Common/Icons';
import { Container } from 'components/Editor/Common/Container/Container';

type Props = {
  mode?: 'design' | 'write' | 'hidden',
  onClick?: () => void,
  bgcolor?: string,
};

export const ModeButton = ({
  mode = 'write',
  onClick,
  bgcolor,
}: Props): React.Node => {
  return (
    <Container
      size="small"
      align="center"
      bgcolor={bgcolor}
      style={{ zIndex: '2' }}
      onClick={onClick}
    >
      {
        {
          design: <DesignModeIcon />,
          write: <WriteModeIcon />,
          hidden: <VisibilityIcon />,
        }[mode]
      }
    </Container>
  );
};
