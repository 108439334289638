export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const TOGGLE_DESIGN_MODE = 'TOGGLE_DESIGN_MODE';
export const ENABLE_DESIGN_MODE_ACCESS = 'ENABLE_DESIGN_MODE_ACCESS';
export const CHANGE_CURRENT_INDEX = 'CHANGE_CURRENT_INDEX';
export const CHANGE_POSITION = 'CHANGE_POSITION';
export const OPEN_TOOLBAR = 'OPEN_TOOLBAR';
export const SET_ANCHOR_SOURCE = 'SET_ANCHOR_SOURCE';
export const TOGGLE_DELINK_WARNING = 'TOGGLE_DELINK_WARNING';
export const SHOW_TOC_WARNING = 'SHOW_TOC_WARNING';

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

export const showSnackbar = (message, messageType = 'info', iconType = '') => ({
  type: SHOW_SNACKBAR,
  message,
  messageType,
  iconType,
});

export const handleTocWarning = (showTocWarning: boolean) => ({
  type: SHOW_TOC_WARNING,
  showTocWarning,
});

export const toggleDesignMode = () => ({
  type: TOGGLE_DESIGN_MODE,
});

export const openToolbar = (
  open: boolean,
  index = undefined,
  anchorEl = null,
  anchorPosition = null
) => ({
  type: OPEN_TOOLBAR,
  open,
  index,
  anchorEl,
  anchorPosition,
});

export const enableDesignModeAccess = () => ({
  type: ENABLE_DESIGN_MODE_ACCESS,
});

export const toggleDelinkWarning = () => ({
  type: TOGGLE_DELINK_WARNING,
});

export const setAnchorSource = (
  anchorSourceId: number,
  anchorSource: Object
) => ({
  type: SET_ANCHOR_SOURCE,
  anchorSource,
  anchorSourceId,
});
