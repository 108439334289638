// @flow
import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';

import { listenToPostActiveUsers } from 'containers/lib/service';
import { Snackbar } from 'components/Editor/Common/Snackbar';
import { TextButton } from 'components/Editor/Common/Buttons/Button';
import { dumpJson } from 'lib/helpers';

type Props = {
  postName: string,
  visitorId: string,
};

const getMessage = (activeUsers, visitorId) => {
  let activeUsersName = [];
  for (const key in activeUsers) {
    const username = activeUsers[key].username;
    if (key !== visitorId && activeUsersName.indexOf(username) === -1) {
      activeUsersName.push(username);
    }
  }

  const totalActiveUsers = activeUsersName.length;
  if (1 === totalActiveUsers) {
    return (
      <span>
        <b>{activeUsersName[0]}</b> tiene este post abierto en otro sitio.
        Comprueba que está abierto sólo una vez antes de hacer cambios, o se
        pueden perder.
      </span>
    );
  }

  const getUserName = (username: string, key: number) => {
    if (key === totalActiveUsers - 1) {
      return (
        <>
          {' '}
          y <b>{username}</b>
        </>
      );
    }
    return (
      <b>
        {key > 0 ? ', ' : ''} {username}
      </b>
    );
  };

  return (
    <span>
      {activeUsersName.map((name, key) => getUserName(name, key))} tienen este
      post abierto en otro sitio. Asegúrate de que está abierto sólo una vez
      antes de hacer cambios o se pueden perder.
    </span>
  );
};

const MultipleActivePostUserMessage = ({ postName, visitorId }: Props) => {
  const [activeUsers, setActiveUsers] = useState({});

  useEffect(() => {
    if (postName) {
      listenToPostActiveUsers(postName, setActiveUsers);
    }
  }, [postName]);

  const store = useStore();
  const { post, sections } = store.getState();

  if (!activeUsers || 2 > Object.keys(activeUsers).length) {
    return null;
  }

  const createBackup = () => {
    const content = {
      postType: post.postType,
      sections,
      siteName: post.siteName,
      title: post.title,
      primaryImage: post.primaryImage,
      subTitle: post.subTitle || null,
      maxId: post.maxId,
    };

    return dumpJson(content, post.id);
  };

  return (
    <Snackbar
      message={getMessage(activeUsers, visitorId)}
      messageType="warning"
      iconType="info"
      layout="column"
      size="extra-large"
      action={
        <TextButton size="small" onClick={createBackup}>
          Descargar contenido
        </TextButton>
      }
    />
  );
};

export default MultipleActivePostUserMessage;
