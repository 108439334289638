// @flow
import * as React from 'react';
import { PlayArrowIcon, PauseIcon } from 'components/Editor/Common/Icons';
import { useDispatch, useSelector } from 'react-redux';

import { toggleDesignMode } from 'actions/common';
import {
  editSection,
  editGridSection,
  changeTableAlignment,
  editTable,
} from 'actions/post';
import {
  SmallLeftIcon,
  SmallCenterIcon,
  SmallRightIcon,
  NormalIcon,
  BigIcon,
  EdgeIcon,
} from 'components/Editor/Common/svgIcons';
import {
  ToolbarIcon,
  SeparatorToolbar,
} from 'components/Editor/Common/Toolbar';
import {
  DesignModeIcon,
  EqualWidthColumnIcon,
  FormatAlignLeftIcon,
  FormatAlignRightIcon,
  VisibilityIcon as Visibility,
} from 'components/Editor/Common/Icons';
import {
  BackgroundColorFillIcon,
  FormatColorTextIcon,
  WriteModeIcon,
  BackgroundImageIcon,
  VisibilityIcon,
  UndoGridIcon,
  AnchorDeLink,
  HighlightTableIcon,
  LockIcon,
  H3Icon,
} from 'components/Editor/Common/CustomSVGIcons';
import { InputEvent } from 'lib/flowTypes';
import { getSection } from 'components/Editor/Escribir/lib/helpers';
import { Popper, MenuItem, MenuList } from 'components/Editor/Common';

type Props = {
  active?: boolean,
  handleClick: (e: InputEvent) => void,
};

type VisibilityIconProps = {
  closeToolbar: () => void,
};

type LayoutProps = {
  handleClick: (layout: string, align: string) => void,
  selectedKey: string,
  disabled?: boolean,
  tooltip?: string,
};

const getColor = (layout: string, align: string, selectedKey: string) => {
  if (
    selectedKey === `${layout}-${align || 'center'}` ||
    selectedKey === `${layout}-${align}`
  ) {
    return 'secondary';
  }
  return 'inherit';
};

export const SmallLeftToolbar = ({
  selectedKey,
  handleClick,
  disabled = false,
}: LayoutProps): React.Node => (
  <ToolbarIcon
    ActionIcon={
      <SmallLeftIcon color={getColor('small', 'left', selectedKey)} />
    }
    handleClick={() => handleClick('small', 'left')}
    tooltip="Pequeño, a la izquierda"
    disabled={disabled}
  />
);
export const SmallCenterToolbar = ({
  selectedKey,
  handleClick,
  disabled = false,
}: LayoutProps): React.Node => (
  <ToolbarIcon
    ActionIcon={<SmallCenterIcon color={getColor('small', '', selectedKey)} />}
    handleClick={() => handleClick('small', '')}
    tooltip="Pequeño, centrado"
    disabled={disabled}
  />
);

export const SmallRightToolbar = ({
  selectedKey,
  handleClick,
  disabled = false,
}: LayoutProps): React.Node => (
  <ToolbarIcon
    ActionIcon={
      <SmallRightIcon color={getColor('small', 'right', selectedKey)} />
    }
    handleClick={() => handleClick('small', 'right')}
    tooltip="Pequeño, a la derecha"
    disabled={disabled}
  />
);

export const NormalCenterToolbar = ({
  selectedKey,
  handleClick,
  disabled = false,
}: LayoutProps): React.Node => (
  <ToolbarIcon
    ActionIcon={<NormalIcon color={getColor('normal', '', selectedKey)} />}
    handleClick={() => handleClick('normal', '')}
    tooltip="Mediano"
    disabled={disabled}
  />
);

export const LargeCenterToolbar = ({
  selectedKey,
  handleClick,
  disabled = false,
  tooltip = 'Grande',
}: LayoutProps): React.Node => (
  <ToolbarIcon
    ActionIcon={<BigIcon color={getColor('large', '', selectedKey)} />}
    handleClick={() => handleClick('large', '')}
    tooltip={tooltip}
    disabled={disabled}
  />
);

export const EdgeToolbar = ({
  selectedKey,
  handleClick,
  disabled = false,
  tooltip = 'Gigante',
}: LayoutProps): React.Node => (
  <ToolbarIcon
    ActionIcon={<EdgeIcon color={getColor('edge', '', selectedKey)} />}
    handleClick={() => handleClick('edge', '')}
    tooltip={tooltip}
    disabled={disabled}
  />
);

export const PlayToolbar = ({ handleClick }: Props): React.Node => (
  <ToolbarIcon
    ActionIcon={<PlayArrowIcon />}
    handleClick={handleClick}
    tooltip={'Ver animación'}
  />
);

export const UndoGrid = ({ handleClick }: Props): React.Node => (
  <ToolbarIcon
    ActionIcon={<UndoGridIcon />}
    handleClick={handleClick}
    tooltip={'Deshacer grid'}
  />
);

export const PauseToolbar = ({ handleClick }: Props): React.Node => (
  <ToolbarIcon
    ActionIcon={<PauseIcon />}
    handleClick={handleClick}
    tooltip={'Parar animación'}
  />
);

export { SeparatorToolbar };

export const DesignModeToolbar = ({
  refreshToolbar,
}: {
  refreshToolbar: () => void,
}): React.Node => {
  const dispatch = useDispatch();

  return (
    <ToolbarIcon
      ActionIcon={<DesignModeIcon htmlColor={'#000'} />}
      handleClick={() => {
        dispatch(toggleDesignMode());
        refreshToolbar();
      }}
      tooltip={'Herramientas de diseño'}
    />
  );
};

export const WriteModeToolbar = ({
  refreshToolbar,
}: {
  refreshToolbar: () => void,
}): React.Node => {
  const dispatch = useDispatch();
  return (
    <ToolbarIcon
      ActionIcon={<WriteModeIcon htmlColor="#000" />}
      handleClick={() => {
        dispatch(toggleDesignMode());
        refreshToolbar();
      }}
      tooltip={'Herramientas de escritura'}
      color="inherit"
    />
  );
};

export const BackgroundColorToolbarIcon = ({
  active = false,
  handleClick,
  ...rest
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <ToolbarIcon
    ActionIcon={
      <BackgroundColorFillIcon color={active ? 'secondary' : 'inherit'} />
    }
    handleClick={handleClick}
    tooltip="Color de fondo"
    {...rest}
  />
);

export const FormatColorToolbarIcon = ({
  active = false,
  handleClick,
  ...rest
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <ToolbarIcon
    ActionIcon={
      <FormatColorTextIcon color={active ? 'secondary' : 'inherit'} />
    }
    handleClick={handleClick}
    tooltip="Color del texto"
    {...rest}
  />
);

export const ExpandToolbarIcon = ({
  active = false,
  handleClick,
  ...rest
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <ToolbarIcon
    ActionIcon={<Visibility color={active ? 'secondary' : 'inherit'} />}
    handleClick={handleClick}
    {...rest}
  />
);

export const H3ToolbarIcon = ({
  active = false,
  handleClick,
  ...rest
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <ToolbarIcon
    ActionIcon={<H3Icon color={active ? 'secondary' : 'inherit'} />}
    handleClick={handleClick}
    tooltip="Incluir H3 en el índice"
    {...rest}
  />
);

export const BackgroundImageToolbarIcon = ({
  active = false,
  handleClick,
  ...rest
}: Props): React.Node => (
  // $FlowFixMe: suppressing this error until we can refactor
  <ToolbarIcon
    ActionIcon={
      <BackgroundImageIcon color={active ? 'secondary' : 'inherit'} />
    }
    handleClick={handleClick}
    tooltip="Imagen de fondo"
    {...rest}
  />
);

export const VisibilityToolbarIcon = ({
  closeToolbar,
}: VisibilityIconProps): React.Node => {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.common.currentIndex);
  const isHidden = useSelector((state) => {
    const section = getSection(index, state.sections);
    return section.isHidden || false;
  });

  const toggleVisibility = () => {
    const isColumnNode = index && index.indexOf('-') > -1;
    if (isColumnNode) {
      dispatch(editGridSection({ index, isHidden: !isHidden }));
    } else {
      dispatch(editSection({ index, isHidden: !isHidden }));
    }
    closeToolbar();
  };

  return (
    <ToolbarIcon
      ActionIcon={<VisibilityIcon color={isHidden ? 'secondary' : 'inherit'} />}
      handleClick={toggleVisibility}
      tooltip={isHidden ? 'Mostar' : 'Ocultar'}
    />
  );
};

export const LockToolbarIcon = ({
  closeToolbar,
}: {
  closeToolbar: Function,
}): React.Node => {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.common.currentIndex);
  const isLocked = useSelector((state) => {
    const section = getSection(index, state.sections);
    return section.isLocked || false;
  });

  const toggleLocked = () => {
    const isColumnNode = index && index.indexOf('-') > -1;
    if (isColumnNode) {
      dispatch(editGridSection({ index, isLocked: !isLocked }));
    } else {
      dispatch(editSection({ index, isLocked: !isLocked }));
    }
    closeToolbar();
  };

  return (
    <ToolbarIcon
      ActionIcon={<LockIcon color={isLocked ? 'secondary' : 'inherit'} />}
      handleClick={toggleLocked}
      tooltip={isLocked ? 'Desbloquear' : 'Bloquear'}
    />
  );
};

type DelinkProps = {
  anchorSources: [string, string][],
  handleDelink: (source: [string, string]) => void,
};

export const DelinkToolbar = ({
  anchorSources,
  handleDelink,
}: DelinkProps): React.Node => {
  const [anchorEl, setAnchorEl] = React.useState();

  const handleClick = (e: InputEvent) => {
    if (anchorSources.length === 1) {
      handleDelink(anchorSources[0]);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };

  const links = anchorSources.map((source, i) => (
    <MenuItem key={i} onClick={() => handleDelink(source)}>
      {source[1]}
    </MenuItem>
  ));

  return (
    <>
      <ToolbarIcon
        ActionIcon={<AnchorDeLink color="secondary" />}
        handleClick={handleClick}
        tooltip="Deshacer ancla"
      />
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        handleClickAwayListener={() => setAnchorEl(null)}
      >
        <MenuList>{links}</MenuList>
      </Popper>
    </>
  );
};

export const EqualWidthColumnToolbar = ({
  closeToolbar,
}: {
  closeToolbar: Function,
}): React.Node => {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.common.currentIndex);
  const equalWidth = useSelector((state) => {
    const section = getSection(index, state.sections);
    return section.equalWidth || false;
  });
  const handleClick = () => {
    const isColumnNode = index && index.indexOf('-') > -1;
    const section = {
      index,
      equalWidth: !equalWidth,
    };
    if (isColumnNode) {
      dispatch(editGridSection(section));
    } else {
      dispatch(editSection(section));
    }
    closeToolbar();
  };

  return (
    <ToolbarIcon
      ActionIcon={
        <EqualWidthColumnIcon color={equalWidth ? 'secondary' : 'inherit'} />
      }
      handleClick={handleClick}
      tooltip="Columnas del mismo ancho"
    />
  );
};

export const AlignLeftToolbar = ({
  closeToolbar,
}: {
  closeToolbar: Function,
}): React.Node => {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.common.currentIndex);
  const justify = useSelector((state) => {
    const section = getSection(index, state.sections);
    return section.justify || 'left';
  });
  const handleClick = () => {
    dispatch(changeTableAlignment(index, 'left'));
    closeToolbar();
  };

  return (
    <ToolbarIcon
      ActionIcon={
        <FormatAlignLeftIcon
          color={justify === 'left' ? 'secondary' : 'inherit'}
        />
      }
      handleClick={handleClick}
      tooltip="Alineación izquierda"
    />
  );
};

export const AlignRightToolbar = ({
  closeToolbar,
}: {
  closeToolbar: Function,
}): React.Node => {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.common.currentIndex);
  const justify = useSelector((state) => {
    const section = getSection(index, state.sections);
    return section.justify || 'left';
  });
  const handleClick = () => {
    dispatch(changeTableAlignment(index, 'right'));
    closeToolbar();
  };

  return (
    <ToolbarIcon
      ActionIcon={
        <FormatAlignRightIcon
          color={justify === 'right' ? 'secondary' : 'inherit'}
        />
      }
      handleClick={handleClick}
      tooltip="Alineación derecha"
    />
  );
};

export const HighlightToolbar = ({
  closeToolbar,
}: {
  closeToolbar: Function,
}): React.Node => {
  const dispatch = useDispatch();
  const index = useSelector((state) => state.common.currentIndex);
  const [tableIndex, rowIndex, colIndex] = index.split(':');
  const tableSection = useSelector((state) =>
    getSection(tableIndex, state.sections)
  );
  const isDisabled =
    (tableSection.hasRowHeader && rowIndex === '0') ||
    (tableSection.hasColumnHeader && colIndex === '0');
  const isHighlighted = useSelector((state) => {
    if (isDisabled) {
      return false;
    }
    const section = getSection(index, state.sections);
    return section.isHighlighted || false;
  });

  const handleClick = () => {
    dispatch(editTable(index, { isHighlighted: !isHighlighted }));
    closeToolbar();
  };

  return (
    <ToolbarIcon
      ActionIcon={
        <HighlightTableIcon color={isHighlighted ? 'secondary' : 'inherit'} />
      }
      handleClick={handleClick}
      disabled={isDisabled}
      tooltip="Resaltar"
    />
  );
};
