// @flow
import { Image } from 'lib/flowTypes';

export const RECEIVE_IMAGES = 'RECEIVE_IMAGES';
export const CHANGE_SELECTED_IMAGE = 'CHANGE_SELECTED_IMAGE';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CHANGE_UPLOADING_STATUS = 'CHANGE_UPLOADING_STATUS';

export const receiveImages = (
  images: Image[]
): {| images: Array<any>, type: string |} => ({
  type: RECEIVE_IMAGES,
  images,
});

export const changeSelectedImage = (
  image: Image
): {| image: any, type: string |} => ({
  type: CHANGE_SELECTED_IMAGE,
  image,
});

export const startUpload = (): {|
  isImageUploading: boolean,
  type: string,
|} => ({
  type: CHANGE_UPLOADING_STATUS,
  isImageUploading: true,
});

export const endUpload = (): {| isImageUploading: boolean, type: string |} => ({
  type: CHANGE_UPLOADING_STATUS,
  isImageUploading: false,
});
