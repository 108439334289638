// @flow
import type { Node } from 'React';
import React from 'react';
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

type Props = {
  onChange: (date: string) => void,
  value: string | null,
  label: string,
};

const theme = createTheme({
  palette: {
    error: {
      main: '#C52100',
    },
  },

  overrides: {
    MuiFormControl: {
      marginDense: {
        marginTop: '0 !important',
      },
    },

    MuiInputAdornment: {
      positionStart: {
        marginRight: '0 !important',
      },

      positionEnd: {
        marginLeft: '0 !important',
      },
    },

    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: '0px !important',
      },

      adornedStart: {
        paddingLeft: '0px',
        overflow: 'hidden',
      },
    },

    MuiPopover: {
      root: {
        zIndex: '2400 !important',
      },
    },
  },
});

class LocalizedUtils extends MomentUtils {
  getCalendarHeaderText(date) {
    return date.format('MMMM [de] yyyy');
  }
}

const DatePicker = ({ value, onChange, label, ...otherProps }: Props): Node => (
  <>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LocalizedUtils} locale="es">
        {/* $FlowFixMe This comment suppresses a Flow error */}
        <KeyboardDatePicker
          fullWidth
          autoOk
          disableToolbar
          variant="inline"
          inputVariant="outlined"
          label={label}
          value={value}
          margin="dense"
          onChange={onChange}
          InputAdornmentProps={{ position: 'start' }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...otherProps}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </>
);

export default DatePicker;
