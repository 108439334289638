// @flow
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const getTheme = (margin) =>
  createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          maxWidth: '100%',
        },

        tooltipPlacementBottom: {
          margin: `${margin}px !important`,
        },
        tooltipPlacementTop: {
          margin: `${margin}px !important`,
        },
      },
    },
  });

type props = {
  title: string,
  placement?: string,
  margin?: 'zero' | 'normal',
  children: any,
};

export const ToolTipText = ({
  title = '',
  placement = 'bottom',
  margin = 'normal',
  children,
}: props): React.Node => {
  let value = '';

  if (margin === 'normal') {
    value = 8;
  } else {
    value = 0;
  }

  return (
    <MuiThemeProvider theme={getTheme(value)}>
      <Tooltip title={title} placement={placement} enterTouchDelay={400}>
        {children}
      </Tooltip>
    </MuiThemeProvider>
  );
};

export const LightTooltip: any = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);
