// @flow
import * as React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MuiToolbar from '@material-ui/core/Toolbar';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import styles from './Toolbar.module.css';

const theme = createTheme({
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: '4px',
        padding: '8px',

        '&:hover': {
          backgroundColor: 'rgba(102,102,102,0.25)',
        },
      },
    },
  },
});

const toolbarTheme = createTheme({
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: '56px',

        '@media(min-width: 600px)': {
          minHeight: '56px',
        },
      },
    },
  },
});

type ToolbarProps = {
  children: React.Node,
  arrowPosition?: 'bottomCenter' | 'rightCenter' | 'leftCenter',
};

export const Toolbar = ({
  arrowPosition = ' ',
  children,
  ...props
}: ToolbarProps): React.Node => {
  const classNames = [styles.toolTipTop];

  if (arrowPosition === 'bottomCenter') {
    classNames.push(styles.arrowBottomCenter);
  }

  if (arrowPosition === 'leftCenter') {
    classNames.push(styles.arrowLeftCenter);
  }

  if (arrowPosition === 'rightCenter') {
    classNames.push(styles.arrowRightCenter);
  }

  return (
    <MuiThemeProvider theme={toolbarTheme}>
      {/* $FlowFixMe This comment suppresses a Flow error */}
      <MuiToolbar className={classNames.join(' ')} {...props}>
        {children}
      </MuiToolbar>
    </MuiThemeProvider>
  );
};
type Props = {
  ActionIcon: React.Node,
  handleClick: () => void,
  tooltip: string,
  disabled?: boolean,
};

export const ToolbarIcon = ({
  ActionIcon,
  handleClick,
  tooltip,
  disabled = false,
  ...rest
}: Props): React.Node => (
  <Tooltip title={tooltip} disableFocusListener>
    <span>
      <MuiThemeProvider theme={theme}>
        {/* $FlowFixMe This comment suppresses a Flow error */}
        <IconButton
          aria-label={tooltip}
          color="inherit"
          onClick={handleClick}
          disabled={disabled}
          {...rest}
        >
          {ActionIcon}
        </IconButton>
      </MuiThemeProvider>
    </span>
  </Tooltip>
);

type styleProps = {
  marginLeft?: string,
  marginRight?: string,
};

export const SeparatorToolbar = ({
  marginLeft = '14px',
  marginRight = '14px',
}: styleProps): React.Element<'span'> => {
  return (
    <span
      style={{
        backgroundColor: '#212121',
        display: 'block',
        height: 32,
        marginLeft: marginLeft,
        marginRight: marginRight,
        minWidth: 1,
      }}
    />
  );
};
