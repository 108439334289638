// @flow
import type { Node } from 'React';
import React, { PureComponent } from 'react';
import { Grid } from 'components/Editor/Common/Grid/Grid';
import { TextField, TextArea } from 'components/Editor/Common';
import { InputEvent, Image, updateSection, Section } from 'lib/flowTypes';
import Dialog from 'components/Editor/Common/Dialog';
import { LayoutBar } from 'components/Editor/Common/Toolbar/LayoutBar';
import { cancelLabel } from 'components/Editor/Escribir/lib/helpers';
import { Caption } from 'components/Editor/Common/Typography/Typo';
import styles from './imageAltPopover.module.css';
import { isValidUrl } from 'lib/helpers';

const imageDimension = 'original';
const normalCaption = 'caption-img';

type ImageData = {
  alt: string,
  src: string,
  extension: string,
  name: string,
  height: number,
  width: number,
  caption: string,
  caption_text: string,
};

type Props = {
  open: boolean,
  imageToEmbed: Image,
  closeModal: () => void,
  openModal: (name: string, mode?: string, prevModalData?: {}) => void,
  mode: string,
  isMobile?: boolean,
  currentIndex: string,
  postType: string,
  prevModalData: { name: string, mode: string, section: Section },
  primarySection: (section: {}, subType: string) => void,
  updateSection: updateSection,
};

type State = {
  altText: string,
  layout: string,
  align: string,
  captionText: string,
  link: string,
  hasError: boolean,
  hasLinkError: boolean,
};

const isUrlValidOrBlank = (link) => {
  return link ? isValidUrl(link) : true;
};

export class ImageAltTextPopover extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      altText: this.getDefaultAltText(this.props.imageToEmbed.name),
      layout: 'normal',
      align: 'center',
      captionText: '',
      link: '',
      hasError: false,
      hasLinkError: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.open !== this.props.open) {
      this.setState({
        layout: 'normal',
        align: 'center',
        captionText: '',
        link: '',
      });
    }
  }

  getDefaultAltText: (name: string) => string = (name: string) => {
    // check if name starts with digit
    if (name.match(/^\d/)) {
      return '';
    }
    // eslint-disable-next-line
    let words = name.split(/[_\-\.\s]+/);
    // remove file extension
    words.pop();
    // capitalize each word in result array
    let altText = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return altText.join(' ');
  };

  handleCaptionChange = (e: InputEvent) => {
    this.setState({ captionText: e.target.value });
  };

  handleUrl = (e: InputEvent) => {
    const link = e.target.value.trim();
    this.setState({ link, hasLinkError: !isUrlValidOrBlank(link) });
  };

  handleAltTextChange = (e: InputEvent) => {
    const altText = e.target.value;
    this.setState({ altText, hasError: !altText.trim() });
  };

  onKeyPress = (e: InputEvent) => {
    const key = e.which || e.keyCode;

    if (this.state.altText.trim() && 13 === key) {
      this.handleSubmit();
    }
  };

  includeCaption: (layout: string) => boolean = (layout: string): boolean =>
    ['normal', 'large', 'edge'].includes(layout);

  handleSubmit: () => void = () => {
    const { imageToEmbed } = this.props;
    const { altText, layout, align } = this.state;
    let { captionText, link } = this.state;
    captionText = this.includeCaption(layout) ? captionText : '';

    if (!this.state.altText.trim()) {
      this.setState({ hasError: true });
      return;
    }

    if (!isUrlValidOrBlank(link)) {
      this.setState({ hasLinkError: true });
      return;
    }

    const image = {
      ...imageToEmbed,
      alt: altText.trim(),
      layout,
      align,
      caption_text: captionText.trim(),
      caption: captionText ? normalCaption : '',
      link: link.trim(),
    };

    this.addImage(image);
  };

  handleAmpContentSubmit: () => void = () => {
    const {
      imageToEmbed,
      prevModalData: { name: prevModalName, mode, section },
      updateSection,
    } = this.props;
    const { altText: alt, link } = this.state;

    const sectionData = {
      type: prevModalName,
      ...section,
      ampContent: {
        type: 'image',
        alt,
        link,
        ...imageToEmbed,
      },
    };

    updateSection(sectionData, mode);
  };

  addImage: (image: ImageData) => void = (image: ImageData) => {
    const { primarySection, mode, updateSection } = this.props;

    if ('mainImage' === mode) {
      primarySection(image, 'image');
    } else {
      const imgAttributes = {
        ...image,
        type: 'image',
      };
      updateSection(imgAttributes);
    }
  };

  goBack: () => void = () =>
    this.props.openModal(
      'imagePanel',
      this.props.mode,
      this.props.prevModalData
    );

  setImageLayout: (layout: string, align: string) => void = (
    layout: string,
    align: string
  ) => {
    this.setState({
      layout: layout,
      align: align,
    });
  };

  render(): Node {
    const {
      imageToEmbed: { src, extension, name },
      open,
      mode,
      isMobile,
      closeModal,
      postType,
      prevModalData: { name: prevModalName },
      currentIndex,
    } = this.props;
    const { hasError, hasLinkError, altText, link } = this.state;
    const isTableCell = currentIndex && currentIndex.indexOf(':') > -1;
    const imageSrc = `${src}/${imageDimension}.${extension}`;
    const showCaption =
      mode !== 'mainImage' &&
      this.includeCaption(this.state.layout) &&
      'richContent' !== prevModalName &&
      !isTableCell;
    const showLayoutToolbar =
      mode !== 'mainImage' && 'richContent' !== prevModalName && !isTableCell;

    const showUrlField = mode !== 'mainImage';

    return (
      <Dialog
        open={open}
        title={
          prevModalName
            ? 'Código personalizado - Imagen alternativa'
            : 'Imágenes'
        }
        onClose={closeModal}
        submitLabel={prevModalName ? 'Insertar Código' : 'Insertar imagen'}
        onSubmit={
          prevModalName ? this.handleAmpContentSubmit : this.handleSubmit
        }
        submitDisabled={hasError || hasLinkError}
        onCancel={this.goBack}
        cancelLabel={cancelLabel('Elegir otra')}
        size="large"
      >
        <Grid container gutter="custom" spacing={4}>
          <Grid item sm={4} xs={12}>
            <div className={styles.imgContainer}>
              <img src={imageSrc} alt="" />
            </div>
            <p className={styles.modalImgTitle}>{name}</p>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Grid container gutter="custom" spacing={5}>
              <Grid item xs={12}>
                <TextField
                  value={altText}
                  hintText="Descripción de la imagen"
                  floatingLabelText={
                    prevModalName
                      ? 'Texto alternativo (para accesibilidad y SEO)'
                      : 'Texto alternativo'
                  }
                  onChange={this.handleAltTextChange}
                  onKeyPress={this.onKeyPress}
                  autoFocus={isMobile ? false : true}
                  errorText={hasError ? 'Falta el texto alternativo' : ''}
                />
              </Grid>
              {showLayoutToolbar && (
                <Grid item xs={12}>
                  <div className={styles.modalMediaLayout}>
                    <Caption>Diseño de la imagen</Caption>
                    <LayoutBar
                      type="image"
                      postType={postType}
                      changeLayout={this.setImageLayout}
                      selectedKey={`${this.state.layout}-${this.state.align}`}
                      index={currentIndex}
                    />
                  </div>
                </Grid>
              )}
              {showCaption && (
                <Grid item xs={12}>
                  <TextArea
                    className="modal-media-caption"
                    hintText="..."
                    floatingLabelText="Pie de foto (opcional)"
                    onChange={this.handleCaptionChange}
                    onKeyPress={this.onKeyPress}
                    minRows={0}
                    maxRows={3}
                  />
                </Grid>
              )}

              {showUrlField && (
                <Grid item xs={12}>
                  <TextField
                    className="modal-media-caption"
                    hintText="https://www.xataka.com/otros/herman-miller-tiene-su-silla-gaming-colaboracion-logitech-a-precio-1-276-euros"
                    floatingLabelText="URL imagen (opcional)"
                    onChange={this.handleUrl}
                    onKeyPress={this.onKeyPress}
                    value={link}
                    errorText={
                      hasLinkError
                        ? 'Error: La URL no es válida, por favor usa la URL completa de la imagen'
                        : ''
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}
