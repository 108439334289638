// @flow
import React from 'react';
import Pagination from '@mui/material/Pagination';
import { ThemeProvider, createTheme } from '@mui/material/styles';

type Props = {
  pageCount: number,
  currentPage: number,
  handleChangePage: Function,
  background?: boolean,
};

const theme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        ul: {
          margin: '16px 0',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        page: {
          boxShadow: '0 2px 2px 0px #00000040',
          minWidth: '26px',
          fontWeight: 'bold',

          '&.Mui-selected': {
            boxShadow: 'none',
            color: '#a4a4a4',
          },
        },
      },
    },
  },
});

const invertedTheme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        ul: {
          margin: '16px 0',
        },
      },
    },

    MuiPaginationItem: {
      styleOverrides: {
        page: {
          boxShadow: 'none',
          minWidth: '24px',
          height: '30px',
          fontWeight: '500',
          color: '#3F51B5',
          background: 'transparent',
          borderRadius: '2px !important',

          '&.Mui-selected': {
            boxShadow: 'none',
            color: '#a4a4a4',
            background: '#E0E0E0',
          },
        },
      },
    },
  },
});

export default function PaginationRounded({
  pageCount,
  currentPage,
  handleChangePage,
  background = false,
}: Props) {
  return (
    <div>
      <ThemeProvider theme={background ? invertedTheme : theme}>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handleChangePage}
          shape="rounded"
          hidePrevButton
          hideNextButton
          siblingCount={1}
        />
      </ThemeProvider>
    </div>
  );
}
