import React from 'react';

import { InputEvent, Image } from 'lib/flowTypes';
import styles from './Thumbnail.module.css';

const thumbnailDimension = 'original';

type Props = {
  image: Image,
  handleClick: (e: InputEvent) => void,
};

export function Thumbnail(props: Props) {
  const {
    image: { src, extension },
    handleClick,
  } = props;

  return (
    <div className={handleClick ? styles.imagehover : styles.image}>
      <img
        src={`${src}/${thumbnailDimension}.${extension}`}
        alt=""
        onClick={handleClick}
        className={styles.size}
      />
    </div>
  );
}
