// @flow
import * as React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

const theme = createTheme({
  palette: {
    error: {
      main: '#C52100',
    },
  },
});

type Props = {
  label: string,
  value: string | null,
  onChange: (time: any) => void,
};

const TimePicker = ({ onChange, value, label, ...otherProps }: Props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardTimePicker
          {...otherProps}
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
          variant="inline"
          inputVariant="outlined"
          label={label}
          value={value}
          onChange={onChange}
          margin="dense"
          ampm={false}
          KeyboardButtonProps={{ disabled: true, style: { display: 'none' } }}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default TimePicker;
