const webediaSiteGroups = {
  tecnologia: {
    sites: {
      applesfera: 'Applesfera',
      genbeta: 'Genbeta',
      mundoxiaomi: 'Mundo Xiaomi',
      xataka: 'Xataka',
      xatakandroid: 'Xataka Android',
      xatakaciencia: 'Xataka Ciencia',
      xatakafoto: 'Xataka Foto',
      xatakamovil: 'Xataka Móvil',
      xatakahome: 'Xataka Smart Home',
      xatakawindows: 'Xataka Windows',
      // xatakacolombia: 'Xataka Colombia',
    },
  },

  gaming: {
    sites: {
      '3djuegos': '3DJuegos',
      '3djuegospc': '3DJuegos PC',
      '3djuegosguias': '3DJuegos Guías',
      vidaextra: 'Vida Extra',
    },
  },

  entertainment: {
    sites: {
      espinof: 'Espinof',
    },
  },

  food: {
    sites: {
      directoalpaladar: 'Directo al Paladar',
    },
  },

  lifestyle: {
    sites: {
      compradiccion: 'Compradicción',
      trendenciasdecoracion: 'Decoesfera',
      poprosa: 'Poprosa',
      trendencias: 'Trendencias',
      vitonica: 'Vitónica',
    },
  },

  latinamerica: {
    tecnologia: {
      sites: {
        xatakamexico: 'Xataka México',
        xatakaargentina: 'Xataka Argentina',
      },
    },
    gaming: { sites: { '3djuegoslat': '3dJuegos Latam' } },
    entertainment: { sites: {} },
    food: { sites: { directoalpaladarmexico: 'Directo al Paladar México' } },
  },
};

const webediaGroupLabels = {
  tecnologia: 'Tecnología',
  gaming: 'Gaming',
  entertainment: 'Entertainment',
  food: 'Food',
  lifestyle: 'Lifestyle',
  latinamerica: 'Latinoamérica',
};

export const getSiteGroups = () => {
  return webediaSiteGroups;
};

export const getSiteGroupLabels = () => {
  return webediaGroupLabels;
};

export const LATAM_SITES = {
  xatakamexico: 'Xataka México',
  xatakaargentina: 'Xataka Argentina',
  '3djuegoslat': '3dJuegos Latam',
  directoalpaladarmexico: 'Directo al Paladar México',
};

export const stopWords = [
  'con tal que',
  'lo mismo que',
  'por miedo a',
  'por mucho que',
  'tan pronto como',
  'una vez que',
  'ahora que',
  'antes que',
  'aun cuando',
  'en cuanto',
  'mientras que',
  'para que',
  'por muy',
  'por si',
  'puesto que',
  'siempre que',
  'sin embargo',
  'suponiendo que',
  'ya que',
  'ante',
  'bajo',
  'cabe',
  'con',
  'contra',
  'de',
  'desde',
  'en',
  'entre',
  'hacia',
  'hasta',
  'por',
  'segun',
  'sin',
  'so',
  'sobre',
  'tras',
  'ademas',
  'aun',
  'aunque',
  'cualquiera',
  'el',
  'lo',
  'la',
  'los',
  'las',
  'aquellos',
  'aquellas',
  'junto',
  'es',
  'una',
  'un',
  'mismo',
  'misma',
  'mas',
  'menos',
  'mientras',
  'ni',
  'o',
  'pero',
  'si',
  'tan',
  'tanto',
  'tantos',
  'y',
  'son',
  'ya',
  'del',
];
