// @flow
import React, { useMemo } from 'react';
import { Checkbox } from '../Common/Checkbox/Checkbox';

import {
  LATAM_SITES,
  getSiteGroupLabels,
  getSiteGroups,
} from './lib/constants';
import styles from './RepostSiteGroupOption.module.css';

type Props = {
  groupName: string,
  siteName: string,
  repostSites: Array<string>,
  handleCheck: (e: any, isChecked: boolean) => void,
};

export const RepostSiteGroupOption = ({
  groupName,
  siteName,
  repostSites,
  handleCheck,
}: Props) => {
  const siteGroupLabels = useMemo(
    () => getSiteGroupLabels(siteName),
    [siteName]
  );

  const isGroupSelected = (sites: Array<string>) => {
    for (let i = 0; i < sites.length; i++) {
      if (!repostSites.includes(sites[i])) {
        return false;
      }
    }

    return true;
  };

  const removeCurrentSite = (groupSites: {}) => {
    return Object.keys(groupSites).filter(
      (groupSite) => groupSite !== siteName
    );
  };

  const getTitle = (heading) => {
    return <b>{heading}</b>;
  };

  const getSelectGroupCheckbox = () => {
    const siteGroups = getSiteGroups();
    let sites = [];
    if (groupName === 'latinamerica') {
      sites = removeCurrentSite(LATAM_SITES);
    } else {
      sites = removeCurrentSite(siteGroups[groupName].sites);
    }
    const value = Object.values(sites).join();

    return (
      <div className={styles.layoutLineFormHeading}>
        {value ? (
          <Checkbox
            checked={isGroupSelected(sites)}
            label={getTitle(siteGroupLabels[groupName])}
            value={value}
            onChange={handleCheck}
            margin="zero"
          />
        ) : (
          getTitle(siteGroupLabels[groupName])
        )}
      </div>
    );
  };

  return getSelectGroupCheckbox();
};
