import * as React from 'react';
import styles from './OrderedList.module.css';

type Props = {
  items: Array<>
};

export const OrderedList = ({ items }: Props) => {
  const getList = () => {
    const keywords = items.map((item, index) => (
      <li key={item + index}>{item}</li>
    ));

    return keywords;
  };

  return <ol className={styles.OrderedList}>{getList()}</ol>;
};
