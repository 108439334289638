import { initializeApp } from 'firebase/app';
import {
  getDatabase,
  ref,
  onValue,
  get,
  child,
  query,
  equalTo,
  orderByChild,
  update,
  set,
  push,
  remove,
  off,
  onDisconnect,
} from 'firebase/database';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { isOnline } from 'lib/service';

let db = '',
  auth = '',
  app = '';
if (process.env.REACT_APP_ENV) {
  app = initializeApp({
    apiKey: process.env.REACT_APP_apiKey,
    databaseURL: process.env.REACT_APP_firebaseUrl,
    authDomain: process.env.REACT_APP_authDomain,
  });
  db = getDatabase(app);
  auth = getAuth(app);
}
export const onlinePresence = async (path: string, userData: Object) => {
  const dbRef = ref(db, path);
  onDisconnect(dbRef).set(null);
  await set(dbRef, userData);
};

const getFetchRef = (path: string, options = {}) => {
  if (options.hasOwnProperty('equalTo')) {
    const dbRef = ref(db, path);
    return query(
      dbRef,
      orderByChild(options.orderByChild),
      equalTo(options.equalTo)
    );
  }
  return child(ref(db), path);
};

const getResponse = (data, asArray = false) => {
  if (asArray) {
    if (!data.val()) {
      return [];
    }
    const response = [];
    data.forEach(function (child) {
      response.push({ ...child.val(), key: child.key });
    });
    return response;
  }
  return data.val();
};

export const base = {
  fetch: async (path: string, { asArray = false, queries } = {}) => {
    try {
      db = getDatabase(app);
      const snapshot = await get(getFetchRef(path, queries));
      if (snapshot.exists()) {
        return getResponse(snapshot, asArray);
      }
      return asArray ? [] : '';
    } catch (e) {
      console.log(e.message, path, db);
    }
  },
  listenTo: (path, options) => {
    let dbRef = ref(db, path);
    if (
      options.hasOwnProperty('queries') &&
      options.queries.hasOwnProperty('orderByChild')
    ) {
      dbRef = query(dbRef, orderByChild(options.queries.orderByChild));
    }
    onValue(dbRef, (snapshot) => {
      const response = getResponse(snapshot, options.asArray || false);
      options.then(response);
    });
    return dbRef;
  },
  removeBinding: (dbRef) => {
    off(dbRef);
  },
  update: async (path: string, { data }: Object) => {
    const isConnected = await isOnline();
    if (!isConnected) {
      throw new Error('Internet not connected');
    }
    const updates = {};
    for (const key in data) {
      updates[`${path}/${key}`] = data[key];
    }
    return update(ref(db), updates);
  },
  push: async (path: string, { data }: Object) => {
    const { key } = await push(child(ref(db), path));
    await set(ref(db, `${path}/${key}`), data);
    return new Promise((resolve, reject) => {
      resolve({ key });
    });
  },
  post: async (path: string, options) => {
    await set(ref(db, path), options.data);
    if (options.hasOwnProperty('then')) {
      options.then();
    }
  },
  remove: (path: string) => remove(ref(db, path)),
};

export { auth, signInWithCustomToken };
