//@flow
import type { Element } from 'React';
import React from 'react';
import ReactSelect from 'react-select';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { ReactSelectStyles, containerStyles } from './ReactSelectStyles';
import { ErrorText, DropdownIndicator, Placeholder } from './helpers';
import type { ValueType } from 'lib/flowTypes';
import styles from './Select.module.css';

const theme = createTheme({
  overrides: {
    MuiTypography: {
      caption: {
        lineHeight: '1',
        position: 'absolute',
        top: '0',
      },
    },
  },
});

type Props = {
  placeholder: string,
  value: ValueType,
  options: any,
  onChange: Function,
  errorText?: string,
  isMulti?: boolean,
  disabled?: boolean,
  hintText?: string,
};

export const Select = ({
  placeholder,
  value,
  options,
  onChange,
  errorText,
  isMulti = false,
  hintText = '...',
  disabled = false,
  ...props
}: Props): Element<'div'> => {
  const selectClassName = errorText ? styles.fieldError : styles.selectField;

  return (
    <div style={containerStyles} className={selectClassName}>
      <MuiThemeProvider theme={theme}>
        <Placeholder text={placeholder} />
      </MuiThemeProvider>
      {/* $FlowFixMe This comment suppresses a Flow error */}
      <ReactSelect
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#FCE4EC',
            primary50: '#F5E9ED',
            primary: '#EEEEEE',
          },
        })}
        placeholder={hintText}
        isMulti={isMulti}
        options={options}
        onChange={onChange}
        value={value}
        getOptionValue={({ id }) => id}
        styles={ReactSelectStyles}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        isDisabled={disabled}
        {...props}
      />
      {!!errorText && <ErrorText text={errorText} />}
    </div>
  );
};
