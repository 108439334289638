// @flow
import moment from 'moment-timezone';

moment.locale('es');
const EUROPE_TIMEZONE = 'Europe/Madrid';
const MEXICO_TIMEZONE = 'America/Mexico_City';
const ARGENTINA_TIMEZONE = 'America/Argentina/Buenos_Aires';
const AMERICA_BOGOTA_TIMEZONE = 'America/Bogota';
const AMERICA_SAO_PAULO_TIMEZONE = 'America/Sao_Paulo';

export const getMoment = () => moment;

export const getTimezone = (siteName: string) => {
  if ('xatakaargentina' === siteName) {
    return ARGENTINA_TIMEZONE;
  }

  if ('xatakacolombia' === siteName) {
    return AMERICA_BOGOTA_TIMEZONE;
  }

  if (siteName.endsWith('mexico') || siteName.endsWith('lat')) {
    return MEXICO_TIMEZONE;
  }

  if ('xatakabrasil' === siteName) {
    return AMERICA_SAO_PAULO_TIMEZONE;
  }

  return EUROPE_TIMEZONE;
};

export const setTimezone = (siteName: string) =>
  moment.tz.setDefault(getTimezone(siteName));

export const getDateFromTimestamp = (date: number) =>
  moment.unix(date).format('DD/MM/YYYY');

export const currentDate = (format: string = 'DD/MM/YYYY') => {
  return moment().format(format);
};

export const getCurrentDateByAddingXdays = (
  xDays: number,
  format: string = 'DD/MM/YYYY'
) => moment().add(xDays, 'days').format(format);

export const getDateBySubtactingXDays = (date: string, xDays: number) =>
  moment(date, 'DD/MM/YYYY').subtract(xDays, 'days').format('DD/MM/YYYY');

export const currentDateTime = () => {
  return moment().format('DD/MM/YYYY HH:mm');
};

export const YYYYMMDDcurrentDate = () => {
  return moment().format('YYYY-MM-DD');
};
export const getDateDifferenceInDays = (
  date: string,
  format: string = 'DD/MM/YYYY HH:mm'
) => {
  const publishDate = moment(date, format);
  return moment().diff(publishDate, 'days');
};

export const getDateDifferenceInMinutes = (date: string) => {
  const publishDate = moment(date, 'DD/MM/YYYY HH:mm');
  return moment().diff(publishDate, 'minutes');
};

export const isValidDate = (date: string | null) => {
  return moment(date, 'YYYY-MM-DD', true).isValid();
};

export const isValidTime = (time: string | null) => {
  return moment(time, 'HH:mm', true).isValid();
};

export const isValidDateTime = (dateTime: string | null) => {
  return moment(dateTime, 'YYYY-MM-DD HH:mm:ss', true).isValid();
};

export const getYYYYMMDDISOFormatDate = (date: string | null) => {
  const momentDate = moment(date);
  return !momentDate.isValid() ? '' : momentDate.format('YYYY-MM-DD');
};

export const getDDMMYYYYFormatDate = (date: string | null) => {
  const momentDate = moment(date);
  return !momentDate.isValid() ? '' : momentDate.format('DD/MM/YYYY');
};

export const getDDMMMYYYYFormatDateTime = (date: string | null) => {
  const momentDate = moment(date);
  return !momentDate.isValid() ? '' : momentDate.format('DD MMM YYYY - HH:mm');
};

export const getHHMMSSFormatTime = (time: string | null) => {
  const momentTime = moment(time);
  return !momentTime.isValid() ? '' : momentTime.format('HH:mm:ss');
};

export const getHHMMFormatTime = (time: string | null) => {
  const momentTime = moment(time);
  return !momentTime.isValid() ? '' : momentTime.format('HH:mm');
};

export const getGMTDate = (
  date: string | null,
  time: string | null,
  toFormat: string = 'YYYY-MM-DD HH:mm:ss'
) => {
  const fullDate = moment.tz(
    [...getDateInfo(date), ...getTimeInfo(time)],
    'Europe/Madrid'
  );
  return fullDate.isValid() ? moment(fullDate).utc().format(toFormat) : '';
};

export const getDateInfo = (date: string | null) => {
  const year = moment(date).get('year') || 0;
  const month = moment(date).get('month') || 0;
  const day = moment(date).get('date') || 0;
  return [year, month, day];
};

export const getTimeInfo = (time: string | null) => {
  const hours = moment(time, 'HH:mm').get('hour') || 0;
  const minutes = moment(time, 'HH:mm').get('minute') || 0;
  return [hours, minutes];
};

export const getFullDate = (date: string | null, time: string | null) => {
  return moment([...getDateInfo(date), ...getTimeInfo(time)]);
};

export const isPastFullDate = (date: string | null) => {
  const streamingDate = moment([...getDateInfo(date), ...getTimeInfo(date)]);
  const currentFullDate = new moment();
  return streamingDate < currentFullDate;
};

export const isPastDate = (date: string | null) => {
  const streamingDate = moment([...getDateInfo(date)]);
  const currentFormattedDate = moment([...getDateInfo(YYYYMMDDcurrentDate())]);
  return streamingDate.isBefore(currentFormattedDate);
};

export const isFutureFullDate = (date: string | null) => {
  const streamingDate = moment([...getDateInfo(date), ...getTimeInfo(date)]);
  const currentFullDate = new moment();
  return streamingDate > currentFullDate;
};

export const currentFullDate = () => new moment();

export const isSameOrBefore = (date1: string | null, date2: string | null) => {
  return moment(date1).isSameOrBefore(date2);
};

export const isCurrentDateTimeIsBetween = (
  utcStartDateTime: string | null,
  utcEndDateTime: string | null
) =>
  moment
    .utc()
    .isBetween(
      moment.utc(utcStartDateTime, 'YYYY-MM-DD HH:mm:ss'),
      moment.utc(utcEndDateTime, 'YYYY-MM-DD HH:mm:ss')
    );

export const getTimeLeft = (utcEndTime: string | null) => {
  const utcDate = moment.utc(utcEndTime, 'YYYY-MM-DD HH:mm:ss');
  const duration = moment.duration(utcDate.diff(moment()));
  if (duration.asHours() < 1) {
    return `${duration.minutes()} min ${duration.seconds()} s`;
  }

  if (duration.asDays() < 1) {
    return `${duration.hours()} h ${duration.minutes()} min`;
  }

  return `${Math.floor(duration.asDays())} días ${duration.hours()} h`;
};

export const getManualPriceExpiryIntervalInDays = (
  date: string = currentDate('YYYY-MM-DD')
) => {
  let intervalInDays = 7;
  if (isSameOrBefore('2024-11-29', date)) {
    intervalInDays = 3;
  }

  return intervalInDays;
};

export const getManualPriceExpiryDate = (
  format: string = 'DD/MM/YYYY',
  date: string = currentDate('YYYY-MM-DD')
) => {
  const intervalInDays = getManualPriceExpiryIntervalInDays(date);

  return getCurrentDateByAddingXdays(intervalInDays, format);
};
