// @flow
import { setTimezone, getMoment } from 'lib/momentHelpers';

const moment = getMoment();

export { setTimezone };

export const currentHour = (): any => {
  return moment().add(1, 'hours').format('DD/MM/YYYY HH:00');
};

export const currentDate = (): any => {
  return moment().format('DD/MM/YYYY');
};

export const currentTime = (): any => {
  return moment().format('DD/MM/YYYY HH:mm');
};

export const getCurrentUtcTime = (
  dateFormat: string = 'DD/MM/YYYY HH:mm:ss'
): any => moment().utc().format(dateFormat);

export const isFuture = (
  date: string,
  fromFormat: string = 'DD/MM/YYYY HH:mm'
): boolean => {
  return moment(date, fromFormat) >= moment();
};

export const isValidDate = (date: string | null): any => {
  return moment(date, 'DD/MM/YYYY HH:mm', true).isValid();
};

export const isValidTime = (time: string | null): any => {
  return moment(time, 'YYYY-MM-DD HH:mm', true).isValid();
};

export const getFormatDate = (
  date: string,
  fromFormat: string = 'YYYY-MM-DD HH:mm:ss',
  toFormat: string = 'DD/MM/YYYY HH:mm'
): any => {
  const utcDate = moment.utc(date, fromFormat).format();
  return moment(utcDate).format(toFormat);
};

export const convertDateToUtc = (
  date: string,
  fromFormat: string = 'DD/MM/YYYY HH:mm:ss',
  toFormat: string = 'YYYY-MM-DD HH:mm:ss'
): any => moment(date, fromFormat).utc().format(toFormat);

export const changeDateFormat = (
  date: string,
  fromFormat: string = 'YYYY-MM-DD HH:mm:ss',
  toFormat: string = 'DD/MM/YYYY HH:mm:ss'
): any => moment(date, fromFormat).format(toFormat);

export const getNextDay = (date: string, i: number): any =>
  moment.unix(date).add(i, 'day');

export const getLongDate = (dateString: string): any => {
  const date = moment(dateString, 'DD/MM/YYYY HH:mm:ss');
  return date.format('D [de] MMMM [de] YYYY [a las] HH:mm');
};
